import { IState } from '@/store/auth/interfaces'
import UserModel from '@/modules/user/user.model'

export enum Mutations {
  reset = 'AUTH/RESET',
  setUser = 'AUTH/SET_USER',
}

export default {
  [Mutations.setUser]: (state: IState, payload: UserModel) => {
    state.user = payload
  },
  [Mutations.reset]: (state: IState) => {
    state.user = null
  },
}
