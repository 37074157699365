








import { Component, Vue } from 'vue-property-decorator'
import DataTableBase from '@/classes/DataTable/DataTable'
import RoleModel from '@/modules/role/role.model'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import permissions from '@/helpers/permissions'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import can from '@/helpers/can'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class SettingsTabsRolesList extends Vue {
  permissions = permissions
  table: DataTableBase = new DataTableBase()
    .setEndpoint('roles')
    .setModel(RoleModel)
    .setHeaders([
      new DataTableHeader().setValue('name').setText('Pavadinimas'),
      new DataTableHeader().setValue('slug').setText('Trumpinys').setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setPermissions([permissions.roles.edit, permissions.roles.show])
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.editItem),
      new DataTableAction()
        .setPermissions([permissions.roles.delete])
        .setIcon('delete')
        .setTitle('Ištrinti')
        .setAction(this.deleteItem),
    ])

  editItem(item: RoleModel): void {
    this.$router.push({
      name: AdminRoutes.settingsRolesEdit,
      params: {
        uuid: item.uuid,
      },
    })
  }

  goToCreate(): void {
    this.$router.push({ name: AdminRoutes.settingsRolesCreate })
  }

  deleteItem(item: RoleModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite rolę (${item.name}) ir šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: RoleModel): Promise<DataTableBase> {
    return http.delete(`${this.table.endpoint}/${item.uuid}`).then(() => this.table.removeItem(item))
  }
}
