






import { Component, Inject, Vue } from 'vue-property-decorator'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import http from '@/http'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class CustomersEditTabsBasicEdit extends Vue {
  @Inject('user') getUser!: () => UserModel | null
  form: FormBase = new FormBase()
    .setUuid((this.user && this.user.uuid) || '')
    .setEndpoint(`users`)
    .setModel(UserModel)
    .setEntry(this.user)
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('avatar')
        .setTitle('Nuotrauka')
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field().setEntryKey(UserAttribute.firstName).setKey('first_name').setTitle('Vardas').setSize(FieldSizes.half),
      new Field().setEntryKey(UserAttribute.lastName).setKey('last_name').setTitle('Pavardė').setSize(FieldSizes.half),
      new Field().setKey('email').setTitle('El. paštas').setSize(FieldSizes.half),
      new Field().setKey('phone').setTitle('Tel. numeris'),
      new SelectField()
        .setEntryKey(UserAttribute.roleUuid)
        .setKey('role_uuid')
        .setTitle('Rolė')
        .loadItems({ endpoint: '/roles?filter-notIn-slug=FINANCIER,ADMIN,WORKER', value: 'slug', title: 'name' })
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey(UserAttribute.financier_uuid)
        .setKey('financier_uuid')
        .setTitle('Finansuotojas')
        .loadItems({ endpoint: '/financiers', value: 'uuid', title: 'name' })
        .setVisibleIf((values: any) => values.role_uuid === 'FINANCIER')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('change_password_needed')
        .setTitle('Reikalingas slaptažodžio keitimas'),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password')
        .setTitle('Slaptažodis')
        .setVisibleIf((values: any) => values.change_password_needed),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password_confirmation')
        .setTitle('Pakartokite slaptažodį')
        .setVisibleIf((values: any) => values.change_password_needed),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(response: any, entry: any): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: `Darbuotojas atnaujintas - ${entry.fullName}`,
    })
    this.goToList()
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.customersIndex })
  }

  loginAsUser(): void {
    http.post(`/login/as-other-user/${this.user?.uuid}`).then(async (response: any) => {
      const redirectUrl = `${process.env.VUE_APP_WEB_URL}login-as?url=${response.data.url}`
      window.open(redirectUrl, '_blank')
    })
  }

  get user(): UserModel | null {
    return this.getUser()
  }
}
