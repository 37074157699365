import Vue from 'vue'
import Vuetify from 'vuetify'
import lt from 'vuetify/src/locale/lt'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: {
      lt,
    },
    current: 'lt',
  },
})
