import { IDialog, ISnackbar, IState, NavigationState } from '@/store/global/helpers'

export enum Mutations {
  setSnackbar = 'GLOBAL/SET_SNACKBAR',
  setNavigation = 'GLOBAL/SET_NAVIGATION',
  setDialog = 'GLOBAL/SET_DIALOG',
  resetDialog = 'GLOBAL/RESET_DIALOG',
  showLoading = 'GLOBAL/SHOW_LOADING',
}

export default {
  [Mutations.setSnackbar]: (state: IState, payload: ISnackbar | null): void => {
    state.snackbar = payload
  },
  [Mutations.setNavigation]: (state: IState, payload: NavigationState): void => {
    state.navigation = payload
  },
  [Mutations.setDialog]: (state: IState, payload: IDialog): void => {
    state.dialog = payload
  },
  [Mutations.resetDialog]: (state: IState): void => {
    state.dialog = {
      close: true,
      show: false,
      component: null,
      maxWidth: 350,
    }
  },
  [Mutations.showLoading]: (state: IState, payload: boolean): void => {
    state.loading = payload
  },
}
