

























import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableHeaderTypes, { dataTableHeaderMap } from '@/classes/DataTable/DataTableHeaderTypes'
import TextHeader from '@/components/DataTable/HeaderTypes/TextHeader.vue'
import DataTableBase from '@/classes/DataTable/DataTable'

@Component({
  components: {
    TextHeader,
  },
})
export default class HeaderTypes extends Vue {
  @Prop() table!: DataTableBase
  @Prop() props!: any

  getComponentName(type: DataTableHeaderTypes): any {
    // eslint-disable-next-line no-prototype-builtins
    if (dataTableHeaderMap.hasOwnProperty(type)) {
      return dataTableHeaderMap[type]
    }

    return TextHeader
  }
}
