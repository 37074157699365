











import { Component } from 'vue-property-decorator'
import DialogBase from '@/classes/Dialog'
import FormBase from '@/classes/Form/FormBase'
import Form from '@/components/Form/Form.vue'
import { HttpMethod } from '@/helpers/requests'
import { Actions } from '@/store/global/actions'
import { OfferAttribute } from '@/modules/offer/offer.model'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'

@Component({
  components: {
    Form,
  },
})
export default class OverwriteOfferDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setMethod(HttpMethod.POST)
    .setEndpoint(`funding-requests/${this.meta.fundingRequestUuid}/overwrite/${this.meta.entry.uuid}`)
    .setEntry(this.meta.entry)
    .setInjectValues({
      funding_request_uuid: this.meta.fundingRequestUuid,
    })
    .setFields([
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.period)
        .setTitle('Periodas')
        .setAppend(this.meta.entry.period_measure),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.annualInterestRate)
        .setEntryKey(OfferAttribute.annualInterestRate)
        .setTitle('Palūkanos')
        .setAppend('%'),
      new Field().setType(FieldTypes.number).setKey(OfferAttribute.amount).setTitle('Suma').setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.monthlyAmount)
        .setEntryKey(OfferAttribute.monthlyAmount)
        .setTitle('Mėn. suma')
        .setAppend('€/mėn'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.finalAmount)
        .setTitle('Bendra gražintina suma')
        .setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setVisibleIf(() => this.meta.fundingRequest && this.meta.fundingRequest.type === 'consumer_credit')
        .setKey(OfferAttribute.bvkkmn)
        .setTitle('*BVKKMN')
        .setAppend('%'),
      new Field().setType(FieldTypes.textArea).setKey(OfferAttribute.description).setTitle('Aprašymas'),
    ])
    .setOnSuccess(this.onSuccess)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async onSuccess() {
    await this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Pasiūlymas sėkmingai perrašytas',
    })
    this.meta.onSuccess()
    this.closeDialog()
  }
}
