





import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import ITabs from '@/interfaces/tabs'
import Tabs from '@/components/Tabs.vue'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'

@Component({
  components: {
    Tabs,
    AdminLayout,
  },
})
export default class SettingsSettings extends Vue {
  tabs: ITabs[] = [
    {
      to: AdminRoutes.settingsFinanciers,
      title: 'Kredito davėjai',
      permissions: [permissions.financiers.list],
    },
    {
      to: AdminRoutes.settingsAdditionalDocuments,
      title: 'Dokumentai',
      permissions: [permissions.additionalDocuments.list],
    },
    {
      to: AdminRoutes.settingsUserData,
      title: 'Vartotojo duomenų',
      permissions: [permissions.fundingRequestFields.list],
    },
    {
      to: AdminRoutes.settingsNotifications,
      title: 'Pranešimų',
      permissions: [permissions.notificationTemplates.list],
    },
    {
      to: AdminRoutes.settingsRoles,
      title: 'Rolės',
      permissions: [permissions.roles.list],
    },
    {
      to: AdminRoutes.settingsRecommendations,
      title: 'Rekomendacijos',
      permissions: [permissions.recommendations.list],
    },
  ]
}
