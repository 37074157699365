







import { Component, Vue } from 'vue-property-decorator'
import { IDialog } from '@/store/global/helpers'
import { Getters } from '@/store/global/getters'

@Component
export default class Dialog extends Vue {
  get dialog(): IDialog {
    return this.$store.getters[Getters.getDialog]
  }
}
