export default class NavigationItem {
  title!: string
  icon!: string
  route!: string
  open: boolean = false
  subItems: NavigationItem[] = []
  permissions!: string[]
  visibleIf: (values: any) => boolean = () => true
  atLeastOne!: boolean

  setTitle(title: string): NavigationItem {
    this.title = title

    return this
  }

  setIcon(icon: string): NavigationItem {
    this.icon = icon

    return this
  }

  setRoute(route: string): NavigationItem {
    this.route = route

    return this
  }

  setOpen(open: boolean): NavigationItem {
    this.open = open

    return this
  }

  setSubItems(subItems: NavigationItem[]): NavigationItem {
    this.subItems = subItems

    return this
  }

  setPermissions(permissions: string[]): NavigationItem {
    this.permissions = permissions

    return this
  }

  setPermissionsAtLeastOne(atLeastOne: boolean): NavigationItem {
    this.atLeastOne = atLeastOne

    return this
  }

  setVisibleIf(visibleIf: (values: any) => boolean): this {
    this.visibleIf = visibleIf

    return this
  }
}
