import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'

export default class TextAreaFieldTypes extends Field {
  type: FieldTypes = FieldTypes.textArea
  autoGrow: boolean = false
  rows: number = 2

  setAutoGrow(value: boolean): this {
    this.autoGrow = value

    return this
  }

  setRows(value: number): this {
    this.rows = value

    return this
  }
}
