export default class DataTableAction {
  icon!: string
  title!: string
  key!: any
  action!: (item: any) => void
  permissions!: string[]

  setIcon(icon: string): DataTableAction {
    this.icon = icon

    return this
  }

  setKey(key: any): DataTableAction {
    this.key = key

    return this
  }

  setTitle(title: string): DataTableAction {
    this.title = title

    return this
  }

  setAction(action: (item: any) => void): DataTableAction {
    this.action = action

    return this
  }

  setPermissions(permissions: string[]): DataTableAction {
    this.permissions = permissions

    return this
  }
}
