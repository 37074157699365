








import { Component, Vue } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/auth/actions'
import { FieldTypes } from '@/components/Form/FieldTypes'
import Field from '@/classes/Form/Field'
import tokenHelper from '@/helpers/tokenHelper'
import AuthLayout from '@/layouts/AuthLayout.vue'
import FormBase from '@/classes/Form/FormBase'
import { Positions } from '@/interfaces/form'
import { AdminRoutes } from '@/helpers/getRouteMeta'

@Component({
  components: {
    Form,
    AuthLayout,
  },
})
export default class Login extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('admin-login')
    .setInjectValues({
      type: 'form',
    })
    .setFields([
      new Field().setType(FieldTypes.email).setKey('email').setTitle('El. paštas'),
      new Field().setType(FieldTypes.password).setKey('password').setTitle('Slaptažodis'),
    ])
    .setOnSuccess(this.onSuccess)
    .setSubmit({
      text: 'Prisijungti',
      position: Positions.center,
    })

  goToRemindPassword(): void {
    this.$router.push({ name: AdminRoutes.remindPassword })
  }

  async onSuccess(response: any): Promise<void> {
    tokenHelper.setToken(response.access_token)
    await this.$store.dispatch(Actions.me).then(() => this.$router.push({ name: AdminRoutes.dashboard }))
  }
}
