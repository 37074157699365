import { IDialog, ISnackbar, IState, NavigationState } from '@/store/global/helpers'

export enum Getters {
  getSnackbar = 'GLOBAL/GET_SNACKBAR',
  getNavigation = 'GLOBAL/GET_NAVIGATION',
  getDialog = 'GLOBAL/GET_DIALOG',
  getLoading = 'GLOBAL/GET_LOADING',
}

export default {
  [Getters.getSnackbar]: (state: IState): ISnackbar | null => state.snackbar,
  [Getters.getNavigation]: (state: IState): NavigationState => state.navigation,
  [Getters.getDialog]: (state: IState): IDialog => state.dialog,
  [Getters.getLoading]: (state: IState): boolean => state.loading,
}
