export const offerStatuses: { key: any; value: any }[] = [
  { key: 'overwritten', value: 'Perrašytas' },
  { key: 'plain', value: 'Paprastas' },
  { key: 'expired', value: 'Nebegaliojantis' },
  { key: 'voided', value: 'Voided' },
]

export const getOfferStatuses = (type: string): string => {
  const found = offerStatuses.find((item: { key: any; value: any }) => item.key === type)

  if (found) return found.value

  return ''
}
