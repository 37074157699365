































import { Component } from 'vue-property-decorator'
import DialogBase from '@/classes/Dialog'
import Form from '@/components/Form/Form.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import AttachmentModel from '@/modules/attachment/attachment.model'

@Component({
  components: {
    Form,
    DataTable,
  },
})
export default class AdditionalDocumentsDialog extends DialogBase {
  attachments: AttachmentModel[] = []

  created(): void {
    const { entry } = this.meta

    if (entry) {
      this.attachments = new AttachmentModel().setCollection(entry)
    }
  }
}
