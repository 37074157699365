










import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import http from '@/http'
import _get from '@/helpers/_get'
import FundingRequestModel, { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import FundingRequestFieldModel from '@/modules/funding-request-field/funding-request-field.model'
import IFundingRequestResponse from '@/modules/funding-request/funding-request-response.interface'
import IFundingRequestFieldResponse from '@/modules/funding-request-field/funding-request-field-response.interface'
import { AxiosResponse } from 'axios'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import FormBase from '@/classes/Form/FormBase'
import UserModel from '@/modules/user/user.model'
import Tabs from '@/components/Tabs.vue'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { Actions as GlobalActions } from '@/store/global/actions'
import { FieldTypes } from '@/components/Form/FieldTypes'
import Form from '@/components/Form/Form.vue'
import { HttpMethod } from '@/helpers/requests'

@Component({
  components: {
    PageHeader,
    Tabs,
    Form,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessFundingRequestsEdit extends Vue {
  user: UserModel = this.$store.getters[Getters.getUser]
  loaded: boolean = false
  form: FormBase = new FormBase()
    .setEndpoint(`business-funding-requests/${this.$route.params.uuid}/constrained-update`)
    .setMethod(HttpMethod.PUT)
    .setModel(FundingRequestModel)
    .setFields([
      new Field()
        .setType(FieldTypes.number)
        .setEntryKey(FundingRequestAttribute.amount)
        .setKey('amount')
        .setTitle('Paskolos suma')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.number)
        .setEntryKey(FundingRequestAttribute.period)
        .setKey('period')
        .setTitle('Paskolos terminas')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.json)
        .setEntryKey(FundingRequestAttribute.data)
        .setKey('data')
        .setTitle('Paskolos duomenys'),
    ])
    .setOnSuccess(this.onSuccess)
  permissions = permissions
  fundingRequest: FundingRequestModel | null = null
  fundingRequestFields: FundingRequestFieldModel[] | null = null
  listRoute: string = AdminRoutes.offersIndex

  created(): void {
    this.loadFundingRequest()
  }

  goToFundingRequest(): void {
    this.$router.push({ name: AdminRoutes.businessFundingRequestsShow, params: { uuid: this.$route.params.uuid } })
  }

  loadFundingRequest(): void {
    http.get(`/business-funding-requests/${this.$router.currentRoute.params.uuid}`).then(
      (
        response: AxiosResponse<{
          funding_request: IFundingRequestResponse
          funding_request_fields: IFundingRequestFieldResponse[]
        }>,
      ) => {
        this.fundingRequest = new FundingRequestModel().transform(response.data.funding_request)
        this.form.setEntry(this.fundingRequest)
        this.loaded = true
      },
    )
  }

  onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackbar, {
      type: 'success',
      message: 'Paredaguota!',
    })
  }
}
