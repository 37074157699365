








import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import FormBase from '@/classes/Form/FormBase'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { Actions as GlobalActions } from '@/store/global/actions'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import store from '@/store'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { Getters } from '@/store/auth/getters'
import { Actions } from '@/store/auth/actions'

@Component({
  components: {
    AdminLayout,
    PageHeader,
    Form,
  },
})
export default class Profile extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('users/update-profile')
    .setModel(UserModel)
    .setFiles(true)
    .setFields([
      new Field().setType(FieldTypes.file).setKey(UserAttribute.avatar).setTitle('Nuotrauka').setMeta({
        type: 'image',
        accept: 'image/*',
      }),
      new Field().setEntryKey(UserAttribute.firstName).setKey('first_name').setTitle('Vardas'),
      new Field().setEntryKey(UserAttribute.lastName).setKey('last_name').setTitle('Pavardė'),
      new Field().setType(FieldTypes.number).setKey('phone').setTitle('Tel. nr.'),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('change_password_needed')
        .setTitle('Reikalingas slaptažodžio keitimas'),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password')
        .setTitle('Slaptažodis')
        .setVisibleIf((values: any) => values.change_password_needed),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password_confirmation')
        .setTitle('Pakartokite slaptažodį')
        .setVisibleIf((values: any) => values.change_password_needed),
    ])
    .setOnSuccess(this.onSuccess)

  created(): void {
    const user = store.getters[Getters.getUser]

    this.form.setEntry(user)
  }

  onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackbar, {
      type: 'success',
      message: 'Profilis buvo paredaguotas!',
    })

    store.dispatch(Actions.me)
  }
}
