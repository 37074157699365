import IOption from '@/interfaces/option.interface'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'

export const companyExistanceDuration: IOption[] = [
  {
    key: 'zero-to-six-months',
    title: '0 - 6 mėn',
  },
  {
    key: 'six-to-twelve-months',
    title: '6 - 12 mėn',
  },
  {
    key: 'one-to-two-years',
    title: '1 - 2 metai',
  },
  {
    key: 'two-to-four-years',
    title: '2 - 4 metai',
  },
  {
    key: 'four-to-five-years',
    title: '4 - 5 metai',
  },
  {
    key: 'five-years-or-above',
    title: 'Virš 5 metų',
  },
]

export const companyRevenueYearly: IOption[] = [
  {
    title: 'iki 50.000 Eur.',
    key: 'fifty-thousand',
  },
  {
    title: 'Nuo 50.000 Eur. iki 100.000 Eur.',
    key: 'from-fifty-thousand-to-one-hundred-thousand',
  },
  {
    title: 'Nuo 100.000 Eur. iki 300.000 Eur.',
    key: 'from-fifty-thousand-to-three-hundred-thousand',
  },
  {
    title: 'Nuo 300.000 Eur. iki 500.000 Eur.',
    key: 'from-three-hundred-thousand-to-five-hundred-thousand',
  },
  {
    title: 'Nuo 500.000 Eur. iki 1 mln Eur.',
    key: 'from-five-hundred-thousand-to-one-million',
  },
  {
    title: 'Nuo 1 mln Eur. iki 3 mln Eur.',
    key: 'from-one-million-to-three-million',
  },
  {
    title: 'Nuo 3 mln Eur. iki 5 mln Eur.',
    key: 'from-three-million-to-five-million',
  },
  {
    title: 'Virš 5 mln Eur.',
    key: 'five-million-and-above',
  },
]

export const optionsToSelectItems = (options: IOption[]): SelectItem[] => {
  return options.map((option: IOption) => {
    return new SelectItem().setValue(option.key).setTitle(option.title)
  })
}
