import Model from '@/classes/Model'
import INotificationTemplateResponse from '@/modules/notification-template/notification-template-response.interface'

export enum NotificationTemplateAttribute {
  notificationType = 'notificationType',
  type = 'type',
  content = 'content',
}

export default class NotificationTemplateModel extends Model {
  [NotificationTemplateAttribute.notificationType]!: string;
  [NotificationTemplateAttribute.type]!: string;
  [NotificationTemplateAttribute.content]!: string

  transform(data: INotificationTemplateResponse): NotificationTemplateModel {
    super.transform(data)

    this.notificationType = data.notification_type
    this.type = data.type
    this.content = data.content

    return this
  }
}
