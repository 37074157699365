import Model from '@/classes/Model'
import ISodraFinancesInterface from '@/modules/sodra/sodra-finances.interface'

export enum SodraFinanceAttribute {
  kind = 'kind',
  kindDescription = 'kind_description',
  type = 'type',
  typeDescription = 'type_description',
  amount = 'amount',
  currency = 'currency',
  from = 'from',
  to = 'to',
}

export default class SodraFinancesModel extends Model {
  [SodraFinanceAttribute.kind]!: string;
  [SodraFinanceAttribute.kindDescription]!: string;
  [SodraFinanceAttribute.type]!: string;
  [SodraFinanceAttribute.typeDescription]!: string;
  [SodraFinanceAttribute.amount]!: number;
  [SodraFinanceAttribute.currency]!: string;
  [SodraFinanceAttribute.from]!: Date;
  [SodraFinanceAttribute.to]!: Date

  transform(data: ISodraFinancesInterface): SodraFinancesModel {
    this.kind = data.Rusis
    this.kind_description = data.RusisString
    this.type = data.AsmIsmTipas
    this.type_description = data.AsmIsmTipasString
    this.amount = data.Suma
    this.currency = data.Valiuta
    this.from = data.DataNuo
    this.to = data.DataIki

    return this
  }
}
