











































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import can from '@/helpers/can'
import Tabs from '@/components/Tabs.vue'
import http from '@/http'
import FinancierModel from '@/modules/financier/financier.model'
import { AxiosResponse } from 'axios'
import IFinancierResponse from '@/modules/financier/financier-response.interface'
import IUserResponse from '@/modules/user/user-response.interface'
import UserModel from '@/modules/user/user.model'
import { Actions as GlobalActions } from '@/store/global/actions'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessFundingRequestsTabsAssignedUsers extends Vue {
  selected: UserModel[] = []
  financiers: FinancierModel[] = []
  panel: number | null = null

  created(): void {
    http
      .get(`financiers/users/assigned-funding-request/${this.$router.currentRoute.params.uuid}`)
      .then((response: AxiosResponse) => {
        this.populateData(response)
      })
  }

  setSelected(financier: FinancierModel): void {
    this.selected = []
    this.selected[0] = financier.users.find((user: IUserResponse) => {
      return user.assigned_funding_requests.length > 0
    })
  }

  assignUser(financier: FinancierModel): void {
    const user = this.selected[0]

    if (user && financier) {
      http
        .post(`financiers/${financier.uuid}/user/${user.uuid}/assign/${this.$router.currentRoute.params.uuid}`)
        .then((response: AxiosResponse) => {
          this.populateData(response)
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: 'success',
            message: 'Darbuotojas sėkmingai priskirtas!',
          })
        })
    }
  }

  populateData(response: AxiosResponse): void {
    this.financiers = []
    response.data.data.forEach((item: IFinancierResponse) => {
      this.financiers.push(new FinancierModel().transform(item))
    })

    if (this.financiers.length === 1) {
      // Open first panel if only one exists
      this.panel = 0
      this.setSelected(this.financiers[0])
    }
  }
}
