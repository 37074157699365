











import { Component } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'
import VJsoneditor from 'v-jsoneditor'

@Component({
  components: { VJsoneditor },
})
export default class JSONField extends AbstractField {}
