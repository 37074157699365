












































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import FormField from '@/components/Form/FormField.vue'
import Field from '@/classes/Form/Field'
import baseHttp from '@/http'
import { AxiosError } from 'axios'
import { Actions } from '@/store/global/actions'
import { SnackbarTypes } from '@/store/global/helpers'
import IResponse from '@/modules/response.interface'
import IModelResponse from '@/modules/model-response.interface'
import { FieldTypes } from '@/components/Form/FieldTypes'
import FormBase from '@/classes/Form/FormBase'
import { IFormError } from '@/interfaces/form'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import ISignedDocument from '@/modules/attachment/attachment-signed.interface'

@Component({
  components: { FormField },
})
export default class Form extends Vue {
  @Prop() form!: FormBase
  @Prop({
    default() {
      return []
    },
  })
  displayDocuments!: ISignedDocument[]
  @Prop() fundingRequestUuid!: string
  http = baseHttp
  loading: boolean[] = []
  loadedEntry: boolean = false

  documents: ISignedDocument[] = []

  created(): void {
    if (this.form.entry) {
      this.parseValues(this.form.entry)
    } else if (this.form.uuid) {
      this.loadEntryAndPresetValues()
    } else {
      this.presetValues()
    }

    if (this.displayDocuments.length) {
      this.displayDocuments.forEach(() => {
        this.loading.push(false)
      })

      this.documents = _.cloneDeep(this.displayDocuments.filter((document) => document.group === 'offer-document'))
    }
  }

  // eslint-disable-next-line consistent-return,@typescript-eslint/explicit-function-return-type
  async submit() {
    const { uuid, endpoint, model, onSuccess, initialCall } = this.form

    const data = this.generatePostData()

    const call: any = this.http[this.form.method]

    if ((!endpoint || !call) && onSuccess) return onSuccess(data)

    this.form.setLoading(true)

    // eslint-disable-next-line no-nested-ternary
    const callByEndpoint = initialCall ? endpoint : uuid ? `${endpoint}/${uuid}` : endpoint
    await call(callByEndpoint, data)
      .then(async (response: IResponse<IModelResponse>) => {
        if (model) {
          const entry = new model().transform(response.data.data)
          ;(await onSuccess) && onSuccess(response.data, entry)
        } else {
          ;(await onSuccess) && onSuccess(response.data)
        }

        this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Operacija sėkminga',
        })
      })
      .catch(this.catchErrors)

    this.form.setLoading(false)
  }

  private generatePostData(): any {
    this.http.defaults.headers['Content-Type'] = 'application/json'

    const data = _.cloneDeep(this.form.data)

    Object.keys(data).forEach((key: string) => {
      const found = this.form.fields.find((field: Field) => field.key === key)

      if (!found || !found.visibleIf(data)) delete data[key]
    })

    return this.form.files ? this.formDataForFileFormat(data) : { ...data, ...this.form.injectValues }
  }

  private formDataForFileFormat(data: any): FormData {
    const { uuid, injectValues, fields } = this.form

    const formData = new FormData()

    formData.append('_method', uuid ? 'PUT' : 'POST')
    this.http.defaults.headers['Content-Type'] = 'multipart/form-data'

    Object.keys(data).forEach((key: string) => {
      const found = fields.find((field: Field) => field.key === key)

      if (found && found.type === FieldTypes.file && data[key] instanceof Object && !(data[key] instanceof File)) {
        if (data[key][0] instanceof File) {
          data[key].forEach((item, index) => {
            formData.append(`${key}[${index}][file]`, item)
          })
        } else {
          formData.append(key, '')
        }
      } else if (found && found.type === FieldTypes.number) {
        if (data[key] === null || data[key] === '') {
          formData.append(key, '0')
        } else {
          formData.append(key, data[key])
        }
      } else if (data[key] !== null && data[key] !== '') {
        formData.append(key, data[key])
      }
    })

    if (injectValues) {
      Object.keys(injectValues).forEach((key: string) => formData.append(key, injectValues[key]))
    }

    return formData
  }

  private catchErrors(error: AxiosError<any>): void {
    if (!error.response) return

    if (error.response.status === 422 || error.response.status === 500) {
      error.response.data.errors && this.parseErrors(error.response.data.errors)

      this.$store.dispatch(Actions.showSnackbar, {
        type: SnackbarTypes.error,
        message: error.response.data.message,
      })
    }
  }

  private parseErrors(errors: any): void {
    const formErrors: IFormError = {}

    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key],
      }
    })

    this.form.setErrors(formErrors)
  }

  private presetValues(): void {
    const data = this.getDefaultFieldValues()

    this.form.setData(data)
  }

  private getDefaultFieldValues(): any {
    const data: any = {}
    this.form.fields.forEach((field: Field) => {
      let value: any = ''

      if (field.type === FieldTypes.file) {
        value = null
      } else if (field.type === FieldTypes.checkbox) {
        value = false
      } else if (field.type === FieldTypes.object) {
        value = {}
      }

      _.set(data, field.key, value)
    })

    return data
  }

  deleteDocument(document: ISignedDocument, fundingRequest: string, index): void {
    this.$set(this.loading, index, true)

    baseHttp.defaults.headers['Content-Type'] = 'application/json'

    baseHttp
      .post(`offers/${fundingRequest}/remove-attachment`, { key: document.key })
      .then(async () => {
        this.documents.splice(index, 1)
        await this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Sėkmingai pašalinote failą',
        })
      })
      .catch(async (error: AxiosError) => {
        if (error.response) {
          await this.$store.dispatch(Actions.showSnackbar, {
            type: 'error',
            message: error.response.data.message,
          })
        }
      })
      .finally(() => this.$set(this.loading, index, false))
  }

  private loadEntryAndPresetValues(): void {
    const { endpoint, uuid, model, initialCall } = this.form

    const callByEndpoint = initialCall ? endpoint : `${endpoint}/${uuid}`

    this.http.get(callByEndpoint).then((response: IResponse<IModelResponse>) => {
      const entry = new model().transform(response.data.data)
      this.form.setEntry(entry)
      this.parseValues(entry)
      this.loadedEntry = true
    })
  }

  private parseValues(entry: any): void {
    const data: any = this.getDefaultFieldValues()
    this.form.fields.forEach((field: Field | SelectField) => {
      const key = field.entryKey ? field.entryKey : field.key

      // eslint-disable-next-line no-prototype-builtins
      if (!entry.hasOwnProperty(key)) return

      if (field.type === FieldTypes.object) {
        if (!entry[key] || entry[key].length === 0) {
          data[field.key] = {}

          return
        }
      }

      if (field.type === FieldTypes.multipleSelect && field.prefill.value) {
        if (!entry[key] || entry[key].length === 0) {
          data[field.key] = []

          return
        }

        data[field.key] = entry[key].map((item: any) => {
          return item[field.prefill.value]
        })

        return
      }

      data[field.key] = entry[key]
    })

    this.form.setData(data)
    this.loadedEntry = true
  }
}
