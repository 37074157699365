// eslint-disable-next-line consistent-return
export default (name: string): string | undefined => {
  if (!translations[name]) {
    console.error(`Translation for ${name} not found`)
  }

  return translations[name]
}

const translations = {
  business_certificate: 'Individualios veiklos pažyma arba verslo liudijimas',
  income_declaration: 'Pajamų deklaracija už praeitus kalendorinius metus',
  income_and_expenses_documents:
    'Einamųjų metų pajamų-išlaidų žurnalas arba kiti to laikotarpio pajamas ir išlaidas pagrindžiantys dokumentai',
  work_certificate: 'Darbo pažymėjimas',
  bank_statement: 'Banko išrašas už paskutinius 12 mėnesių',
  spouse_business_certificate: 'Individualios veiklos pažyma arba verslo liudijimas',
  spouse_income_declaration: 'Pajamų deklaracija už praeitus kalendorinius metus',
  spouse_income_and_expenses_documents:
    'Einamųjų metų pajamų-išlaidų žurnalas arba kiti to laikotarpio pajamas ir išlaidas pagrindžiantys dokumentai',
  spouse_work_certificate: 'Darbo pažymėjimas',
  spouse_bank_statement: 'Banko išrašas už paskutinius 12 mėnesių',
  real_estate_rating: 'Nekilnojamojo turto vertinimas arba registrų centro išrašas',
  real_estate_documents: 'Perkamo būsto dokumentai (preliminari sutartis) arba komercinis pasiūlymas',
  pictures: 'Būsto nuotraukos',
}
