import Field, { IPrefillObject } from '@/classes/Form/Field'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { ILoadItemsProps } from '@/interfaces/form'
import http from '@/http'
import IResponse from '@/modules/response.interface'

export default class SelectField extends Field {
  type: FieldTypes = FieldTypes.select
  items: SelectItem[] = []
  prefill: IPrefillObject = {
    object: '',
    title: '',
    value: '',
  }

  setPrefill(prefill: IPrefillObject): this {
    this.prefill = prefill

    return this
  }

  setItems(items: SelectItem[]): SelectField {
    this.items = items

    return this
  }

  isMultiple(): SelectField {
    this.type = FieldTypes.multipleSelect

    return this
  }

  loadItems(loadItemsProps: ILoadItemsProps): SelectField {
    http
      .get(loadItemsProps.endpoint)
      .then((response: IResponse<any>) =>
        this.setItems(
          response.data.data.map((item: any) =>
            new SelectItem().setTitle(item[loadItemsProps.title]).setValue(item[loadItemsProps.value]),
          ),
        ),
      )

    return this
  }

  loadTemplateDocumentItems(loadItemsProps: ILoadItemsProps): SelectField {
    http.get(loadItemsProps.endpoint).then((response: IResponse<any>) =>
      this.setItems(
        response.data.data.map((item: any) =>
          new SelectItem()
            .setTitle(item[loadItemsProps.title])
            .setValue(item[loadItemsProps.value])
            .setTemplateDocument(item[loadItemsProps.template_document as any]),
        ),
      ),
    )

    return this
  }
}
