











import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import AdditionalDocumentModel, {
  AdditionalDocumentAttribute,
} from '@/modules/additional-documents/additional-document.model'
import { IDialog } from '@/store/global/helpers'
import CreateDialog from '@/views/Settings/Tabs/AdditionalDocuments/Dialogs/Create.vue'
import EditDialog from '@/views/Settings/Tabs/AdditionalDocuments/Dialogs/Edit.vue'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class SettingsTabsAdditionalDocumentsList extends Vue {
  permissions = permissions
  table = new DataTableBase()
    .setModel(AdditionalDocumentModel)
    .setEndpoint('additional-documents')
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${AdditionalDocumentAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Finansuotojo logotipas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader().setValue(AdditionalDocumentAttribute.title).setText('Dokumento pavadinimas'),
      new DataTableHeader()
        .setValue(AdditionalDocumentAttribute.isActive)
        .setText('Aktyvus')
        .setType(DataTableHeaderTypes.boolean),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.openEdit)
        .setPermissions([permissions.additionalDocuments.edit]),
    ])

  openCreate(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: CreateDialog,
      maxWidth: 650,
      meta: {
        onSuccess: (entry: AdditionalDocumentModel) => {
          http.get(`additional-documents/${entry.uuid}`).then((response: AxiosResponse) => {
            this.table.unshiftItem(new AdditionalDocumentModel().transform(response.data.data))
          })
        },
      },
    } as IDialog)
  }

  openEdit(item: AdditionalDocumentModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: EditDialog,
      maxWidth: 650,
      meta: {
        entry: item,
        onSuccess: (entry: AdditionalDocumentModel) => this.table.setItem(entry),
      },
    } as IDialog)
  }

  deleteItem(item: AdditionalDocumentModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite finansuotoją (${item.title}) ir šis veiksmas negalės būti atšaukti.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: AdditionalDocumentModel): Promise<DataTableBase> {
    return http.delete(`${this.table.endpoint}/${item.uuid}`).then(() => this.table.removeItem(item))
  }

  goToCreate(): void {
    this.$router.push({ name: AdminRoutes.settingsFinanciersCreate })
  }
}
