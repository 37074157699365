






import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import PageHeader from '@/components/PageHeader.vue'
import { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { UserAttribute } from '@/modules/user/user.model'
import permissions from '@/helpers/permissions'
import DeclinedFundingRequestModel, {
  DeclinedFundingRequestAttribute,
} from '@/modules/declined-funding-requests/declined-funding-request.model'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'

@Component({
  components: {
    AdminLayout,
    PageHeader,
    DataTable,
  },
})
export default class ActivityLogList extends Vue {
  table = new DataTableBase()
    .setModel(DeclinedFundingRequestModel)
    .setEndpoint('funding-requests/declined')
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${DeclinedFundingRequestAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Finansuotojas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
        .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.fundingRequestNumber}`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(false),
      new DataTableHeader()
        .setValue(
          `${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.${UserAttribute.fullName}`,
        )
        .setText('Vardas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.email`)
        .setText('El. paštas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.phone`)
        .setValueParser((value) => `+370${value}`)
        .setText('Tel. nr.')
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.funding_type}`)
        .setText('Tipas')
        .setValueParser((value) => value.name)
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.decline_reason}`)
        .setText('Atmetimo priežastis')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.confirmed_at}`)
        .setText('Pateikimo data')
        .setSortable(false),
      new DataTableHeader().setValue(`${DeclinedFundingRequestAttribute.created_at}`).setText('Atmetimo data'),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setTitle('Informacija')
        .setPermissions([permissions.fundingRequests.show])
        .setAction(this.goToInformation),
    ])

  goToInformation(item: DeclinedFundingRequestModel): void {
    this.$router.push({
      name: AdminRoutes.fundingRequestsShow,
      params: {
        uuid: item[DeclinedFundingRequestAttribute.funding_request].uuid,
      },
    })
  }
}
