import Model from '@/classes/Model'
import IRoleResponse from '@/modules/role/role-response.interface'

export enum RoleAttribute {
  name = 'name',
  slug = 'slug',
  permissions = 'permissions',
}

export default class RoleModel extends Model {
  [RoleAttribute.name]!: string;
  [RoleAttribute.slug]!: string;
  [RoleAttribute.permissions]!: string[]

  transform(data: IRoleResponse): RoleModel {
    super.transform(data)

    this.name = data.name
    this.slug = data.slug
    this.permissions = data.permissions

    return this
  }
}
