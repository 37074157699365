








import { Component, Vue } from 'vue-property-decorator'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import Form from '@/components/Form/Form.vue'
import PageHeader from '@/components/PageHeader.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import RoleModel from '@/modules/role/role.model'
import http from '@/http'
import IResponse from '@/modules/response.interface'
import IPermissionResponse from '@/modules/permission/permission-response.interface'
import PermissionModel from '@/modules/permission/permission.model'
import ObjectField from '@/classes/Form/Fields/ObjectField'
import { AdminRoutes } from '@/helpers/getRouteMeta'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class SettingsTabsRolesCreate extends Vue {
  loadedPermissions: boolean = false
  form: FormBase = new FormBase()
    .setEndpoint('roles')
    .setModel(RoleModel)
    .setFields([new Field().setKey('name').setTitle('Pavadinimas'), new Field().setKey('slug').setTitle('Trumpinys')])
    .setOnSuccess(this.onSuccess)

  created(): void {
    http.get('/permissions').then((response: IResponse<IPermissionResponse[]>) => {
      const permissions = response.data.data.map((item: IPermissionResponse) => new PermissionModel().transform(item))
      this.form.addField(
        new ObjectField()
          .setKey('permissions')
          .setTitle('Leidimai')
          .setChildren(
            permissions.map((permission: PermissionModel) =>
              new Field()
                .setType(FieldTypes.checkbox)
                .setKey(permission.uuid)
                .setTitle(permission.name)
                .setSize(FieldSizes.quarter)
                .setDense(true)
                .setHideDetails(true),
            ),
          ),
      )
      this.loadedPermissions = true
    })
  }

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Rolės duomenys buvo sėkmingai paredaguoti',
    })
    this.goToList()
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.settingsRolesIndex })
  }
}
