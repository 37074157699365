import DataTableFilter from '@/classes/DataTable/DataTableFilter'

export default class DataTableFilterObject {
  hasClear: boolean = true
  filters: DataTableFilter[] = []

  setHasClear(hasClear: boolean): DataTableFilterObject {
    this.hasClear = hasClear

    return this
  }

  setFilters(filters: DataTableFilter[]): DataTableFilterObject {
    this.filters = filters

    return this
  }
}
