import Model from '@/classes/Model'
import IRecommendationResponse from '@/modules/recommendation/recommendation-field-response.interface'

export enum RecommendationFieldAttribute {
  uuid = 'uuid',
  title = 'title',
  is_business = 'is_business',
  is_individual = 'is_individual',
  index = 'index',
  is_active = 'is_active',
}

export default class RecommendationFieldModel extends Model {
  [RecommendationFieldAttribute.uuid]!: string;
  [RecommendationFieldAttribute.title]!: string;
  [RecommendationFieldAttribute.is_business]!: boolean;
  [RecommendationFieldAttribute.is_individual]!: boolean;
  [RecommendationFieldAttribute.index]!: number;
  [RecommendationFieldAttribute.is_active]!: boolean

  transform(data: IRecommendationResponse): RecommendationFieldModel {
    super.transform(data)

    this.uuid = data.uuid
    this.title = data.title
    this.is_business = data.is_business
    this.is_individual = data.is_individual
    this.index = data.index
    this.is_active = data.is_active

    return this
  }
}
