


































































import { Component, Prop, Vue } from 'vue-property-decorator'
import http from '@/http'
import { DataOptions } from 'vuetify'
import IModelResponse from '@/modules/model-response.interface'
import IResponse from '@/modules/response.interface'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import HeaderTypes from '@/components/DataTable/HeaderTypes.vue'
import Sortable, { SortableEvent } from 'sortablejs'
import { Actions } from '@/store/global/actions'
import Filters from '@/components/DataTable/Filters.vue'
import _ from 'lodash'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    HeaderTypes,
    Filters,
  },
})
export default class DataTable extends Vue {
  @Prop() table!: DataTableBase
  options: DataOptions | any = {}
  total: number = 0
  page: number = 1
  perPage: number = 20
  perPageFromBackend: number | null = null
  pageFromBackend: number | null = null
  footerProps: any = {
    itemsPerPageOptions: [20, 50, 100],
    showFirstLastPage: true,
  }
  sortable!: Sortable
  sortableList: any[] = []
  selectedItems: any = this.table.selected

  created(): void {
    this.addActionBtn()
    this.setDefaults()
  }

  mounted(): void {
    this.filter()
    this.$watch('$route', this.filter)
    this.$watch('options', this.updateQuery, { deep: true })
    this.$watch('table.data', () => {
      this.sortableList = [...this.table.data]

      if (this.total === 0) {
        this.total = this.table.data.length
      }
    })

    if (this.table.isSortable) this.initialSortable()
  }

  get loading(): boolean {
    return this.table.loading
  }

  filter(): void {
    const { query } = this.$router.currentRoute

    const searchParams = new URLSearchParams('')
    let url = this.table.endpoint

    Object.keys(query).forEach((key: string) => searchParams.set(key, query[key] as string))

    if (searchParams.toString().length > 0) url += `?${searchParams.toString()}`

    if (url) this.table.loading = true

    url &&
      http.get(url).then((response: IResponse<IModelResponse[]>) => {
        const { data } = response

        this.perPageFromBackend = data.meta.per_page
        this.pageFromBackend = data.meta.current_page
        this.perPage = data.meta.per_page
        this.options.itemsPerPage = data.meta.per_page

        const { model } = this.table
        this.table.setData(data.data.map((item: IModelResponse) => new model().transform(item)))
        this.total = data.meta.total
        this.table.loading = false
      })
  }

  setDefaults(): void {
    // eslint-disable-next-line prefer-const
    let { query, name } = this.$router.currentRoute
    query = { ...query }

    const { per_page } = query

    query.sort_by = 'funding_request_number'
    query.order = 'desc'

    if (!per_page) {
      this.$router.push({ name: name as string, query }).catch(() => {})

      return
    }

    if (this.footerProps.itemsPerPageOptions.find((value: number) => value === Number(per_page))) {
      this.perPage = Number(query.per_page)
    } else {
      query.per_page = this.perPage.toString()
    }

    this.page = Number(query.page)

    this.$router.push({ name: name as string, query }).catch(() => {})
  }

  updateQuery(): void {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options
    // eslint-disable-next-line prefer-const
    let { query, name } = this.$router.currentRoute
    query = { ...query }

    if (itemsPerPage !== this.perPageFromBackend || query.per_page || this.pageFromBackend !== page) {
      query.page = page
      query.per_page = itemsPerPage
    }

    if (sortBy[0]) {
      // eslint-disable-next-line prefer-destructuring
      query.sort_by = sortBy[0]
      query.order = sortDesc[0] ? 'asc' : 'desc'
    } else if (query.sort_by !== 'funding_request_number') {
      delete query.sort_by
      delete query.order
    }

    this.$router.push({ name: name as string, query }).catch(() => {})
  }

  addActionBtn(): void {
    if (this.table.actions.length === 0) return

    if (this.table.headers.find((header: DataTableHeader) => header.value === 'action')) return

    this.table.addHeader(
      new DataTableHeader()
        .setType(DataTableHeaderTypes.action)
        .setValue('action')
        .setText('')
        .setAlign('center')
        .setSortable(false),
    )
  }

  private initialSortable(): void {
    this.addSortableHeader()

    const { table } = this.$refs

    if (!table) return

    if ('$el' in table) {
      this.sortable = new Sortable(table.$el.getElementsByTagName('tbody')[0], {
        draggable: '.sortable-row',
        handle: '.sortable-handle',
        onEnd: this.reorder,
      })
    }
  }

  private addSortableHeader(): void {
    if (this.table.headers.find((header: DataTableHeader) => header.defaultValue === 'drag_handle')) return
    this.table.unshiftHeader(
      new DataTableHeader()
        .setType(DataTableHeaderTypes.icon)
        .setDefaultValue('drag_handle')
        .setClasses('sortable-handle')
        .setText('')
        .setSortable(false),
    )
  }

  private reorder(event: SortableEvent): void {
    const movedItem = this.sortableList.splice(event.oldIndex || 0, 1)[0]
    this.sortableList.splice(event.newIndex || 0, 0, movedItem)

    http
      .post(
        `${this.table.endpoint}/reordering`,
        this.sortableList.map((item: any) => item.uuid),
      )
      .then(() =>
        this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Perūšiavimas buvo sėkmingas',
        }),
      )
  }

  selectItem(item: any, value: boolean): void {
    // @ts-ignore
    this.$refs.table.select(item, value)
  }

  clearSelectedItems(): void {
    this.selectedItems = []
  }

  updateSelectedItems(data: any): void {
    const { item } = data

    const { value } = data

    const selected = _.filter(this.selectedItems, (selectedItem) => !!selectedItem)

    if (value) selected.push(item)

    if (!value) {
      const index = _.findIndex(selected, (subItem: any) => {
        return subItem[this.table.selectBy] === item[this.table.selectBy]
      })
      selected.splice(index, 1)
    }

    this.$emit('itemsSelected', selected)
  }

  updateAllSelectedItems(data: any): void {
    const { items } = data

    const { value } = data
    let selected = this.selectedItems

    if (value) selected = items

    if (!value) selected = []

    this.$emit('itemsSelected', selected)
  }

  fetchExpandedData(props, table: DataTableBase): void {
    if (!table.expansionDataCallback) {
      console.warn('Expansion callback not set')

      return
    }

    table
      .expansionDataCallback(props.item)
      .then((response: AxiosResponse) => {
        table.setExpandedData(response.data)
      })
      .finally(() => {
        props.expand(true)
      })
  }
}
