import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'

export default class ObjectField extends Field {
  type: FieldTypes = FieldTypes.object
  children: Field[] = []

  setChildren(children: Field[]): this {
    this.children = children

    return this
  }
}
