











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import { OfferAttribute } from '@/modules/offer/offer.model'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { HttpMethod } from '@/helpers/requests'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { FundingRequestFinancierInfoAttribute } from '@/modules/funding-request-financier-info/funding-request-financier-info.model'

@Component({
  components: {
    Form,
  },
})
export default class CreateDialog extends DialogBase {
  selectedDocuments: string | null = null
  documentsField: SelectField = new SelectField()
    .isMultiple()
    .setVisibleIf((values: any) => !!values.financier_uuid)
    .setKey('additional_documents')
    .setTitle('Papildomi dokumentai')
  form: FormBase = new FormBase()
    .setEndpoint(`additional-documents/requestDocuments/${this.meta.fundingRequestUuid}`)
    .setMethod(HttpMethod.POST)
    .setEntry({
      financier_uuid: this.meta.user.financier_uuid,
    })
    .setInjectValues({
      funding_request_uuid: this.meta.fundingRequestUuid,
    })
    .setFields([
      new SelectField()
        .setKey(OfferAttribute.financier_uuid)
        .setValue(this.meta.user.financier_uuid)
        .setTitle('Finansuotojas')
        .loadItems({
          endpoint: `funding-requests/${this.meta.fundingRequestUuid}/available-financiers`,
          value: 'uuid',
          title: 'name',
        })
        .setOnChange(this.changeFinancier),
      this.documentsField,
      new Field().setType(FieldTypes.filesView).setTitle('Dokumentai').setMeta({
        document: this.selectedDocuments,
        allDocuments: this.documentsField.items,
      }),
      new Field()
        .setType(FieldTypes.text)
        .setKey(FundingRequestFinancierInfoAttribute.remarks)
        .setTitle('Pastabos')
        .setVisibleIf((values: any) => !!values.financier_uuid),
    ])
    .setOnSuccess(this.onSuccess)

  mounted(): void {
    if (this.meta.user.financier_uuid && this.meta.user.roleUuid === 'FINANCIER') {
      this.fetchFinancierDocuments()
    }
  }

  resetDocumentsField(): void {
    this.form.removeField(3)
    this.form.removeField(2)

    this.documentsField.value = []

    this.form.addField(
      new Field().setType(FieldTypes.filesView).setTitle('Dokumentai').setMeta({
        selectedDocuments: [],
        allDocuments: [],
      }),
    )

    this.addNotesField()
  }

  changeFinancier(): void {
    this.resetDocumentsField()
    this.fetchFinancierDocuments()
  }

  addNotesField(): void {
    this.form.addField(
      new Field()
        .setType(FieldTypes.text)
        .setKey(FundingRequestFinancierInfoAttribute.remarks)
        .setTitle('Pastabos')
        .setVisibleIf((values: any) => !!values.financier_uuid),
    )
  }

  onSuccess(data: any): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Dokumentai sėkmingai išsaugoti!',
    })
    this.closeDialog()
    this.meta.onSuccess(data.data)
  }

  fetchFinancierDocuments(): void {
    this.documentsField.loadTemplateDocumentItems({
      endpoint: `/additional-documents?is_active=true&financier_uuid=${this.form.data.financier_uuid}`,
      value: 'uuid',
      title: 'title',
      template_document: 'template_document',
    })
  }

  created(): void {
    this.$watch(
      'form.data.additional_documents',
      () => {
        const { data } = this.form

        if (!data) return

        const { additional_documents } = data

        if (additional_documents && additional_documents.length) {
          this.selectedDocuments = additional_documents

          this.form.addField(
            new Field().setType(FieldTypes.filesView).setTitle('Dokumentai').setMeta({
              selectedDocuments: this.selectedDocuments,
              allDocuments: this.documentsField.items,
            }),
          )

          this.addNotesField()

          this.form.removeField(3)
          this.form.removeField(2)
        } else if (this.selectedDocuments !== null) {
          this.resetDocumentsField()
        }
      },
      { deep: true },
    )
  }
}
