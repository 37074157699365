import Model from '@/classes/Model'
import IPermissionResponse from '@/modules/permission/permission-response.interface'

export default class PermissionModel extends Model {
  name!: string

  transform(data: IPermissionResponse): PermissionModel {
    super.transform(data)

    this.name = data.name

    return this
  }
}
