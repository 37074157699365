











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import FundingRequestFieldModel from '@/modules/funding-request-field/funding-request-field.model'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'

@Component({
  components: {
    Form,
  },
})
export default class RepeatNotificationDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setEndpoint(`funding-requests/${this.$router.currentRoute.params.uuid}/repeat-info-notifications`)
    .setFields([
      new SelectField()
        .isMultiple()
        .setKey('financier_uuid')
        .setTitle('Finansuotojai')
        .loadItems({
          endpoint: `/funding-requests/${this.$router.currentRoute.params.uuid}/financiers`,
          value: 'uuid',
          title: 'name',
        }),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any, entry: FundingRequestFieldModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Klientai sėkmingai informuoti!',
    })
    this.meta.onSuccess(entry)
    this.closeDialog()
  }
}
