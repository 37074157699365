











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import Field from '@/classes/Form/Field'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import OfferModel, { OfferAttribute } from '@/modules/offer/offer.model'
import { FieldTypes } from '@/components/Form/FieldTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import { HttpMethod } from '@/helpers/requests'
import { documentsTypes } from '@/helpers/mimeTypes'

@Component({
  components: {
    Form,
  },
})
export default class CreateDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setMethod(HttpMethod.POST)
    .setFiles(true)
    .setEndpoint('offers')
    .setModel(OfferModel)
    .setEntry({
      financier_uuid: this.meta.user.financier_uuid,
      period: this.meta.fundingRequest.period,
      amount: this.meta.fundingRequest.amount,
      monthlyAmount: this.meta.fundingRequest.monthlyAmount,
    })
    .setInjectValues({
      funding_request_uuid: this.meta.fundingRequestUuid,
    })
    .setFields([
      new SelectField()
        .setKey(OfferAttribute.financier_uuid)
        .setValue(this.meta.user.financier_uuid)
        .setTitle('Finansuotojas')
        .loadItems({
          endpoint: `funding-requests/${this.meta.fundingRequestUuid}/available-financiers`,
          value: 'uuid',
          title: 'name',
        }),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.period)
        .setTitle('Periodas')
        .setAppend(this.meta.fundingRequest.period_measure),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.annualInterestRate)
        .setEntryKey(OfferAttribute.annualInterestRate)
        .setTitle('Palūkanos')
        .setAppend('%'),
      new Field().setType(FieldTypes.number).setKey(OfferAttribute.amount).setTitle('Suma').setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.monthlyAmount)
        .setEntryKey(OfferAttribute.monthlyAmount)
        .setTitle('Mėn. suma')
        .setAppend('€/mėn'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.finalAmount)
        .setTitle('Bendra gražintina suma')
        .setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setVisibleIf(() => this.meta.fundingRequest.type !== 'business')
        .setKey(OfferAttribute.bvkkmn)
        .setTitle('*BVKKMN')
        .setAppend('%'),
      new Field().setType(FieldTypes.textArea).setKey(OfferAttribute.description).setTitle('Aprašymas'),
      new SelectField()
        .setKey(OfferAttribute.type)
        .setTitle('Tipas')
        .setItems([
          new SelectItem().setTitle('Įpareigojantis').setValue('binding'),
          new SelectItem().setTitle('Preliminarus').setValue('preliminary'),
        ]),
      new Field()
        .setType(FieldTypes.file)
        .setDisablePreview(true)
        .setKey(OfferAttribute.attachments)
        .setTitle('Dokumentai')
        .setMeta({
          type: 'file',
          accept: documentsTypes.join(','),
          multiple: true,
        }),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any, entry: OfferModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Pasiūlymas sukurtas',
    })
    this.meta.onSuccess(entry)
    this.closeDialog()
  }
}
