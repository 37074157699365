var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('PageHeader',{attrs:{"title":"Skolos"}}),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":[
      { text: 'Suma', value: 'amount', sortable: false },
      { text: 'Pasiskolinta suma', value: 'amount_original', sortable: false },
      { text: 'Kreditorius', value: 'creditor_name' },
      { text: 'Data', value: 'date' },
      { text: 'Paskolos statuso data', value: 'debt_status_date', sortable: false },
      { text: 'Užginčyta', value: 'disputed', sortable: false },
      { text: 'Statusas', value: 'status', sortable: false },
      { text: 'Tipas', value: 'type', sortable: false } ],"items":_vm.debts,"item-key":"uuid","show-select":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount + ' ' + item.currency))])]}},{key:"item.amount_original",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount_original + ' ' + item.currency))])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date.date).toLocaleDateString())+" ")]}},{key:"item.debt_status_date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.debt_status_date.date).toLocaleDateString())+" ")]}}])}),(_vm.spouseDebts.length)?_c('div',{staticClass:"mt-15"},[_c('PageHeader',{attrs:{"title":"Sutuoktinio skolos"}}),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":[
        { text: 'Suma', value: 'amount', sortable: false },
        { text: 'Pasiskolinta suma', value: 'amount_original', sortable: false },
        { text: 'Kreditorius', value: 'creditor_name' },
        { text: 'Data', value: 'date' },
        { text: 'Paskolos statuso data', value: 'debt_status_date', sortable: false },
        { text: 'Užginčyta', value: 'disputed', sortable: false },
        { text: 'Statusas', value: 'status', sortable: false },
        { text: 'Tipas', value: 'type', sortable: false } ],"items":_vm.spouseDebts,"item-key":"uuid","show-select":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount + ' ' + item.currency))])]}},{key:"item.amount_original",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount_original + ' ' + item.currency))])]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date.date).toLocaleDateString())+" ")]}},{key:"item.debt_status_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.debt_status_date.date).toLocaleDateString())+" ")]}}],null,false,3589170633)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }