import state from '@/store/global/state'
import getters from '@/store/global/getters'
import mutations from '@/store/global/mutations'
import actions from '@/store/global/actions'

export default {
  state,
  getters,
  mutations,
  actions,
}
