









import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/components/DataTable/HeaderTypes/AbstractHeader'
import Model from '@/classes/Model'
import can from '@/helpers/can'
import UserModel from '@/modules/user/user.model'
import { Getters as AuthGetters } from '@/store/auth/getters'

@Component
export default class TextHeader extends AbstractHeader {
  private user: UserModel = this.$store.getters[AuthGetters.getUser]

  hasPermission(permissions: string[], item: Model): boolean {
    if (this.user.isAdmin) return true

    return item.hasDynamicPermissions(permissions) && can(permissions)
  }
}
