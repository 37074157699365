




























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import FinancierModel, { FinancierAttribute } from '@/modules/financier/financier.model'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import { getFinanciersStatus } from '@/helpers/fundingRequest'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class SettingsTabsFinanciersList extends Vue {
  showStatusModal: boolean = false
  statusData: any = {}
  permissions = permissions
  table = new DataTableBase()
    .setModel(FinancierModel)
    .setEndpoint('financiers')
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue('logo')
        .setText('Logotipas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader().setValue(FinancierAttribute.name).setText('Pavadinimas'),
      new DataTableHeader().setValue(FinancierAttribute.email).setText('El. paštas'),
      new DataTableHeader()
        .setKey(FinancierAttribute.minAmount)
        .setValue('min_amount')
        .setText('Min. suma')
        .setValueParser((value: any) => `${value} €`),
      new DataTableHeader()
        .setKey(FinancierAttribute.maxAmount)
        .setValue('max_amount')
        .setText('Maks. suma')
        .setValueParser((value: any) => `${value} €`),
      new DataTableHeader().setText('Statusas').setValue('status').setValueParser(getFinanciersStatus),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.editItem)
        .setPermissions([permissions.financiers.edit, permissions.financiers.show]),
      new DataTableAction()
        .setIcon('delete')
        .setTitle('Ištrinti')
        .setAction(this.deleteItem)
        .setPermissions([permissions.financiers.delete]),
      // (new DataTableAction()).setTitle('Aktyvuoti/Deaktyvuoti').setAction(this.changeItemStatus)
      //   .setPermissions([permissions.financiers.activate])
    ])

  editItem(item: FinancierModel): void {
    this.$router.push({
      name: AdminRoutes.settingsFinanciersEdit,
      params: {
        uuid: item.uuid,
      },
    })
  }

  delete(item: FinancierModel): Promise<DataTableBase> {
    return http.delete(`${this.table.endpoint}/${item.uuid}`).then(() => this.table.removeItem(item))
  }

  deleteItem(item: FinancierModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite finansuotoją (${item.name}) ir šis veiksmas negalės būti atšaukti.`,
        onYes: () => this.delete(item),
      },
    })
  }

  // changeItemStatus(item: FinancierModel) {
  //   let endpoint = item.status === 'active' ? 'deactivate' : 'activate'
  //   return http.put(`/financiers/${item.uuid}/${endpoint}`)
  //     .then(() => {
  //       this.table.setItem(item)
  //       // @ts-ignore
  //       this.$refs.table.filter()
  //     })
  // }

  goToCreate(): void {
    this.$router.push({ name: AdminRoutes.settingsFinanciersCreate })
  }

  getStatus(): void {
    http.get('/credit-info/status').then((response) => {
      this.showStatusModal = true
      this.statusData = response.data.data
    })
  }
}
