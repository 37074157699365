import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import * as Sentry from '@sentry/vue'

Vue.config.productionTip = false

Sentry.setTag('repository', 'finbro-admin')

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN || '',
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted(): void {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
