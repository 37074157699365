import { IFormError, ISubmit, Positions } from '@/interfaces/form'
import Field from '@/classes/Form/Field'
import { HttpMethod } from '@/helpers/requests'

export default class FormBase {
  uuid!: string
  endpoint!: string
  fields: Field[] = []
  errors: IFormError = {}
  data: any = {}
  withoutData: boolean = false
  method: HttpMethod = HttpMethod.POST
  injectValues!: any
  model!: any
  entry!: any
  loading: boolean = false
  files: boolean = false
  initialCall: boolean = false
  submit: ISubmit = {
    color: 'primary',
    class: '',
    text: 'Išsaugoti',
    position: Positions.left,
    disabled: false,
  }
  onSuccess!: (data: any, entry?: any) => void

  setUuid(uuid: string): this {
    this.uuid = uuid

    return this
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint

    return this
  }

  setInitialCall(initial: boolean): this {
    this.initialCall = initial

    return this
  }

  setFields(fields: Field[]): this {
    this.fields = fields

    return this
  }

  addField(field: Field): this {
    this.fields = [...this.fields, field]

    return this
  }

  removeField(index: number): this {
    this.fields = this.fields.filter((_, i) => i !== index)

    return this
  }

  setInjectValues(injectValues: any): this {
    this.injectValues = injectValues

    return this
  }

  setModel(model: any): this {
    this.model = model

    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading

    return this
  }

  setFiles(files: boolean): this {
    this.files = files

    return this
  }

  setSubmit(submit: ISubmit): this {
    this.submit = submit

    return this
  }

  setOnSuccess(onSuccess: (data: any, entry?: any) => void): this {
    this.onSuccess = onSuccess

    return this
  }

  setData(data: any): this {
    this.data = data

    return this
  }

  setErrors(errors: IFormError): this {
    this.errors = errors

    return this
  }

  changeError(key: string, value: any): void {
    this.errors[key] = value
  }

  setMethod(method: HttpMethod): this {
    this.method = method

    return this
  }

  setEntry(entry: any): this {
    this.entry = entry

    return this
  }

  setWithoutData(withoutData: boolean): this {
    this.withoutData = withoutData

    return this
  }
}
