















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subtitle!: string
}
