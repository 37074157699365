import Model from '@/classes/Model'
import IActivityLogResponse from '@/modules/activity-log/activity-log-response.interface'
import UserModel from '@/modules/user/user.model'
import FinancierModel from '@/modules/financier/financier.model'

export enum ActivityLogAttribute {
  type = 'type',
  activity_text = 'activityText',
  user = 'user',
  financier = 'financier',
  loggable = 'loggable',
  created_at = 'createdAt',
}

export default class ActivityLogModel extends Model {
  [ActivityLogAttribute.type]!: string;
  [ActivityLogAttribute.activity_text]!: string;
  [ActivityLogAttribute.user]!: UserModel;
  [ActivityLogAttribute.financier]!: FinancierModel;
  [ActivityLogAttribute.loggable]!: any

  transform(data: IActivityLogResponse): ActivityLogModel {
    super.transform(data)

    if (data.financier) {
      const financierData = new FinancierModel().transform(data.financier)

      if (!data.user) {
        // @ts-ignore
        data.user = { financier: financierData }
        // @ts-ignore
      } else data.user.financier = financierData
    }

    this.type = data.type
    this.activityText = data.activity_text
    this.user = data.user && new UserModel().transform(data.user)
    this.loggable = data.loggable

    return this
  }
}
