















import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'
import { NavigationState } from '@/store/global/helpers'
import { Getters } from '@/store/auth/getters'
import { Getters as GlobalGetters } from '@/store/global/getters'
import UserModel from '@/modules/user/user.model'
import Snackbar from '@/components/Alerts/Snackbar.vue'
import Dialog from '@/components/Dialog.vue'

@Component({
  components: {
    Snackbar,
    Dialog,
    Header,
    Navigation,
  },
})
export default class AdminLayout extends Vue {
  loading: boolean = this.$store.getters[GlobalGetters.getLoading]

  get contentClass(): string {
    const navigationState = this.$store.getters[GlobalGetters.getNavigation]

    if (navigationState === NavigationState.minimized) {
      return 'content-minimized'
    }

    return 'content-default'
  }

  get user(): UserModel | null {
    return this.$store.getters[Getters.getUser]
  }
}
