








import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/components/DataTable/HeaderTypes/AbstractHeader'

@Component
export default class LinkHeader extends AbstractHeader {}
