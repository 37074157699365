





import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import ObjectField from '@/classes/Form/Fields/ObjectField'
import PermissionModel from '@/modules/permission/permission.model'
import http from '@/http'
import IResponse from '@/modules/response.interface'
import IPermissionResponse from '@/modules/permission/permission-response.interface'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class CustomersEditTabsPermissionsEdit extends Vue {
  loadedPermissions: boolean = false
  form!: FormBase

  async created(): Promise<void> {
    const userId = this.$router.currentRoute.params.uuid
    let permissions: any = {}
    await http.get(`users/${userId}/permissions`).then((response) => (permissions = response.data.data))

    this.form = new FormBase()
      .setEntry({ permissions })
      .setEndpoint(`users/${userId}/permissions`)
      .setOnSuccess(this.onSuccess)

    http.get('/permissions').then((response: IResponse<IPermissionResponse[]>) => {
      const permissions = response.data.data.map((item: IPermissionResponse) => new PermissionModel().transform(item))
      this.form.addField(
        new ObjectField()
          .setKey('permissions')
          .setChildren(
            permissions.map((permission: PermissionModel) =>
              new Field()
                .setType(FieldTypes.checkbox)
                .setKey(permission.uuid)
                .setTitle(permission.name)
                .setSize(FieldSizes.quarter)
                .setDense(true)
                .setHideDetails(true),
            ),
          ),
      )
      this.loadedPermissions = true
    })
  }

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: `Darbuotojo leidimai atnaujinti`,
    })
  }
}
