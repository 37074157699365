



import { Component } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import AbstractField from '@/classes/Form/Fields/AbstractField'

@Component({
  components: {
    VueEditor,
  },
})
export default class HtmlField extends AbstractField {}
