import Model from '@/classes/Model'
import IAdditionalDocumentResponse from '@/modules/additional-documents/additional-document-response.interface'
import FinancierModel from '@/modules/financier/financier.model'
import ITemplateDocument from '@/modules/attachment/template-document.interface'

export enum AdditionalDocumentAttribute {
  uuid = 'uuid',
  title = 'title',
  isActive = 'is_active',
  remove_template = 'remove_template',

  financier_uuid = 'financier_uuid',
  financier = 'financier',
  funding_request_financier_document = 'funding_request_financier_document',
  template_document = 'template_document',
}

export default class AdditionalDocumentModel extends Model {
  [AdditionalDocumentAttribute.title]!: string;
  [AdditionalDocumentAttribute.isActive]!: boolean;

  [AdditionalDocumentAttribute.financier_uuid]!: string;
  [AdditionalDocumentAttribute.financier]!: FinancierModel;
  [AdditionalDocumentAttribute.funding_request_financier_document]!: any;
  [AdditionalDocumentAttribute.template_document]!: ITemplateDocument

  transform(data: IAdditionalDocumentResponse): AdditionalDocumentModel {
    super.transform(data)

    this.title = data.title
    this.is_active = data.is_active
    this.financier_uuid = data.financier_uuid
    this.financier = data.financier && new FinancierModel().transform(data.financier)
    this.funding_request_financier_document = data.funding_request_financier_document
    this.template_document = data.template_document

    return this
  }
}
