











import { Component, Vue } from 'vue-property-decorator'
import AuthLayout from '@/layouts/AuthLayout.vue'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import Form from '@/components/Form/Form.vue'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import FormBase from '@/classes/Form/FormBase'
import { Positions } from '@/interfaces/form'

@Component({
  components: {
    AuthLayout,
    Form,
  },
})
export default class RemindPassword extends Vue {
  message: string | null = null
  form!: FormBase

  created(): void {
    this.form = new FormBase()
      .setEndpoint('password/reset')
      .setSubmit({
        text: 'Priminti slaptažodį',
        position: Positions.center,
      })
      .setFields([new Field().setType(FieldTypes.email).setKey('email').setTitle('El. Paštas')])
      .setInjectValues({
        url: this.$router.resolve({
          name: AdminRoutes.resetPassword,
          params: {
            token: ':token',
            email: ':email',
          },
        }).href,
      })
      .setOnSuccess(this.onSuccess)
  }

  goToLogin(): void {
    this.$router.push({ name: AdminRoutes.login })
  }

  onSuccess(response: any): void {
    this.message = response.message
  }
}
