import { notificationTemplateTypes, notificationTypes } from '@/modules/notification-template/constants'
import _ from 'lodash'

export function notificationTypeParser(value: string): string | null {
  return notificationValueParser(notificationTypes, value)
}

export function notificationTemplateTypeParser(value: string): string | null {
  return notificationValueParser(notificationTemplateTypes, value)
}

export function notificationValueParser(types: any[], value: string): any {
  const found = _.find(types, (item: any) => item.key === value)

  if (!found) return null

  return found.value
}
