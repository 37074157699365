export enum SnackbarTypes {
  error = 'error',
  success = 'success',
}

export interface ISnackbar {
  type: SnackbarTypes
  message: string
}

export enum NavigationState {
  default = 'default',
  minimized = 'minimized',
}

export interface IDialog {
  show: boolean
  component: any
  maxWidth: number
  meta?: any
  closeBlack?: any
  close: boolean
}

export interface IState {
  snackbar: ISnackbar | null
  navigation: NavigationState
  dialog: IDialog
  loading: boolean
}
