









































































import { Component, Inject, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import can from '@/helpers/can'
import Tabs from '@/components/Tabs.vue'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import DebtModel from '@/modules/debt/debt.model'
import IDebtInterface from '@/modules/debt/debt.interface'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class Debts extends Vue {
  @Inject('fundingRequest') getFundingRequest!: () => FundingRequestModel
  debts: DebtModel[] = []
  spouseDebts: DebtModel[] = []

  created(): void {
    const fundingRequest = this.getFundingRequest()

    const debts = fundingRequest.debts_data?.data?.Debts

    if (debts) {
      Object.keys(debts).forEach((key) => {
        debts[key].forEach((debtData: IDebtInterface) => {
          this.debts.push(new DebtModel().transform(debtData))
        })
      })
    }

    const spouseDebts = fundingRequest.spouse_debts_data?.data?.Debts

    if (!spouseDebts) return

    Object.keys(spouseDebts).forEach((key) => {
      this.spouseDebts[key].forEach((debtData: IDebtInterface) => {
        this.spouseDebts.push(new DebtModel().transform(debtData))
      })
    })
  }
}
