import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import tokenHelper from '@/helpers/tokenHelper'
import store from '@/store/index'
import { Actions } from '@/store/global/actions'
import { Actions as AuthActions } from '@/store/auth/actions'
import { SnackbarTypes } from '@/store/global/helpers'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://local.finbro.com',
})

http.interceptors.request.use((config: AxiosRequestConfig) => {
  if (tokenHelper.getToken()) {
    config.headers.Authorization = `Bearer ${tokenHelper.getToken()}`
  }

  store.dispatch(Actions.showLoading, true)

  return config
})

let isRefreshing = false

const subscribers = []

http.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(Actions.showLoading, false)

    return response
  },
  async (error: AxiosError<any>) => {
    const token = tokenHelper.getToken()

    let message = 'Kažkas nutiko netaip, pamėginkite dar kartą.'

    if (error.response && error.response.data && error.response.data.message !== '')
      message = error.response.data.message

    const originalRequest = error.config

    // Check if we should refresh the token
    let shouldRefresh =
      token !== null &&
      error.response &&
      (error.response.status === 401 || error.response.status === 402) &&
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest._retry

    // Skips if the request is for token refresh
    if (originalRequest.url === '/refresh') {
      shouldRefresh = false
    }

    // If token should be refreshed
    if (shouldRefresh) {
      // Mark this request as one who's refreshing token
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true

      // Refresh the token only if not yet refreshing
      if (!isRefreshing) {
        isRefreshing = true

        http
          .post('/refresh')
          .then((response: any) => {
            tokenHelper.setToken(response.data.access_token.token)
            isRefreshing = false
            onTokenRefreshed({ token: response.data.access_token })

            return store.dispatch(AuthActions.me)
          })
          .catch(() => {
            store.dispatch(AuthActions.reset)
          })
      }

      // Subscribe to token refresh
      return new Promise((resolve) => {
        // @ts-ignore
        subscribeTokenRefresh(({ token }) => {
          if (originalRequest.headers.Authorization && originalRequest.headers.Authorization.indexOf('Bearer') > -1) {
            originalRequest.headers.Authorization = `Bearer ${token}`
          }

          return resolve(http.request(originalRequest))
        })
      })
    }

    store.dispatch(Actions.showSnackbar, {
      type: SnackbarTypes.error,
      message,
    })
    store.dispatch(Actions.showLoading, false)

    return Promise.reject(error)
  },
)

function subscribeTokenRefresh(call: any): void {
  // @ts-ignore
  subscribers.push(call)
}

function onTokenRefreshed({ token }): void {
  subscribers.map((call: any) => call({ token }))
}

export default http
