import { NavigationState, IState } from '@/store/global/helpers'

export default (): IState => ({
  snackbar: null,
  navigation: NavigationState.default,
  dialog: {
    show: false,
    component: null,
    maxWidth: 350,
    close: true,
  },
  loading: false,
})
