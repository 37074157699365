









import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableFilter, { FilterOperators } from '@/classes/DataTable/DataTableFilter'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import { RoleAttribute } from '@/modules/role/role.model'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'

@Component({
  components: {
    DataTable,
    AdminLayout,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class EmployeesList extends Vue {
  permissions = permissions
  table = new DataTableBase()
    .setModel(UserModel)
    .setEndpoint('users')
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(UserAttribute.avatar)
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader().setKey(UserAttribute.fullName).setValue('full_name').setText('Vardas'),
      new DataTableHeader().setValue(UserAttribute.email).setText('El. paštas'),
      new DataTableHeader().setValue(`${UserAttribute.role}.${RoleAttribute.name}`).setText('Rolė').setSortable(false),
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setRelation(UserAttribute.role)
        .setField(
          new SelectField()
            .isMultiple()
            .setKey(RoleAttribute.name)
            .setTitle('Rolė')
            .loadItems({ endpoint: '/roles?filter-notIn-slug=REGULAR,WORKER', value: 'name', title: 'name' }),
        ),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.editItem)
        .setPermissions([permissions.users.edit, permissions.users.show]),
      new DataTableAction()
        .setIcon('delete')
        .setTitle('Ištrinti')
        .setAction(this.deleteItem)
        .setPermissions([permissions.users.delete]),
    ])

  goToCreate(): void {
    this.$router.push({ name: AdminRoutes.employeesCreate })
  }

  editItem(item: UserModel): void {
    this.$router.push({
      name: AdminRoutes.employeesEdit,
      params: {
        uuid: item.uuid,
      },
    })
  }

  deleteItem(item: UserModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite darbuotoją (${item.fullName}) ir šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: UserModel): Promise<DataTableBase> {
    return http.delete(`${this.table.endpoint}/${item.uuid}`).then(() => this.table.removeItem(item))
  }
}
