export const documentsTypes = [
  'application/pdf',
  'application/xls',
  'application/xlsx',
  'application/xml',
  'application/doc',
  'application/docx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats,officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
