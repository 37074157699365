












import { Component, Vue } from 'vue-property-decorator'
import AuthLayout from '@/layouts/AuthLayout.vue'
import Form from '@/components/Form/Form.vue'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import FormBase from '@/classes/Form/FormBase'
import { Positions } from '@/interfaces/form'

@Component({
  components: {
    AuthLayout,
    Form,
  },
})
export default class ResetPassword extends Vue {
  message: string | null = null
  form!: FormBase

  created(): void {
    const { token, email } = this.$router.currentRoute.params

    this.form = new FormBase()
      .setEndpoint('password/update')
      .setSubmit({
        text: 'Pakeisti slaptažodį',
        position: Positions.center,
      })
      .setFields([
        new Field().setType(FieldTypes.password).setKey('password').setTitle('Slaptažodis'),
        new Field().setType(FieldTypes.password).setKey('password_confirmation').setTitle('Pakartokite slaptažodį'),
      ])
      .setInjectValues({
        token,
        email,
      })
      .setOnSuccess(this.onSuccess)
  }

  onSuccess(response: any): void {
    this.message = response.message
  }

  goToLogin(): void {
    this.$router.push({ name: AdminRoutes.login })
  }
}
