







import { Component, Vue } from 'vue-property-decorator'
import { Getters } from '@/store/global/getters'
import { MutationPayload } from 'vuex'
import { ISnackbar } from '@/store/global/helpers'
import { Mutations } from '@/store/global/mutations'
import { Actions } from '@/store/global/actions'

@Component
export default class Snackbar extends Vue {
  snackbar: ISnackbar | null = this.$store.getters[Getters.getSnackbar]

  created(): void {
    this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === Mutations.setSnackbar) {
        this.snackbar = this.$store.getters[Getters.getSnackbar]

        if (this.snackbar) setTimeout(this.closeSnackbar, 5000)
      }
    })
  }

  closeSnackbar(): void {
    this.$store.dispatch(Actions.showSnackbar, null)
  }
}
