







































import { Component, Vue } from 'vue-property-decorator'
import { Actions } from '@/store/global/actions'
import { Actions as AuthActions } from '@/store/auth/actions'
import { Getters } from '@/store/auth/getters'
import getInitials from '@/helpers/getInitials'
import UserModel from '@/modules/user/user.model'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { Getters as GlobalGetters } from '@/store/global/getters'

@Component({
  methods: {
    getInitials,
  },
})
export default class Header extends Vue {
  toggleNavigation = (): Promise<any> => this.$store.dispatch(Actions.toggleNavigation)
  logout = (): Promise<any> => this.$store.dispatch(AuthActions.logout)

  get user(): UserModel | null {
    return this.$store.getters[Getters.getUser]
  }

  get loading(): boolean {
    return this.$store.getters[GlobalGetters.getLoading]
  }

  goToProfile(): void {
    this.$router.push({ name: AdminRoutes.profile })
  }
}
