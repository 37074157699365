import UserModel from '@/modules/user/user.model'
import { Getters } from '@/store/auth/getters'
import store from '@/store'
import _ from 'lodash'

export default (permissions: string[], atLeastOne: boolean = false): boolean => {
  if (!permissions) return true

  const user: UserModel = store.getters[Getters.getUser]

  if (!user) return false

  if (user.permissions.includes('*')) return true

  if (atLeastOne) {
    return permissions.some((permission: string) => {
      if (_.keys(user.dynamicPermissions).includes(permission)) {
        return !!user.dynamicPermissions[permission]
      }

      return user.permissions.includes(permission)
    })
  }

  return permissions.every((permission: string) => {
    if (_.keys(user.dynamicPermissions).includes(permission)) {
      return !!user.dynamicPermissions[permission]
    }

    return user.permissions.includes(permission)
  })
}
