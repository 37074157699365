










import AbstractField from '@/classes/Form/Fields/AbstractField'
import ITemplateDocument from '@/modules/attachment/template-document.interface'

export default class FilesViewField extends AbstractField {
  get filesToDownload(): boolean {
    return this.field.meta.allDocuments.filter(
      (item) => this.field.meta.selectedDocuments.includes(item.value) && item.template_document !== null,
    )
  }
  downloadDocument(file: ITemplateDocument): void {
    const link = document.createElement('a')

    link.setAttribute('href', file.url)
    link.setAttribute('download', file.filename)
    link.click()
  }
}
