import { Prop, Vue, Component } from 'vue-property-decorator'
import { Actions } from '@/store/global/actions'

@Component
export default class DialogBase extends Vue {
  @Prop() meta!: any

  closeDialog(): void {
    this.$store.dispatch(Actions.closeDialog)
  }
}
