import store from '@/store'
import { Getters } from '@/store/auth/getters'
import tokenHelper from '@/helpers/tokenHelper'
import { Actions } from '@/store/auth/actions'
import { Route } from 'vue-router'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import getRouteMeta, { AdminRoutes } from '@/helpers/getRouteMeta'
import { FinancierAttribute } from '@/modules/financier/financier.model'

export default async (to: Route, from: Route, next: any): Promise<void> => {
  const token: string | null = tokenHelper.getToken()
  let isLogged: boolean = store.getters[Getters.isLogged]
  let user: UserModel = store.getters[Getters.getUser]

  if (!token && isLogged) {
    await store.dispatch(Actions.reset)

    const { loginRoute } = to.matched[0].meta

    return loginRoute ? next({ name: loginRoute }) : next('/')
  }

  if (token && !isLogged) {
    await store.dispatch(Actions.me)
    isLogged = store.getters[Getters.isLogged]
    user = store.getters[Getters.getUser]
  }

  if (to.name === AdminRoutes.dashboard) {
    if (user && user[UserAttribute.roleUuid] === 'REGULAR') {
      tokenHelper.clearToken()
      await store.dispatch(Actions.reset)

      return next({ name: AdminRoutes.login })
    }
  }

  if (checkLoggedIn(to) && !isLogged) {
    const { loginRoute } = to.matched[0].meta

    return loginRoute ? next({ name: loginRoute }) : next('/')
  }

  if (checkNotLoggedIn(to) && isLogged) {
    return to.meta?.redirect ? next({ name: to.meta.redirect }) : next('/')
  }

  if (checkNeedPermission(to)) {
    if (!hasPermission(to.meta?.permission, user)) {
      return to.meta?.redirect ? next({ name: to.meta.redirect }) : next('/')
    }
  }

  if (checkInitialRedirects(to)) {
    let redirectName: any = null

    to.meta?.initialRedirects.forEach((routeName: string) => {
      if (redirectName) return

      const routeMeta = getRouteMeta(routeName)

      if (!routeMeta || !routeMeta.permission) {
        redirectName = routeName

        return
      }

      if (hasPermission(routeMeta.permission, user)) {
        redirectName = routeName
      }
    })

    if (redirectName) {
      return next({ name: redirectName })
    }

    return next({ name: to.meta?.mainRedirect })
  }

  if (to.name === AdminRoutes.fundingRequestsIndex && !user[UserAttribute.isAdmin]) {
    user[UserAttribute.financier][FinancierAttribute.fundingRequestVisibility]
      ? next()
      : next({ name: AdminRoutes.businessFundingRequestsIndex })
  }

  if (to.name === AdminRoutes.businessFundingRequestsIndex && !user[UserAttribute.isAdmin]) {
    user[UserAttribute.financier][FinancierAttribute.businessRequestVisibility]
      ? next()
      : next({ name: AdminRoutes.fundingRequestsIndex })
  }

  return next()
}

const hasPermission = (permission: string, user: UserModel): boolean => {
  if (!user[UserAttribute.permissions]) return false

  if (user.permissions.includes('*')) return true

  return user.permissions.includes(permission)
}

const checkLoggedIn = (to: Route): boolean => has(to, 'shouldLoggedIn')

const checkNotLoggedIn = (to: Route): boolean => has(to, 'shouldNotLoggedIn')

const checkNeedPermission = (to: Route): boolean => has(to, 'permission')

// eslint-disable-next-line no-prototype-builtins
const checkInitialRedirects = (to: Route): boolean | undefined => to.meta?.hasOwnProperty('initialRedirects')

// eslint-disable-next-line no-prototype-builtins
const has = (to: Route, what: string): boolean => to.matched.some((route: any) => route.meta.hasOwnProperty(what))
