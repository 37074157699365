import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'

export default class DataTableHeader {
  type: DataTableHeaderTypes = DataTableHeaderTypes.text
  value!: string
  valueClass!: string
  defaultValue!: any
  classes!: string
  text!: string
  width!: string
  align!: string
  meta: any = {}
  sortable: boolean = true
  permissions!: string[]
  key!: string
  valueParser: (value: any) => any = (value: any) => value

  setType(type: DataTableHeaderTypes): DataTableHeader {
    this.type = type

    return this
  }

  setValue(value: string): DataTableHeader {
    this.value = value

    return this
  }

  setValueClass(valueClass: string): DataTableHeader {
    this.valueClass = valueClass

    return this
  }

  setDefaultValue(defaultValue: any): DataTableHeader {
    this.defaultValue = defaultValue

    return this
  }

  setText(text: string): DataTableHeader {
    this.text = text

    return this
  }

  setWidth(width: string): DataTableHeader {
    this.width = width

    return this
  }

  setAlign(align: string): DataTableHeader {
    this.align = align

    return this
  }

  setMeta(meta: any): DataTableHeader {
    this.meta = meta

    return this
  }

  setSortable(sortable: boolean): DataTableHeader {
    this.sortable = sortable

    return this
  }

  setValueParser(valueParser: (value: any) => any): DataTableHeader {
    this.valueParser = valueParser

    return this
  }

  setClasses(classes: string): DataTableHeader {
    this.classes = classes

    return this
  }

  setPermissions(permissions: string[]): DataTableHeader {
    this.permissions = permissions

    return this
  }

  setKey(value: string): DataTableHeader {
    this.key = value

    return this
  }
}
