






import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import NotificationTemplateModel from '@/modules/notification-template/notification-template.model'
import { Actions } from '@/store/global/actions'
import EditDialog from '@/components/Notifications/EditDialog.vue'
import { notificationTemplateTypeParser, notificationTypeParser } from '@/helpers/notifications'
import permissions from '@/helpers/permissions'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
})
export default class SettingsTabsNotifications extends Vue {
  table: DataTableBase = new DataTableBase()
    .setEndpoint('notification-templates')
    .setModel(NotificationTemplateModel)
    .setHasFilter(false)
    .setHeaders([
      new DataTableHeader()
        .setValue('notificationType')
        .setText('Pranešimo tipas')
        .setValueParser(notificationTypeParser)
        .setSortable(false),
      new DataTableHeader()
        .setValue('type')
        .setText('Tipas')
        .setValueParser(notificationTemplateTypeParser)
        .setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setPermissions([permissions.notificationTemplates.edit, permissions.notificationTemplates.show])
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.editItem),
    ])

  editItem(item: NotificationTemplateModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: EditDialog,
      maxWidth: 650,
      meta: {
        entry: item,
        onSuccess: this.table.setItem,
      },
    })
  }
}
