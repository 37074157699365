import IAttachmentResponse from '@/modules/attachment/attachment-response.interface'

export enum AttachmentAttribute {
  key = 'key',
  name = 'name',
  url = 'url',
}

export default class AttachmentModel {
  [AttachmentAttribute.key]!: string;
  [AttachmentAttribute.name]!: string;
  [AttachmentAttribute.url]!: string

  transform(data: IAttachmentResponse): AttachmentModel {
    this.key = data.title
    this.name = data.title
    this.url = data.title

    return this
  }

  setData(data: { key: string; name: string; url: string }): AttachmentModel {
    this.key = data.key
    this.name = data.name
    this.url = data.url

    return this
  }

  setCollection(data: { key: string; name: string; url: string }[]): any {
    const collection: AttachmentModel[] = []

    data.forEach((item: { key: string; name: string; url: string }) => {
      collection.push(new AttachmentModel().setData(item))
    })

    return collection
  }
}
