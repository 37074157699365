










import { Component, Prop } from 'vue-property-decorator'
import DialogBase from '@/classes/Dialog'

@Component
export default class AreYouSureDialog extends DialogBase {
  @Prop() meta: any
  loading: boolean = false

  async onYes(): Promise<void> {
    const { onYes } = this.meta
    this.loading = true
    onYes && (await onYes())
    this.loading = false

    if (!this.meta.dontCloseOnYes) {
      this.closeDialog()
    }
  }

  onNo(): void {
    const { onNo } = this.meta
    this.closeDialog()
    onNo && onNo()
  }
}
