











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import { HttpMethod } from '@/helpers/requests'
import http from '@/http'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { FundingRequestFinancierInfoAttribute } from '@/modules/funding-request-financier-info/funding-request-financier-info.model'

@Component({
  components: {
    Form,
  },
})
export default class AdditionalInfoProvideCommentDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setMethod(HttpMethod.POST)
    .setFields([
      new Field()
        .setType(FieldTypes.textArea)
        .setKey(FundingRequestFinancierInfoAttribute.comment)
        .setTitle('Administratoriaus komentaras'),
    ])
    .setInjectValues({
      uuid: this.meta.uuid,
    })
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any): void {
    const info = new FormData()

    info.append(`info[${this.meta.uuid}][comment]`, data.comment)
    info.append(`info[${this.meta.uuid}][uuid]`, this.meta.uuid)

    http.post(`additional-information/funding-request/${this.meta.funding_request.uuid}/provide`, info).then(() => {
      this.closeDialog()
      this.meta.onSuccess(data.data)
    })
  }
}
