export default {
  dashboard: {
    list: 'dashboard.index',
  },
  financiers: {
    list: 'financiers.index',
    all: 'financiers.all',
    show: 'financiers.show',
    create: 'financiers.store',
    edit: 'financiers.update',
    delete: 'financiers.destroy',
    activate: 'financiers.update',
    deactivate: 'financiers.update',
  },
  fundingRequestFields: {
    list: 'funding-request-fields.index',
    create: 'funding-request-fields.store',
    edit: 'funding-request-fields.update',
  },
  notificationTemplates: {
    list: 'notification-templates.index',
    create: 'notification-templates.store',
    edit: 'notification-templates.update',
    show: 'notification-templates.show',
  },
  settings: {
    list: 'setting.get-by-group',
  },
  users: {
    list: 'users.index',
    create: 'users.create',
    edit: 'users.update',
    show: 'users.show',
    delete: 'users.destroy',
  },
  colleagues: {
    list: 'colleagues.index',
    create: 'colleagues.create',
    edit: 'colleagues.update',
    show: 'colleagues.show',
    delete: 'colleagues.destroy',
  },
  roles: {
    list: 'roles.index',
    create: 'roles.store',
    edit: 'roles.update',
    show: 'roles.show',
    delete: 'roles.destroy',
  },
  recommendations: {
    list: 'recommendations.list',
  },
  departments: {
    list: 'departments.index',
    create: 'departments.store',
    edit: 'departments.update',
    show: 'departments.show',
    delete: 'departments.destroy',
  },
  offers: {
    list: 'offers.index',
    create: 'offers.store',
    edit: 'offers.update',
    show: 'offers.show',
    delete: 'offers.destroy',
    declineBindingOfferRequest: 'offers.decline-binding-offer-request',
  },
  fundingRequests: {
    list: 'funding-requests.index',
    create: 'funding-requests.store',
    edit: 'funding-requests.update',
    constrainedUpdate: 'funding-requests.constrained-update',
    show: 'funding-requests.show',
    delete: 'funding-requests.destroy',
    archive: 'funding-requests.archive',
    hasOffers: 'funding-requests.has-offers',
    offerAccepted: 'funding-requests.offer-accepted',
    complete: 'funding-requests.complete',
    overwrite: 'funding-requests.overwrite',
    undecline: 'funding-requests.undecline',
    unarchive: 'funding-requests.unarchive',
    repeatInfoNotification: 'funding-requests.repeatInfoNotifications',
    getAssignedFinanciers: 'funding-requests.get-assigned-financiers',
  },
  verifications: {
    download: 'verifications.download',
  },
  archivedFundingRequests: {
    list: 'archived-funding-requests.list',
  },
  businessOffers: {
    list: 'business-offers.index',
    create: 'business-offers.store',
    edit: 'business-offers.update',
    show: 'business-offers.show',
    delete: 'business-offers.destroy',
  },
  businessFundingRequests: {
    list: 'business-funding-requests.index',
    create: 'business-funding-requests.store',
    edit: 'business-funding-requests.update',
    constrainedUpdate: 'business-funding-requests.constrained-update',
    show: 'business-funding-requests.show',
    delete: 'business-funding-requests.destroy',
    archive: 'business-funding-requests.archive',
    hasOffers: 'business-funding-requests.has-offers',
    offerAccepted: 'business-funding-requests.offer-accepted',
    complete: 'business-funding-requests.complete',
    overwrite: 'business-funding-requests.overwrite',
    undecline: 'business-funding-requests.undecline',
    unarchive: 'business-funding-requests.unarchive',
    repeatInfoNotification: 'funding-requests.repeatInfoNotifications',
    getAssignedFinanciers: 'funding-requests.get-assigned-financiers',
  },
  additionalDocuments: {
    index: 'additional-documents.index',
    list: 'additional-documents.get-documents',
    create: 'additional-documents.request-documents',
    store: 'additional-documents.store',
    edit: 'additional-documents.update',
    show: 'additional-documents.show',
    delete: 'additional-documents.destroy',
  },
  activityLog: {
    list: 'activity-log.index',
  },
}
