import Model from '@/classes/Model'
import FinancierModel from '@/modules/financier/financier.model'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import IDeclinedFundingRequestResponse from '@/modules/declined-funding-requests/declined-funding-request-response.interface'

export enum DeclinedFundingRequestAttribute {
  decline_reason = 'decline_reason',

  financier = 'financier',
  funding_request = 'funding_request',
  created_at = 'created_at',
}

export default class DeclinedFundingRequestModel extends Model {
  [DeclinedFundingRequestAttribute.decline_reason]!: string;

  [DeclinedFundingRequestAttribute.financier]!: FinancierModel;
  [DeclinedFundingRequestAttribute.funding_request]!: FundingRequestModel;
  [DeclinedFundingRequestAttribute.created_at]!: string

  transform(data: IDeclinedFundingRequestResponse): DeclinedFundingRequestModel {
    super.transform(data)

    this.decline_reason = data.decline_reason
    this.created_at = data.created_at
    this.financier = data.financier && new FinancierModel().transform(data.financier)
    this.funding_request = data.funding_request && new FundingRequestModel().transform(data.funding_request)

    return this
  }
}
