













import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { HttpMethod } from '@/helpers/requests'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { OfferAttribute } from '@/modules/offer/offer.model'

@Component({
  components: {
    Form,
  },
})
export default class DeclineOfferSubmissionBusinessDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setEndpoint(this.meta.endpoint)
    .setMethod(HttpMethod.POST)
    .setFields([
      new SelectField().setKey('decline_reason_uuid').setTitle('Priežastis').loadItems({
        endpoint: 'decline-reasons/business',
        value: 'uuid',
        title: 'title',
      }),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('other_reason')
        .setTitle('Priežastis')
        .setVisibleIf((values: any) => values.decline_reason_uuid === 'OTHER'),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Sėkmingai atsisakėte pasiūlymo pateikimo!',
    })
    this.closeDialog()
    this.meta.onSuccess()
  }

  changeFinancier(): void {
    this.form.setEndpoint(`/financiers/${this.form.data.financier_uuid}/decline/${this.meta.itemUuid}`)
  }

  created(): void {
    if (!this.meta.financierUuid) {
      this.form.addField(
        new SelectField()
          .setKey(OfferAttribute.financier_uuid)
          .setTitle('Finansuotojas')
          .loadItems({
            endpoint: `funding-requests/${this.meta.fundingRequestUuid}/available-financiers`,
            value: 'uuid',
            title: 'name',
          })
          .setOnChange(this.changeFinancier),
      )

      this.$watch(
        'form.data.financier_uuid',
        () => {
          this.form.setSubmit({
            disabled: !this.form.data.financier_uuid,
          })
        },
        { deep: true },
      )
    }
  }
}
