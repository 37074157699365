







import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { Getters } from '@/store/auth/getters'

@Component({
  components: {
    DataTable,
    AdminLayout,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class ColleaguesList extends Vue {
  permissions = permissions
  user: UserModel = this.$store.getters[Getters.getUser]
  table = new DataTableBase()
    .setModel(UserModel)
    .setEndpoint(`users/by-financier/${this.user[UserAttribute.financier_uuid]}`)
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(UserAttribute.avatar)
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader().setKey(UserAttribute.fullName).setValue('full_name').setText('Vardas'),
      new DataTableHeader().setValue(UserAttribute.email).setText('El. paštas'),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setTitle('Peržiūrėti')
        .setAction(this.showItem)
        .setPermissions([permissions.users.show]),
    ])

  showItem(item: UserModel): void {
    this.$router.push({
      name: AdminRoutes.colleaguesShow,
      params: {
        uuid: item.uuid,
      },
    })
  }
}
