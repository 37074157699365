









import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import PageHeader from '@/components/PageHeader.vue'
import { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import permissions from '@/helpers/permissions'
import DeclinedFundingRequestModel, {
  DeclinedFundingRequestAttribute,
} from '@/modules/declined-funding-requests/declined-funding-request.model'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { Getters } from '@/store/auth/getters'
import http from '@/http'
import { AxiosResponse } from 'axios'
import { Actions, Actions as GlobalActions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'

@Component({
  components: {
    AdminLayout,
    PageHeader,
    DataTable,
  },
})
export default class RegularDeclinedFundingRequestList extends Vue {
  user: UserModel | null = this.$store.getters[Getters.getUser]
  table = new DataTableBase()
    .setModel(DeclinedFundingRequestModel)
    .setEndpoint('funding-requests/declined')
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${DeclinedFundingRequestAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Finansuotojas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
        .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.fundingRequestNumber}`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(true),
      new DataTableHeader()
        .setValue(
          `${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.${UserAttribute.fullName}`,
        )
        .setText('Vardas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.amount)
        .setText('Paskolos suma')
        .setValueParser((value) => `${value} €`)
        .setSortable(false),
      new DataTableHeader().setValue(FundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.funding_type}`)
        .setText('Tipas')
        .setValueParser((value) => value.name)
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.decline_reason}`)
        .setText('Atmetimo priežastis')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.confirmed_at}`)
        .setText('Pateikimo data')
        .setSortable(false),
      new DataTableHeader().setValue(`${DeclinedFundingRequestAttribute.created_at}`).setText('Atmetimo data'),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setTitle('Informacija')
        .setPermissions([permissions.fundingRequests.show])
        .setAction(this.goToInformation),
      new DataTableAction()
        .setIcon('info')
        .setTitle('Atstatyti paraišką')
        .setPermissions([permissions.fundingRequests.undecline])
        .setAction(this.undeclineFundingRequest),
      new DataTableAction()
        .setIcon('mdi-file-move')
        .setTitle('Archyvuoti')
        .setPermissions([permissions.fundingRequests.archive])
        .setAction(this.onArchive),
    ])

  created(): void {
    if (this.user && !this.user[UserAttribute.isAdmin]) {
      this.table.setIsSelectable().setSelectBy('uuid')
    }

    if (this.user && this.user[UserAttribute.isAdmin]) {
      this.table.setHeaders([
        new DataTableHeader()
          .setType(DataTableHeaderTypes.image)
          .setValue(`${DeclinedFundingRequestAttribute.financier}.${FinancierAttribute.logo}`)
          .setText('Finansuotojas')
          .setSortable(false)
          .setMeta({ width: '40px', height: '40px' }),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
          .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.fundingRequestNumber}`)
          .setValueParser((value) => `#${value}`)
          .setText('Nr.')
          .setSortable(true),
        new DataTableHeader()
          .setValue(
            `${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.${UserAttribute.fullName}`,
          )
          .setText('Vardas')
          .setSortable(false),
        new DataTableHeader()
          .setValue(
            `${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.${UserAttribute.email}`,
          )
          .setText('El. paštas')
          .setSortable(false),
        new DataTableHeader()
          .setValue(
            `${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.user}.${UserAttribute.phone}`,
          )
          .setText('Tel. nr.')
          .setValueParser((value) => `+370${value}`)
          .setSortable(false),
        new DataTableHeader()
          .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.amount}`)
          .setText('Paskolos suma')
          .setValueParser((value) => `${value} €`)
          .setSortable(false),
        new DataTableHeader()
          .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.periodData}`)
          .setText('Periodas')
          .setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.funding_type_name)
          .setKey(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.funding_type}`)
          .setText('Tipas')
          .setValueParser((value) => value.name),
        new DataTableHeader()
          .setValue(`${DeclinedFundingRequestAttribute.decline_reason}`)
          .setText('Atmetimo priežastis')
          .setSortable(true),
        new DataTableHeader()
          .setValue(`${DeclinedFundingRequestAttribute.funding_request}.${FundingRequestAttribute.confirmed_at}`)
          .setText('Pateikimo data')
          .setSortable(false),
        new DataTableHeader().setValue(`${DeclinedFundingRequestAttribute.created_at}`).setText('Atmetimo data'),
      ])
    }
  }

  goToInformation(item: DeclinedFundingRequestModel): void {
    this.$router.push({
      name: AdminRoutes.fundingRequestsShow,
      params: {
        uuid: item[DeclinedFundingRequestAttribute.funding_request].uuid,
      },
    })
  }

  onArchive(item: DeclinedFundingRequestModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite archyvuoti užklausą?',
        text: `Paspaudus taip mygtuką pasirinkta paraiška pateks į archyvuotų paraiškų sąrašą.`,
        onYes: () => this.archiveFundingRequest(item),
      },
    })
  }

  archiveFundingRequest(item: DeclinedFundingRequestModel): void {
    http
      .post(`funding-requests/${item[DeclinedFundingRequestAttribute.funding_request].uuid}/archive`)
      .then(async () => {
        // @ts-ignore
        this.$refs.declinedFundingRequestsTable.filter()
        await this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: `Paraiška sėkmingai suarchyvuota`,
        })
      })
  }

  undeclineFundingRequest(item: DeclinedFundingRequestModel): void {
    http
      .post(
        `financiers/${item[DeclinedFundingRequestAttribute.financier].uuid}/funding-requests/${
          item[DeclinedFundingRequestAttribute.funding_request].uuid
        }/undecline`,
      )
      .then((response: AxiosResponse) => {
        // @ts-ignore
        this.$refs.declinedFundingRequestsTable.filter()
        this.$store.dispatch(GlobalActions.showSnackbar, {
          type: 'success',
          message: response.data,
        })
      })
  }
}
