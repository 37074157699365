var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('PageHeader',{attrs:{"title":"Paraiškos finansuotojai"}},[(_vm.user.isAdmin && !_vm.fundingRequestModel.NEGATIVE_STATUSES.includes(_vm.getFundingRequest().status))?_c('VBtn',{on:{"click":function($event){return _vm.assignFinanciers()}}},[_c('v-badge',{staticClass:"d-flex align-center",attrs:{"color":"primary","value":_vm.selectedItems.length,"content":_vm.selectedItems.length}},[_vm._v(" Tvarkyti ")])],1):_vm._e()],1),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":[
      { text: 'Logotipas', value: 'logo' },
      { text: 'Pavadinimas', value: 'name' },
      { text: 'El. paštas', value: 'email' },
      { text: 'Fizinės finansavimo užklausos', value: 'funding_request_visibility' },
      { text: 'Verslo finansavimo užklausos', value: 'business_request_visibility' } ],"items":_vm.financiers,"item-key":"uuid","show-select":"","value":_vm.selectedItems,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
    var item = ref.item;
return [(item.logo)?_c('v-img',{attrs:{"src":item.logo.url,"aspect-ratio":"1","contain":"","width":40,"height":40}}):_c('div',{staticClass:"grey lighten-2",style:({ width: '40px', height: '40px' })})]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: _vm.adminRoutes.settingsFinanciersEdit, params: { uuid: item.uuid } },"target":"_blank"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.funding_request_visibility",fn:function(ref){
    var item = ref.item;
return [(item.funding_request_visibility)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("close")])]}},{key:"item.business_request_visibility",fn:function(ref){
    var item = ref.item;
return [(item.business_request_visibility)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("close")])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }