













import { Component, Prop, Vue } from 'vue-property-decorator'
import Snackbar from '@/components/Alerts/Snackbar.vue'

@Component({
  components: {
    Snackbar,
  },
})
export default class AuthLayout extends Vue {
  @Prop() headline!: string
}
