export enum NotificationTypes {
  email = 'mail',
  sms = 'sms',
}

export const notificationTypes = [
  {
    key: NotificationTypes.email,
    value: 'El. laiškas',
  },
  {
    key: NotificationTypes.sms,
    value: 'SMS',
  },
]

export enum NotificationTemplateTypes {
  abandonedForm = 'abandoned-form',
  emailConfirmation = 'email-confirmation',
  profileCreated = 'profile-created',
  dataIsMissing = 'data-is-missing',
  unverifiedIdentity = 'unverified-identity',
  offer = 'offer',
  offerChosen = 'offer-chosen',
  awardOfContract = 'award-of-contract',
  firstSmsNotFilledForm = 'first-sms-not-filled-form',
  newConfirmedFundingRequest = 'new-confirmed-funding-request',
  newConfirmedFundingRequestForUser = 'new-confirmed-funding-request-for-user',
  documentRequest = 'documents-request',
  documentProvided = 'document-provided',
}

export const notificationTemplateTypes = [
  {
    key: NotificationTemplateTypes.documentProvided,
    value: 'Klientas pateikė dokumentus',
  },
  {
    key: NotificationTemplateTypes.newConfirmedFundingRequestForUser,
    value: 'Vartotojo paraiška patvirtinta',
  },
  {
    key: NotificationTemplateTypes.offerChosen,
    value: 'Pasirinktas pasiūlymas',
  },
  {
    key: NotificationTemplateTypes.newConfirmedFundingRequest,
    value: 'Naują finansavimo užklausa',
  },
  {
    key: NotificationTemplateTypes.documentRequest,
    value: 'Vartotojui reikia pateikti dokumentus',
  },
  {
    key: NotificationTemplateTypes.abandonedForm,
    value: 'Palikta anketa',
  },
  {
    key: NotificationTemplateTypes.emailConfirmation,
    value: 'El. pašto patvirtinimas',
  },
  {
    key: NotificationTemplateTypes.profileCreated,
    value: 'Paskyros sukūrimas',
  },
  {
    key: NotificationTemplateTypes.dataIsMissing,
    value: 'Trūksta duomenų',
  },
  {
    key: NotificationTemplateTypes.unverifiedIdentity,
    value: 'Nepatvirtina tapatybė',
  },
  {
    key: NotificationTemplateTypes.offer,
    value: 'Pasiūlymas',
  },
  {
    key: NotificationTemplateTypes.awardOfContract,
    value: 'Sutarties sudarymas',
  },
  {
    key: NotificationTemplateTypes.firstSmsNotFilledForm,
    value: 'Pirminis SMS neužpildžius anketos',
  },
]
