





















import { Component, Prop, Vue } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import DataTableFilter from '@/classes/DataTable/DataTableFilter'
import FormBase from '@/classes/Form/FormBase'
import { FieldTypes } from '@/components/Form/FieldTypes'

@Component({
  components: {
    Form,
  },
})
export default class AdvanceFilters extends Vue {
  @Prop() filters!: DataTableFilter[]
  activeFilters: number = 0
  dialog: boolean = false

  form: FormBase = new FormBase()
    .setWithoutData(true)
    .setSubmit({
      text: 'Ieškoti',
    })
    .setOnSuccess(this.onSuccess)

  created(): void {
    this.parseFiltersToFields()
    this.$watch('$route', this.parseFiltersToFields)
  }

  clearSelectionForm(): void {
    this.filters[0].field.value = []
  }

  onSuccess(): void {
    // eslint-disable-next-line prefer-const
    let { query, name } = this.$router.currentRoute
    query = { ...query }

    this.filters.forEach((filter: DataTableFilter) => {
      let value = ''

      if (filter.field) {
        if (filter.field.value instanceof Array) {
          value = filter.field.value.join(',')
        } else {
          value = filter.field.value
        }
      }

      if (!filter) return

      if (!value && value === '') {
        delete query[filter.getQueryName()]

        return
      }

      query[filter.getQueryName()] = value
    })

    query.page = String(1)

    this.$router.push({ name: name as string, query }).catch(() => {})

    this.dialog = false
  }

  parseFiltersToFields(): void {
    this.activeFilters = 0
    this.form.setFields([])
    this.filters.forEach((filter: DataTableFilter) => {
      const { query } = this.$router.currentRoute

      const found = Object.keys(query).find((key: string) => key === filter.getQueryName())
      let value = ''

      if (found && query[found]) {
        this.activeFilters++
        value = query[found] as string

        if (filter.field.type === FieldTypes.multipleSelect) {
          filter.field.setValue(value.split(','))
        } else {
          filter.field.setValue(value)
        }
      }

      this.form.addField(filter.field)
    })
  }
}
