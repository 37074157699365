


























import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableFilter, { FilterOperators } from '@/classes/DataTable/DataTableFilter'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import FundingRequestModel, { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import { fundingRequestIndividualTypes, getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { OfferAttribute } from '@/modules/offer/offer.model'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import { Getters } from '@/store/auth/getters'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import IUserResponse from '@/modules/user/user-response.interface'
import FundingRequestData from '@/components/DataTable/FundingRequestData.vue'

@Component({
  components: {
    DataTable,
    AdminLayout,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class RegularSelectedOffersList extends Vue {
  permissions = permissions
  selectedItems: any[] = []
  user: UserModel = this.$store.getters[Getters.getUser]
  selectedItemsCount: number = 0
  table = new DataTableBase()
    .setEndpoint('funding-requests-offer-accepted')
    .setModel(FundingRequestModel)
    .setHeaders([
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.confirmed_at}`)
        .setText('Pateikimo data')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.user}.${UserAttribute.fullName}`)
        .setText('Vardas ir pavardė')
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(FundingRequestAttribute.funding_type)
        .setText('Paskolos tipas')
        .setValueParser((value) => value.name),
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${OfferAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Pasirinktas finansuotojas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.status)
        .setText('Statusas')
        .setValueParser(getFundingRequestStatusTitle)
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.userEmail}`)
        .setText('El. paštas')
        .setPermissions([permissions.fundingRequests.complete])
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.userPhone}`)
        .setValueParser((value) => `+370${value}`)
        .setText('Tel. nr.')
        .setPermissions([permissions.fundingRequests.complete])
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.assigned_users)
        .setText('Priskirti darbuotojai')
        .setValueParser((value) => this.parseUsers(value))
        .setSortable(false),
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setField(
          new SelectField()
            .isMultiple()
            .setKey(FundingRequestAttribute.filterTypeKey)
            .setTitle('Tipas')
            .setItems(fundingRequestIndividualTypes),
        ),
      new DataTableFilter()
        .setOperator(FilterOperators.equals)
        .setRelation('selectedOffer')
        .setField(
          new SelectField()
            .setTitle('Papildomai')
            .setKey('financier_uuid')
            .setItems([new SelectItem().setValue(this.user.financier_uuid).setTitle('Tik mūsų klientai')]),
        ),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setPermissions([permissions.fundingRequests.show])
        .setTitle('Informacija')
        .setAction(this.goToInformation),
    ])
    .setIsExpandable(true)
    .setExpansionComponent(FundingRequestData)
    .setExpansionDataCallback((item) => this.fetchExpandedData(item))

  created(): void {
    if (this.user && !this.user[UserAttribute.isAdmin]) {
      this.table.setIsSelectable().setSelectBy('uuid')
    }
  }

  goToInformation(item: FundingRequestModel): void {
    this.$router.push({
      name: AdminRoutes.selectedOffersShow,
      params: {
        uuid: item.uuid,
      },
    })
  }

  displayColumnByPermissions(value: any): string {
    if (this.user && this.user[UserAttribute.isAdmin]) {
      return value
    }

    return '*******'
  }

  handleSelectedItems(items: any[]): void {
    this.selectedItemsCount = items.length
    this.selectedItems = items.map((item) => item.uuid)
  }

  onAssignItems(): void {
    if (this.user) {
      this.$store.dispatch(Actions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: 'Ar tikrai norite prisiskirti šias paraiškas?',
          text: `Paraiškos jau yra priskirtos darbuotojui ${this.user.fullName}`,
          onYes: () => this.assignItems(),
        },
      })
    }
  }

  assignItems(): void {
    if (this.user) {
      this.table.loading = true
      http.post('funding-requests/assign', this.selectedItems).then(() => {
        // @ts-ignore
        this.$refs.FundingRequestDataTable.filter() // refresh table data
      })
    }
  }

  unselectAll(): void {
    this.selectedItems = []
    this.selectedItemsCount = 0
    // @ts-ignore
    this.$refs.colleaguesDataTable.clearSelectedItems()
  }

  parseUsers(items: IUserResponse[]): string {
    let parsedItems = ''
    items.forEach((item: IUserResponse, index: number) => {
      if (items.length === 1) {
        parsedItems += item.full_name

        return
      }

      if (index > 0) {
        parsedItems += item.full_name
      } else {
        parsedItems = `${parsedItems + item.full_name}, `
      }
    })

    return parsedItems
  }

  fetchExpandedData(item: FundingRequestModel): Promise<void> {
    return http.get(`/funding-requests/${item.uuid}/get-financiers-data`)
  }
}
