import Field from '@/classes/Form/Field'

export enum FilterOperators {
  equals = 'equals',
  like = 'like',
  in = 'in',
}

export default class DataTableFilter {
  field!: Field
  relation!: string
  title!: string
  operator: FilterOperators = FilterOperators.equals

  setField(field: Field): this {
    this.field = field

    return this
  }

  setRelation(relation: string): DataTableFilter {
    this.relation = relation

    return this
  }

  setTitle(title: string): DataTableFilter {
    this.title = title

    return this
  }

  setOperator(operator: FilterOperators): DataTableFilter {
    this.operator = operator

    return this
  }

  getQueryName(): string {
    let queryName = `filter-${this.operator}`

    if (this.relation) {
      queryName += `-${this.relation}`
    }
    queryName += `-${this.field.key}`

    return queryName
  }
}
