











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import Field, { FieldSizes } from '@/classes/Form/Field'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import FundingRequestFieldModel, {
  FundingRequestFieldAttribute,
  fundingRequestFieldGroups,
  fundingRequestFieldTypes,
} from '@/modules/funding-request-field/funding-request-field.model'
import IOption from '@/interfaces/option.interface'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { Actions } from '@/store/global/actions'
import ArrayField from '@/classes/Form/Fields/ArrayField'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'

@Component({
  components: {
    Form,
  },
})
export default class CreateDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setEndpoint('funding-request-fields')
    .setModel(FundingRequestFieldModel)
    .setFields([
      new Field().setKey(FundingRequestFieldAttribute.uuid).setTitle('Raktas').setDense(true),
      new SelectField()
        .setKey(FundingRequestFieldAttribute.type)
        .setTitle('Tipas')
        .setDense(true)
        .setItems(
          fundingRequestFieldTypes.map((item: IOption) => new SelectItem().setValue(item.key).setTitle(item.title)),
        ),
      new SelectField()
        .setKey(FundingRequestFieldAttribute.group)
        .setTitle('Grupė')
        .setDense(true)
        .setItems(
          fundingRequestFieldGroups.map((item: IOption) => new SelectItem().setValue(item.key).setTitle(item.title)),
        ),
      new Field().setKey(FundingRequestFieldAttribute.label).setTitle('Pavadinimas').setDense(true),
      new ArrayField()
        .setKey(FundingRequestFieldAttribute.options)
        .setTitle('Galimos reikšmės')
        .setDense(true)
        .setVisibleIf(CreateDialog.optionsVisible)
        .setChildren([
          new Field().setKey('value').setTitle('Reikšmė'),
          new Field().setKey('label').setTitle('Pavadinimas'),
        ]),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey(FundingRequestFieldAttribute.required)
        .setDense(true)
        .setTitle('Privalomas')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey(FundingRequestFieldAttribute.enabled)
        .setDense(true)
        .setTitle('Matomas')
        .setSize(FieldSizes.half),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any, entry: FundingRequestFieldModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Vartotojo duomenų laukas buvo sėkmingai sukurtas',
    })
    this.meta.onSuccess(entry)
    this.closeDialog()
  }

  private static optionsVisible(values: any): boolean {
    return values.type === FieldTypes.select
  }
}
