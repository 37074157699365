











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import Field, { FieldSizes } from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import RecommendationFieldModel, {
  RecommendationFieldAttribute,
} from '@/modules/recommendation/recommendation-field.model'

@Component({
  components: {
    Form,
  },
})
export default class CreateDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setEndpoint('referrals')
    .setModel(RecommendationFieldModel)
    .setFields([
      new Field().setKey(RecommendationFieldAttribute.title).setTitle('Pavadinimas').setDense(true),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey(RecommendationFieldAttribute.is_business)
        .setDense(true)
        .setTitle('Verslui')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey(RecommendationFieldAttribute.is_individual)
        .setDense(true)
        .setTitle('Individualiems')
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey(RecommendationFieldAttribute.is_active)
        .setDense(true)
        .setTitle('Aktyvus'),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any, entry: RecommendationFieldModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Rekomendacja buvo sėkmingai sukurta',
    })
    this.meta.onSuccess(entry)
    this.closeDialog()
  }
}
