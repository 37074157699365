














import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'
import TextAreaFieldTypes from '@/classes/Form/Fields/TextAreaFieldTypes'

@Component
export default class TextAreaField extends AbstractField {
  @Prop() field!: TextAreaFieldTypes
}
