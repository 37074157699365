


































































import PageHeader from '@/components/PageHeader.vue'
import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import http from '@/http'
import { AxiosResponse } from 'axios'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import DataTableBase from '@/classes/DataTable/DataTable'
import FundingRequestModel, { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTable from '@/components/DataTable/DataTable.vue'
import { getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'

@Component({
  components: {
    AdminLayout,
    PageHeader,
    DataTable,
  },
})
export default class ColleaguesShow extends Vue {
  selectedItems: any[] = []
  selectedItemsCount: number = 0
  user: UserModel | null = null
  table = new DataTableBase()
    .setModel(FundingRequestModel)
    .setPaginationNotVisible(true)
    .setHasFilter(false)
    .setHeaders([
      new DataTableHeader()
        .setKey(FundingRequestAttribute.fundingRequestNumber)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.confirmed_at}`)
        .setText('Pateikimo data')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.user}.${UserAttribute.fullName}`)
        .setText('Vardas')
        .setSortable(false),
      new DataTableHeader().setValue(`${FundingRequestAttribute.data}.email`).setText('El. paštas').setSortable(false),
      new DataTableHeader().setValue(`${FundingRequestAttribute.data}.phone`).setText('Tel. nr.').setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.status)
        .setText('Statusas')
        .setValueParser(getFundingRequestStatusTitle),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(FundingRequestAttribute.funding_type)
        .setText('Tipas')
        .setValueParser((value) => value.name),
    ])
    .setIsSelectable()
    .setSelectBy('uuid')

  created(): void {
    this.loadUser()
  }

  loadUser(): void {
    http.get(`/users/${this.$router.currentRoute.params.uuid}`).then((response: AxiosResponse) => {
      this.user = new UserModel().transform(response.data.data)
      this.table.setEndpoint(`funding-requests/user/${this.user.uuid}`)
    })
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.colleaguesIndex })
  }

  handleSelectedItems(items: any[]): void {
    this.selectedItemsCount = items.length
    this.selectedItems = items.map((item) => item.uuid)
  }

  onAssignItems(): void {
    if (this.user) {
      this.$store.dispatch(Actions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: 'Ar tikrai norite prisiskirti šias paraiškas?',
          text: `Paraiškos jau yra priskirtos darbuotojui ${this.user.fullName}`,
          onYes: () => this.assignItems(),
        },
      })
    }
  }

  assignItems(): void {
    if (this.user) {
      this.table.loading = true
      http.post('funding-requests/assign', this.selectedItems).then(async () => {
        // @ts-ignore
        await this.$refs.colleaguesDataTable.filter()
        await this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: `Paraiškos sėkmingai priskirtos! Priskirtų paraiškų kiekis: ${this.selectedItemsCount}`,
        })
        await this.unselectAll()
      })
    }
  }

  unselectAll(): void {
    this.selectedItems = []
    this.selectedItemsCount = 0
    // @ts-ignore
    this.$refs.colleaguesDataTable.clearSelectedItems()
  }
}
