





















import { Component } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'

@Component
export default class FileField extends AbstractField {
  url: string | null = null

  get multiple(): boolean {
    return !!this.field.meta.multiple
  }

  get disablePreview(): boolean {
    return this.field.disablePreview
  }

  mounted(): void {
    const value = this.form.data[this.fullKey]

    if (value) this.url = value.url
  }

  handleChange(value: File[] | File): void {
    this.form.data[this.fullKey] = null
    this.url = null

    if (value instanceof File) {
      this.manageSingleFile(value)

      return
    }

    this.manageMultipleFiles(value)
  }

  private manageSingleFile(value: File): void {
    if (value) {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        this.url = e.target.result
      }

      reader.readAsDataURL(value)
    }
    this.form.data[this.fullKey] = value
    this.removeError()
  }

  private manageMultipleFiles(value: File[]): void {
    if (!this.multiple) {
      const singleValue = value.pop()

      if (singleValue) {
        this.manageSingleFile(singleValue)
      }

      return
    }

    this.form.data[this.fullKey] = value
    this.removeError()
  }
}
