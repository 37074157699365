import http from '@/http'
import { Mutations } from '@/store/auth/mutations'
import tokenHelper from '@/helpers/tokenHelper'
import { ActionContext } from 'vuex'
import { IState } from '@/store/auth/interfaces'
import router from '@/router'
import IResponse from '@/modules/response.interface'
import IUserResponse from '@/modules/user/user-response.interface'
import UserModel from '@/modules/user/user.model'

export enum Actions {
  me = 'AUTH/ME',
  logout = 'AUTH/LOGOUT',
  reset = 'AUTH/RESET',
}

export default {
  [Actions.me]: ({ commit }: any) =>
    http.post('/me').then((response: IResponse<IUserResponse>) => {
      commit(Mutations.setUser, new UserModel().transform(response.data.data))

      return response.data
    }),
  [Actions.logout]: ({ commit }: ActionContext<IState, any>) =>
    http.post('/logout').then((response: IResponse<any>) => {
      tokenHelper.clearToken()
      commit(Mutations.reset)
      router.go(0)

      return response.data
    }),
  [Actions.reset]: ({ commit }: ActionContext<IState, any>) => {
    tokenHelper.clearToken()
    commit(Mutations.reset)
    router.go(0)
  },
}
