import { FieldTypes } from '@/components/Form/FieldTypes'
import { IFormError } from '@/interfaces/form'

export enum FieldSizes {
  full = 'full',
  half = 'half',
  quarter = 'quarter',
}

export default class Field {
  type: FieldTypes = FieldTypes.text
  size: FieldSizes = FieldSizes.full
  loading: boolean = false
  key: string = ''
  entryKey: string | null = null
  title!: string | null
  meta: any = {}
  value: any = ''
  dense: boolean = false
  disabled: boolean = false
  hideDetails: boolean = false
  visibleIf: (values: any) => boolean = () => true
  onChange!: () => void
  onEnter!: () => void
  append!: string
  prefill!: IPrefillObject
  autocomplete: string = ''
  callback!: (value: any) => void
  errors: IFormError = {}
  tooltip: string | null = null
  disablePreview: boolean = false

  setType(type: FieldTypes): this {
    if (type === FieldTypes.file) {
      this.value = null
    }
    this.type = type

    return this
  }

  setKey(key: string): this {
    this.key = key

    return this
  }

  setEntryKey(entryKey: string): this {
    this.entryKey = entryKey

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setValue(value: any): this {
    this.value = value

    return this
  }

  setDense(dense: boolean): this {
    this.dense = dense

    return this
  }

  setHideDetails(hideDetails: boolean): this {
    this.hideDetails = hideDetails

    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading

    return this
  }

  setOnEnter(onEnter: () => void): this {
    this.onEnter = onEnter

    return this
  }

  setSize(size: FieldSizes): this {
    this.size = size

    return this
  }

  setDisabled(disabled: boolean): this {
    this.disabled = disabled

    return this
  }

  setMeta(meta: any): this {
    this.meta = meta

    return this
  }

  setVisibleIf(visibleIf: (values: any) => boolean): this {
    this.visibleIf = visibleIf

    return this
  }

  setAppend(append: string): this {
    this.append = append

    return this
  }

  setOnChange(onChange: () => void): this {
    this.onChange = onChange

    return this
  }

  setAutocomplete(autocomplete: string): this {
    this.autocomplete = autocomplete

    return this
  }

  setCallback(callback: (value: any) => void): this {
    this.callback = callback

    return this
  }

  setError(errors: IFormError): this {
    this.errors = errors

    return this
  }

  setTooltip(tooltip: string): this {
    this.tooltip = tooltip

    return this
  }

  setDisablePreview(disable: boolean): this {
    this.disablePreview = disable

    return this
  }
}

export interface IPrefillObject {
  object: string
  title: string
  value: string
}
