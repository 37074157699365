import ITemplateDocument from '@/modules/attachment/template-document.interface'

export default class SelectItem {
  value!: string
  title!: string
  template_document?: ITemplateDocument | string

  setValue(value: any): SelectItem {
    this.value = value

    return this
  }

  setTemplateDocument(template_document: any): SelectItem {
    this.template_document = template_document

    return this
  }

  setTitle(title: string): SelectItem {
    this.title = title

    return this
  }
}
