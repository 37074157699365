import Model from '@/classes/Model'
import IArchivedFundingRequestResponse from '@/modules/archived-funding-requests/archived-funding-request-response.interface'
import { IDocument } from '@/interfaces/attachments'
import { IArchivedUser } from '@/modules/user/user-response.interface'

export enum ArchivedFundingRequestAttribute {
  funding_request_number = 'funding_request_number',
  name = 'name',
  amount = 'amount',
  type = 'type',
  period = 'period',
  periodData = 'periodData',
  periodMeasure = 'period_measure',
  date = 'date',
  funding_type = 'funding_type',
  funding_type_name = 'funding_type_name',
  uuid = 'uuid',
  signed_document = 'signed_document',
  user = 'user',
}

export default class ArchivedFundingRequestModel extends Model {
  [ArchivedFundingRequestAttribute.amount]!: string;
  [ArchivedFundingRequestAttribute.date]!: string;
  [ArchivedFundingRequestAttribute.funding_request_number]!: number;
  [ArchivedFundingRequestAttribute.funding_type]!: string;
  [ArchivedFundingRequestAttribute.name]!: string;
  [ArchivedFundingRequestAttribute.period]!: number;
  [ArchivedFundingRequestAttribute.periodMeasure]!: string;
  [ArchivedFundingRequestAttribute.periodData]!: string;
  [ArchivedFundingRequestAttribute.type]!: string;
  [ArchivedFundingRequestAttribute.uuid]!: string;
  [ArchivedFundingRequestAttribute.user]!: IArchivedUser;
  [ArchivedFundingRequestAttribute.signed_document]!: IDocument

  transform(data: IArchivedFundingRequestResponse): ArchivedFundingRequestModel {
    super.transform(data)

    this.amount = data.amount
    this.date = data.date
    this.funding_request_number = data.funding_request_number
    this.funding_type = data.funding_type
    this.name = data.name
    this.period = data.period
    this.period_measure = data.period_measure
    this.periodData = this.period ? `${this.period} ${this.period_measure}` : '-'
    this.type = data.type
    this.uuid = data.uuid
    this.user = data.user
    this.signed_document = data.signed_document

    return this
  }
}
