













import { Component } from 'vue-property-decorator'
import AbstractHeader from './AbstractHeader'

@Component
export default class ImageHeader extends AbstractHeader {}
