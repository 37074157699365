

























import { Component } from 'vue-property-decorator'
import DialogBase from '@/classes/Dialog'
import FormBase from '@/classes/Form/FormBase'
import NotificationTemplateModel, {
  NotificationTemplateAttribute,
} from '@/modules/notification-template/notification-template.model'
import { HttpMethod } from '@/helpers/requests'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import { notificationTemplateTypeParser, notificationTypeParser } from '@/helpers/notifications'
import { Actions } from '@/store/global/actions'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class EditDialog extends DialogBase {
  notificationType: string | null = null
  type: string | null = null
  form: FormBase = new FormBase()
    .setUuid(this.meta.entry.uuid)
    .setEndpoint('notification-templates')
    .setModel(NotificationTemplateModel)
    .setEntry(this.meta.entry)
    .setMethod(HttpMethod.PUT)
    .setOnSuccess(this.onSuccess)

  created(): void {
    this.$watch(
      'form',
      () => {
        const { entry } = this.form

        if (!entry) return

        const { notificationType, type } = entry
        this.notificationType = notificationTypeParser(notificationType)
        this.type = notificationTemplateTypeParser(type)
      },
      { deep: true },
    )

    if (this.meta.entry.notificationType === 'sms') {
      this.form.setFields([
        new Field().setType(FieldTypes.textArea).setKey(NotificationTemplateAttribute.content).setTitle('Pranešimas'),
      ])
    } else {
      this.form.setFields([
        new Field().setType(FieldTypes.html).setKey(NotificationTemplateAttribute.content).setTitle('Pranešimas'),
      ])
    }
  }

  onSuccess(data: any, entry: NotificationTemplateModel): void {
    this.closeDialog()
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Pranešimo duomenys buvo sėkmingai paredaguoti',
    })
    this.meta.onSuccess(entry)
  }
}
