










import { Component, Vue } from 'vue-property-decorator'
import Field, { FieldSizes } from '@/classes/Form/Field'
import Form from '@/components/Form/Form.vue'
import PageHeader from '@/components/PageHeader.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { FieldTypes } from '@/components/Form/FieldTypes'

@Component({
  components: {
    PageHeader,
    Form,
  },
})
export default class CustomersCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('users')
    .setModel(UserModel)
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey(UserAttribute.avatar)
        .setTitle('Nuotrauka')
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field().setEntryKey(UserAttribute.firstName).setKey('first_name').setTitle('Vardas').setSize(FieldSizes.half),
      new Field().setEntryKey(UserAttribute.lastName).setKey('last_name').setTitle('Pavardė').setSize(FieldSizes.half),
      new Field().setKey('email').setTitle('El. paštas').setSize(FieldSizes.half),
      new Field().setKey('phone').setTitle('Tel. numeris').setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey(UserAttribute.roleUuid)
        .setKey('role_uuid')
        .setTitle('Rolė')
        .loadItems({ endpoint: '/roles?filter-notIn-slug=FINANCIER,ADMIN,WORKER', value: 'slug', title: 'name' })
        .setSize(FieldSizes.half),
      new SelectField()
        .setEntryKey(UserAttribute.financier_uuid)
        .setKey('financier_uuid')
        .setTitle('Kredito davėjas')
        .loadItems({ endpoint: '/financiers', value: 'uuid', title: 'name' })
        .setVisibleIf((values: any) => values.role_uuid === 'FINANCIER')
        .setSize(FieldSizes.half),
      new Field().setType(FieldTypes.password).setKey('password').setTitle('Slaptažodis').setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password_confirmation')
        .setTitle('Pakartokite slaptažodį')
        .setSize(FieldSizes.half),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Vartotojas buvo sukurtas!',
    })
    this.goToList()
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.customersIndex })
  }
}
