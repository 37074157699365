











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import { HttpMethod } from '@/helpers/requests'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { OfferAttribute } from '@/modules/offer/offer.model'
import { FundingRequestFinancierInfoAttribute } from '@/modules/funding-request-financier-info/funding-request-financier-info.model'

@Component({
  components: {
    Form,
  },
})
export default class AdditionalQuestionsCreateDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setEndpoint(`additional-documents/requestDocuments/${this.meta.fundingRequestUuid}`)
    .setMethod(HttpMethod.POST)
    .setEntry({
      financier_uuid: this.meta.user.financier_uuid,
    })
    .setInjectValues({
      funding_request_uuid: this.meta.fundingRequestUuid,
    })
    .setFields([
      new SelectField()
        .setKey(OfferAttribute.financier_uuid)
        .setValue(this.meta.user.financier_uuid)
        .setTitle('Finansuotojas')
        .loadItems({
          endpoint: `funding-requests/${this.meta.fundingRequestUuid}/available-financiers`,
          value: 'uuid',
          title: 'name',
        }),
      new Field()
        .setType(FieldTypes.text)
        .setKey(FundingRequestFinancierInfoAttribute.question)
        .setTitle('Klausimas')
        .setVisibleIf((values: any) => !!values.financier_uuid),
      new Field()
        .setType(FieldTypes.text)
        .setKey(FundingRequestFinancierInfoAttribute.remarks)
        .setTitle('Pastabos')
        .setVisibleIf((values: any) => !!values.financier_uuid),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Klausimas sėkmingai išsaugotas!',
    })
    this.closeDialog()
    this.meta.onSuccess(data.data)
  }
}
