


















import { Component, Inject, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import http from '@/http'
import _get from '@/helpers/_get'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import OfferModel, { OfferAttribute } from '@/modules/offer/offer.model'
import FundingRequestFieldModel from '@/modules/funding-request-field/funding-request-field.model'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { Actions } from '@/store/global/actions'
import { IDialog } from '@/store/global/helpers'
import EditDialog from '@/components/Offers/EditDialog.vue'
import CreateDialog from '@/components/Offers/CreateDialog.vue'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import DeclineOfferSubmissionBusinessDialog from '@/views/Business/FundingRequests/DeclineOfferSubmissionDialog.vue'

@Component({
  components: {
    PageHeader,
    DataTable,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessFundingRequestsTabsOffers extends Vue {
  @Inject('fundingRequest') getFundingRequest!: () => FundingRequestModel
  user: UserModel = this.$store.getters[Getters.getUser]
  permissions = permissions
  fundingRequestFields: FundingRequestFieldModel[] | null = null
  table: DataTableBase = new DataTableBase()
    .setModel(OfferModel)
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${OfferAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Finansuotojas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader()
        .setValue(`${OfferAttribute.financier}.${FinancierAttribute.name}`)
        .setText('Pavadinimas')
        .setSortable(false),
      new DataTableHeader().setValue(`${OfferAttribute.periodData}`).setText('Periodas'),
      new DataTableHeader()
        .setKey(`${OfferAttribute.annualInterestRate}`)
        .setValue('annual_interest_rate')
        .setText('Palūkanos')
        .setValueParser((value: any) => `${value}%`),
      new DataTableHeader()
        .setValue(`${OfferAttribute.amount}`)
        .setText('Finansuojama suma')
        .setValueParser((value: any) => `${value} €`),
      new DataTableHeader()
        .setKey(`${OfferAttribute.monthlyAmount}`)
        .setValue('monthly_amount')
        .setText('Finansuojama mėn. suma')
        .setValueParser((value: any) => `${value} €/mėn`),
      new DataTableHeader()
        .setKey(`${OfferAttribute.finalAmount}`)
        .setValue('final_amount')
        .setText('Galutinė suma')
        .setValueParser((value: any) => `${value} €`),
      new DataTableHeader()
        .setKey(`${OfferAttribute.binding_offer_requested}`)
        .setValue('binding_offer_requested')
        .setText('Prašo įpareigonačio pasiūlymo')
        .setValueParser((value: any) => (value === true ? 'Taip' : 'Ne')),
      new DataTableHeader()
        .setKey(`${OfferAttribute.binding_offer_request_declined}`)
        .setValue('binding_offer_request_declined')
        .setText('Atsisakyta teikti įpareigontį pasiūlymą')
        .setValueParser((value: any) => (value === true ? 'Taip' : 'Ne')),
    ])
    .setActions([
      new DataTableAction()
        .setPermissions([permissions.offers.edit])
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.openEdit),
      new DataTableAction()
        .setPermissions([permissions.offers.edit])
        .setIcon('notification_important')
        .setTitle('Pakartoti pranešimus')
        .setAction(this.onNotify),
      new DataTableAction()
        .setPermissions([permissions.offers.edit])
        .setIcon('cancel')
        .setTitle('Atsisakyti pateikti įpareigojantį pasiūlymą')
        .setAction(this.declineBindingOfferRequest),
      new DataTableAction()
        .setPermissions([permissions.offers.delete])
        .setIcon('cancel')
        .setTitle('Ištrinti')
        .setAction(this.deleteItem),
    ])

  created(): void {
    this.createOffersTable()
  }

  createOffersTable(): void {
    this.table.setEndpoint(`funding-requests/${this.$router.currentRoute.params.uuid}/offers`)
  }

  componentKey: number = 0

  forceRerender(): void {
    this.componentKey += 1
  }

  openEdit(item: OfferModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: EditDialog,
      maxWidth: 650,
      meta: {
        entry: item,
        fundingRequestUuid: this.getFundingRequest() && this.getFundingRequest().uuid,
        onSuccess: (entry: OfferModel) => {
          this.table.setItem(entry)
          this.forceRerender()
        },
      },
    } as IDialog)
  }

  declineBindingOfferRequest(item: OfferModel): void {
    http.put(`offers/${item.uuid}/decline-binding-offer-request`).then(() => {
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Sėkmingai atsisakėte pateikti įpareigojantiį pasiūlymą!',
      })
    })
  }

  get offerIsDeclined(): boolean {
    return this.getFundingRequest() ? !this.getFundingRequest().dynamicPermissions.decline : false
  }

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Sėkmingai išsaugota!',
    })
  }

  onNotify(item: OfferModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite pakartotinai išsiųsti pranešimus?',
        onYes: () => this.notify(item),
      },
    })
  }

  notify(item: OfferModel): void {
    http.post(`offers/${item.uuid}/repeat-notification`).then(() => {
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Klientas sėkmingai informuotas!',
      })
    })
  }

  deleteItem(item: OfferModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite pasiūlymą? Šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: OfferModel): Promise<DataTableBase> {
    return http.delete(`offers/${item.uuid}`).then(() => this.table.removeItem(item))
  }

  declineFundingRequest(item: FundingRequestModel): void {
    if (!item) return

    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: DeclineOfferSubmissionBusinessDialog,
      meta: {
        financierUuid: this.user[UserAttribute.financier_uuid],
        itemUuid: item.uuid,
        fundingRequestUuid: this.getFundingRequest() && this.getFundingRequest().uuid,
        endpoint: `/financiers/${this.user[UserAttribute.financier_uuid]}/decline/${item.uuid}`,
        onSuccess: () => {
          this.$router.go(0)
        },
      },
    })
  }

  openCreate(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: CreateDialog,
      maxWidth: 650,
      meta: {
        user: this.user,
        fundingRequest: this.getFundingRequest(),
        fundingRequestUuid: this.getFundingRequest() && this.getFundingRequest().uuid,
        onSuccess: (entry: OfferModel) => {
          this.table.addItem(entry)
          this.forceRerender()
        },
      },
    } as IDialog)
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.fundingRequestsIndex })
  }
}
