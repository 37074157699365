import Model from '@/classes/Model'
import VerificationResponseInterface from '@/modules/verification/verification-response.interface'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import { IDocument } from '@/interfaces/attachments'

export enum VerificationAttribute {
  uuid = 'uuid',
  unique_code = 'unique_code',
  funding_request_uuid = 'funding_request_uuid',
  email = 'email',
  updated_at = 'updated_at',
  type = 'type',
  name = 'name',
  surname = 'surname',
  personcode = 'personcode',
  personcodestatus = 'personcodestatus',
  is_verified = 'is_verified',
  account = 'account',

  fundingRequest = 'funding_request',
  documents = 'documents',
}

export default class VerificationModel extends Model {
  [VerificationAttribute.unique_code]!: string;
  [VerificationAttribute.funding_request_uuid]!: string;
  [VerificationAttribute.email]!: string;
  [VerificationAttribute.type]!: string;
  [VerificationAttribute.name]!: string;
  [VerificationAttribute.surname]!: string;
  [VerificationAttribute.is_verified]!: number;
  [VerificationAttribute.personcodestatus]!: number;
  [VerificationAttribute.personcode]!: number;
  [VerificationAttribute.updated_at]!: string;
  [VerificationAttribute.account]!: string;
  [VerificationAttribute.fundingRequest]!: FundingRequestModel;
  [VerificationAttribute.documents]!: IDocument

  transform(data: VerificationResponseInterface): VerificationModel {
    super.transform(data)

    this.unique_code = data.unique_code
    this.funding_request_uuid = data.funding_request_uuid
    this.email = data.email
    this.name = data.name
    this.surname = data.surname
    this.email = data.email
    this.is_verified = data.is_verified
    this.type = data.type
    this.personcode = data.personcode
    this.personcodestatus = data.personcodestatus
    this.updated_at = data.updated_at
    this.account = data.account

    this.funding_request = data.funding_request && new FundingRequestModel().transform(data.funding_request)
    this.documents = data.documents

    return this
  }
}
