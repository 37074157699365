import getRouteMeta, { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import Admin from '@/Admin.vue'
import Dashboard from '@/views/Dashboard.vue'
import Profile from '@/views/Profile.vue'
import Index from '@/views/Regular/FundingRequests/Index.vue'
import List from '@/views/Regular/FundingRequests/List.vue'
import Show from '@/views/Regular/FundingRequests/Show.vue'
import Offers from '@/views/Regular/FundingRequests/Tabs/Offers.vue'
import Documents from '@/views/Regular/FundingRequests/Tabs/Documents.vue'
import Verifications from '@/views/Regular/FundingRequests/Tabs/Verifications.vue'
import AssignedUsers from '@/views/Regular/FundingRequests/Tabs/AssignedUsers.vue'
import ActivityLog from '@/views/Regular/FundingRequests/Tabs/ActivityLog.vue'
import AssignedFinanciers from '@/views/Regular/FundingRequests/Tabs/AssignedFinanciers.vue'
import Edit from '@/views/Regular/FundingRequests/Edit.vue'
import RegularDeclinedFundingRequestIndex from '@/views/Regular/DeclinedFundingRequests/Index.vue'
import RegularDeclinedFundingRequestList from '@/views/Regular/DeclinedFundingRequests/List.vue'
import RegularOffersIndex from '@/views/Regular/Offers/Index.vue'
import RegularOffersList from '@/views/Regular/Offers/List.vue'
import RegularSelectedOffersIndex from '@/views/Regular/SelectedOffers/Index.vue'
import RegularSelectedOffersList from '@/views/Regular/SelectedOffers/List.vue'
import RegularSelectedOffersShow from '@/views/Regular/SelectedOffers/Show.vue'
import BusinessFundingRequestsIndex from '@/views/Business/FundingRequests/Index.vue'
import BusinessFundingRequestsList from '@/views/Business/FundingRequests/List.vue'
import BusinessFundingRequestsShow from '@/views/Business/FundingRequests/Show.vue'
import BusinessFundingRequestsTabsOffers from '@/views/Business/FundingRequests/Tabs/Offers.vue'
import BusinessFundingRequestsTabsAdditionalInfo from '@/views/Business/FundingRequests/Tabs/AdditionalInfo.vue'
import BusinessFundingRequestsTabsVerifications from '@/views/Business/FundingRequests/Tabs/Verifications.vue'
import BusinessFundingRequestsTabsAssignedUsers from '@/views/Business/FundingRequests/Tabs/AssignedUsers.vue'
import BusinessFundingRequestsTabsActivityLog from '@/views/Business/FundingRequests/Tabs/ActivityLog.vue'
import BusinessFundingRequestsTabsAssignedFinanciers from '@/views/Business/FundingRequests/Tabs/AssignedFinanciers.vue'
import BusinessFundingRequestsEdit from '@/views/Business/FundingRequests/Edit.vue'
import BusinessDeclinedFundingRequestsIndex from '@/views/Business/DeclinedFundingRequests/Index.vue'
import BusinessDeclinedFundingRequestsList from '@/views/Business/DeclinedFundingRequests/List.vue'
import BusinessOffersIndex from '@/views/Business/Offers/Index.vue'
import BusinessOffersList from '@/views/Business/Offers/List.vue'
import BusinessSelectedOffersIndex from '@/views/Business/SelectedOffers/Index.vue'
import BusinessSelectedOffersList from '@/views/Business/SelectedOffers/List.vue'
import BusinessSelectedOffersShow from '@/views/Business/SelectedOffers/Show.vue'
import EmployeesIndex from '@/views/Employees/Index.vue'
import EmployeesList from '@/views/Employees/List.vue'
import EmployeesCreate from '@/views/Employees/Create.vue'
import EmployeesEditTabsIndex from '@/views/Employees/EditTabs/Index.vue'
import EmployeesEditTabsBasicEdit from '@/views/Employees/EditTabs/BasicEdit.vue'
import EmployeesEditTabsPermissionsEdit from '@/views/Employees/EditTabs/PermissionsEdit.vue'
import CustomersIndex from '@/views/Customers/Index.vue'
import CustomerList from '@/views/Customers/List.vue'
import CustomersCreate from '@/views/Customers/Create.vue'
import CustomersEditTabsIndex from '@/views/Customers/EditTabs/Index.vue'
import CustomersEditTabsBasicEdit from '@/views/Customers/EditTabs/BasicEdit.vue'
import CustomersEditTabsPermissionsEdit from '@/views/Customers/EditTabs/PermissionsEdit.vue'
import ColleaguesIndex from '@/views/Colleagues/Index.vue'
import ColleaguesList from '@/views/Colleagues/List.vue'
import ColleaguesShow from '@/views/Colleagues/Show.vue'
import SettingsSettings from '@/views/Settings/Settings.vue'
import SettingsTabsFinanciersIndex from '@/views/Settings/Tabs/Financiers/Index.vue'
import SettingsTabsFinanciersList from '@/views/Settings/Tabs/Financiers/List.vue'
import SettingsTabsFinanciersCreate from '@/views/Settings/Tabs/Financiers/Create.vue'
import SettingsTabsFinanciersEdit from '@/views/Settings/Tabs/Financiers/Edit.vue'
import SettingsTabsAdditionalDocumentsIndex from '@/views/Settings/Tabs/AdditionalDocuments/Index.vue'
import SettingsTabsAdditionalDocumentsList from '@/views/Settings/Tabs/AdditionalDocuments/List.vue'
import SettingsTabsUserData from '@/views/Settings/Tabs/UserData.vue'
import SettingsTabsNotifications from '@/views/Settings/Tabs/Notifications.vue'
import SettingsTabsRolesIndex from '@/views/Settings/Tabs/Roles/Index.vue'
import SettingsTabsRolesList from '@/views/Settings/Tabs/Roles/List.vue'
import SettingsTabsRolesCreate from '@/views/Settings/Tabs/Roles/Create.vue'
import SettingsTabsRolesEdit from '@/views/Settings/Tabs/Roles/Edit.vue'
import ActivityLogIndex from '@/views/ActivityLog/Index.vue'
import ActivityLogList from '@/views/ActivityLog/List.vue'
import Login from '@/views/Login.vue'
import RemindPassword from '@/views/RemindPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import RegularArchivedFundingRequestIndex from '@/views/Regular/ArchivedFundingRequests/Index.vue'
import RegularArchivedFundingRequestList from '@/views/Regular/ArchivedFundingRequests/List.vue'
import Debts from '@/views/Regular/FundingRequests/Tabs/Debts.vue'
import Finances from '@/views/Regular/FundingRequests/Tabs/Finances.vue'
import SettingsTabsRecommendations from '@/views/Settings/Tabs/Recommendations.vue'

export default {
  path: '/',
  name: AdminRoutes.index,
  component: Admin,
  redirect: { name: AdminRoutes.dashboard },
  meta: {
    loginRoute: AdminRoutes.login,
  },
  children: [
    {
      path: '/',
      name: AdminRoutes.dashboard,
      component: Dashboard,
      meta: getRouteMeta(AdminRoutes.dashboard),
    },
    {
      path: 'profile',
      name: AdminRoutes.profile,
      component: Profile,
      meta: {
        shouldLoggedIn: true,
      },
    },
    {
      path: 'funding-requests',
      name: AdminRoutes.fundingRequests,
      component: Index,
      meta: getRouteMeta(AdminRoutes.fundingRequests),
      redirect: { name: AdminRoutes.fundingRequestsIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.fundingRequestsIndex,
          component: List,
          meta: getRouteMeta(AdminRoutes.fundingRequestsIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.fundingRequestsShow,
          // component: () => import('@/views/Regular/FundingRequests/Show.vue'),
          component: Show,
          meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
          children: [
            {
              path: 'offers',
              name: AdminRoutes.fundingRequestsShowTabOffers,
              component: Offers,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'documents',
              name: AdminRoutes.fundingRequestsShowTabDocuments,
              component: Documents,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'verifications',
              name: AdminRoutes.fundingRequestsShowTabVerifications,
              component: Verifications,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'assigned-users',
              name: AdminRoutes.fundingRequestsShowTabAssignedUsers,
              component: AssignedUsers,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'activity-log',
              name: AdminRoutes.fundingRequestsShowTabActivityLog,
              component: ActivityLog,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'assigned-financiers',
              name: AdminRoutes.fundingRequestsShowTabAssignedFinanciers,
              component: AssignedFinanciers,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'debts',
              name: AdminRoutes.fundingRequestsShowTabDebts,
              component: Debts,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
            {
              path: 'finances',
              name: AdminRoutes.fundingRequestsShowTabFinances,
              component: Finances,
              meta: getRouteMeta(AdminRoutes.fundingRequestsShow),
            },
          ],
        },
        {
          path: ':uuid/edit',
          name: AdminRoutes.fundingRequestsEdit,
          component: Edit,
          meta: getRouteMeta(AdminRoutes.fundingRequestsEdit),
        },
      ],
    },
    {
      path: 'declined-funding-requests',
      name: AdminRoutes.declinedFundingRequests,
      component: RegularDeclinedFundingRequestIndex,
      meta: getRouteMeta(AdminRoutes.fundingRequests),
      redirect: { name: AdminRoutes.declinedFundingRequestsIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.declinedFundingRequestsIndex,
          component: RegularDeclinedFundingRequestList,
          meta: getRouteMeta(AdminRoutes.fundingRequestsIndex),
        },
      ],
    },
    {
      path: 'archived-funding-requests',
      name: AdminRoutes.archivedFundingRequests,
      component: RegularArchivedFundingRequestIndex,
      meta: getRouteMeta(AdminRoutes.fundingRequests),
      redirect: { name: AdminRoutes.archivedFundingRequestsIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.archivedFundingRequestsIndex,
          component: RegularArchivedFundingRequestList,
          meta: getRouteMeta(AdminRoutes.fundingRequestsIndex),
        },
      ],
    },
    {
      path: 'offers',
      name: AdminRoutes.offers,
      component: RegularOffersIndex,
      meta: getRouteMeta(AdminRoutes.offers),
      redirect: { name: AdminRoutes.offersIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.offersIndex,
          component: RegularOffersList,
          meta: getRouteMeta(AdminRoutes.offersIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.offersShow,
          component: Show,
          meta: getRouteMeta(AdminRoutes.offersShow),
        },
      ],
    },
    {
      path: 'selected-offers',
      name: AdminRoutes.selectedOffers,
      component: RegularSelectedOffersIndex,
      meta: getRouteMeta(AdminRoutes.selectedOffers),
      redirect: { name: AdminRoutes.selectedOffersIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.selectedOffersIndex,
          component: RegularSelectedOffersList,
          meta: getRouteMeta(AdminRoutes.selectedOffersIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.selectedOffersShow,
          component: RegularSelectedOffersShow,
          redirect: { name: AdminRoutes.selectedOffersShowTabOffers },
          meta: getRouteMeta(AdminRoutes.selectedOffersShow),
          children: [
            {
              path: 'offers',
              name: AdminRoutes.selectedOffersShowTabOffers,
              component: Offers,
              meta: getRouteMeta(AdminRoutes.selectedOffersShow),
            },
            {
              path: 'documents',
              name: AdminRoutes.selectedOffersShowTabDocuments,
              component: Documents,
              meta: getRouteMeta(AdminRoutes.selectedOffersShow),
            },
            {
              path: 'verifications',
              name: AdminRoutes.selectedOffersShowTabVerifications,
              component: Verifications,
              meta: getRouteMeta(AdminRoutes.selectedOffersShow),
            },
            {
              path: 'activity-log',
              name: AdminRoutes.selectedOffersShowTabActivityLog,
              component: ActivityLog,
              meta: getRouteMeta(AdminRoutes.selectedOffersShow),
            },
          ],
        },
      ],
    },
    {
      path: 'business-funding-requests',
      name: AdminRoutes.businessFundingRequests,
      component: BusinessFundingRequestsIndex,
      meta: getRouteMeta(AdminRoutes.businessFundingRequests),
      redirect: { name: AdminRoutes.businessFundingRequestsIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.businessFundingRequestsIndex,
          component: BusinessFundingRequestsList,
          meta: getRouteMeta(AdminRoutes.businessFundingRequestsIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.businessFundingRequestsShow,
          component: BusinessFundingRequestsShow,
          meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
          children: [
            {
              path: 'offers',
              name: AdminRoutes.businessFundingRequestsShowTabOffers,
              component: BusinessFundingRequestsTabsOffers,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
            {
              path: 'documents',
              name: AdminRoutes.businessFundingRequestsShowTabDocuments,
              component: BusinessFundingRequestsTabsAdditionalInfo,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
            {
              path: 'verifications',
              name: AdminRoutes.businessFundingRequestsShowTabVerifications,
              component: BusinessFundingRequestsTabsVerifications,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
            {
              path: 'assigned-users',
              name: AdminRoutes.businessFundingRequestsShowTabAssignedUsers,
              component: BusinessFundingRequestsTabsAssignedUsers,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
            {
              path: 'activity-log',
              name: AdminRoutes.businessFundingRequestsShowTabActivityLog,
              component: BusinessFundingRequestsTabsActivityLog,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
            {
              path: 'assigned-financiers',
              name: AdminRoutes.businessFundingRequestsShowTabAssignedFinanciers,
              component: BusinessFundingRequestsTabsAssignedFinanciers,
              meta: getRouteMeta(AdminRoutes.businessFundingRequestsShow),
            },
          ],
        },
        {
          path: ':uuid/edit',
          name: AdminRoutes.businessFundingRequestsEdit,
          component: BusinessFundingRequestsEdit,
          meta: getRouteMeta(AdminRoutes.businessFundingRequestsEdit),
        },
      ],
    },
    {
      path: 'declined-business-funding-requests',
      name: AdminRoutes.declinedBusinessFundingRequests,
      component: BusinessDeclinedFundingRequestsIndex,
      meta: getRouteMeta(AdminRoutes.businessFundingRequests),
      redirect: { name: AdminRoutes.declinedBusinessFundingRequestsIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.declinedBusinessFundingRequestsIndex,
          component: BusinessDeclinedFundingRequestsList,
          meta: getRouteMeta(AdminRoutes.businessFundingRequestsIndex),
        },
      ],
    },
    {
      path: 'business-offers',
      name: AdminRoutes.businessOffers,
      component: BusinessOffersIndex,
      meta: getRouteMeta(AdminRoutes.businessOffers),
      redirect: { name: AdminRoutes.businessOffersIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.businessOffersIndex,
          component: BusinessOffersList,
          meta: getRouteMeta(AdminRoutes.businessOffersIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.businessOffersShow,
          component: BusinessFundingRequestsShow,
          meta: getRouteMeta(AdminRoutes.businessOffersShow),
        },
      ],
    },
    {
      path: 'selected-business-offers',
      name: AdminRoutes.selectedBusinessOffers,
      component: BusinessSelectedOffersIndex,
      meta: getRouteMeta(AdminRoutes.selectedBusinessOffers),
      redirect: { name: AdminRoutes.selectedBusinessOffersIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.selectedBusinessOffersIndex,
          component: BusinessSelectedOffersList,
          meta: getRouteMeta(AdminRoutes.selectedBusinessOffersIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.selectedBusinessOffersShow,
          component: BusinessSelectedOffersShow,
          redirect: { name: AdminRoutes.selectedBusinessOffersShowTabOffers },
          meta: getRouteMeta(AdminRoutes.selectedBusinessOffersShow),
          children: [
            {
              path: 'offers',
              name: AdminRoutes.selectedBusinessOffersShowTabOffers,
              component: Offers,
              meta: getRouteMeta(AdminRoutes.selectedBusinessOffersShow),
            },
            {
              path: 'documents',
              name: AdminRoutes.selectedBusinessOffersShowTabDocuments,
              component: Documents,
              meta: getRouteMeta(AdminRoutes.selectedBusinessOffersShow),
            },
            {
              path: 'verifications',
              name: AdminRoutes.selectedBusinessOffersShowTabVerifications,
              component: Verifications,
              meta: getRouteMeta(AdminRoutes.selectedBusinessOffersShow),
            },
            {
              path: 'activity-log',
              name: AdminRoutes.selectedBusinessOffersShowTabActivityLog,
              component: ActivityLog,
              meta: getRouteMeta(AdminRoutes.selectedBusinessOffersShow),
            },
          ],
        },
      ],
    },
    {
      path: 'employees',
      name: AdminRoutes.employees,
      component: EmployeesIndex,
      meta: {
        shouldLoggedIn: true,
        permission: permissions.users.list,
        redirect: AdminRoutes.dashboard,
      },
      redirect: { name: AdminRoutes.employeesIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.employeesIndex,
          component: EmployeesList,
          meta: getRouteMeta(AdminRoutes.employeesIndex),
        },
        {
          path: 'create',
          name: AdminRoutes.employeesCreate,
          component: EmployeesCreate,
          meta: getRouteMeta(AdminRoutes.employeesCreate),
        },
        {
          path: 'edit/:uuid',
          name: AdminRoutes.employeesEdit,
          component: EmployeesEditTabsIndex,
          redirect: { name: AdminRoutes.employeesEditBasic },
          children: [
            {
              path: 'basic',
              name: AdminRoutes.employeesEditBasic,
              component: EmployeesEditTabsBasicEdit,
              meta: getRouteMeta(AdminRoutes.employeesEditBasic),
            },
            {
              path: 'permissions',
              name: AdminRoutes.employeesEditPermissions,
              component: EmployeesEditTabsPermissionsEdit,
              meta: getRouteMeta(AdminRoutes.employeesEditPermissions),
            },
          ],
        },
      ],
    },
    {
      path: 'customers',
      name: AdminRoutes.customers,
      component: CustomersIndex,
      meta: {
        shouldLoggedIn: true,
        permission: permissions.users.list,
        redirect: AdminRoutes.dashboard,
      },
      redirect: { name: AdminRoutes.customersIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.customersIndex,
          component: CustomerList,
          meta: getRouteMeta(AdminRoutes.customersIndex),
        },
        {
          path: 'create',
          name: AdminRoutes.customersCreate,
          component: CustomersCreate,
          meta: getRouteMeta(AdminRoutes.customersCreate),
        },
        {
          path: 'edit/:uuid',
          name: AdminRoutes.customersEdit,
          component: CustomersEditTabsIndex,
          redirect: { name: AdminRoutes.customersEditBasic },
          children: [
            {
              path: 'basic',
              name: AdminRoutes.customersEditBasic,
              component: CustomersEditTabsBasicEdit,
              meta: getRouteMeta(AdminRoutes.customersEditBasic),
            },
            {
              path: 'permissions',
              name: AdminRoutes.customersEditPermissions,
              component: CustomersEditTabsPermissionsEdit,
              meta: getRouteMeta(AdminRoutes.customersEditPermissions),
            },
          ],
        },
      ],
    },
    {
      path: 'colleagues',
      name: AdminRoutes.colleagues,
      component: ColleaguesIndex,
      meta: getRouteMeta(AdminRoutes.colleagues),
      redirect: { name: AdminRoutes.colleaguesIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.colleaguesIndex,
          component: ColleaguesList,
          meta: getRouteMeta(AdminRoutes.colleaguesIndex),
        },
        {
          path: ':uuid',
          name: AdminRoutes.colleaguesShow,
          component: ColleaguesShow,
          meta: getRouteMeta(AdminRoutes.colleaguesShow),
        },
      ],
    },
    {
      path: 'settings',
      name: AdminRoutes.settings,
      component: SettingsSettings,
      meta: getRouteMeta(AdminRoutes.settings),
      children: [
        {
          path: 'financiers',
          name: AdminRoutes.settingsFinanciers,
          component: SettingsTabsFinanciersIndex,
          redirect: { name: AdminRoutes.settingsFinanciersIndex },
          children: [
            {
              path: '',
              name: AdminRoutes.settingsFinanciersIndex,
              component: SettingsTabsFinanciersList,
              meta: getRouteMeta(AdminRoutes.settingsFinanciersIndex),
            },
            {
              path: 'create',
              name: AdminRoutes.settingsFinanciersCreate,
              component: SettingsTabsFinanciersCreate,
              meta: getRouteMeta(AdminRoutes.settingsFinanciersCreate),
            },
            {
              path: 'edit/:uuid',
              name: AdminRoutes.settingsFinanciersEdit,
              component: SettingsTabsFinanciersEdit,
              meta: getRouteMeta(AdminRoutes.settingsFinanciersEdit),
            },
          ],
        },
        {
          path: 'additional-documents',
          name: AdminRoutes.settingsAdditionalDocuments,
          component: SettingsTabsAdditionalDocumentsIndex,
          redirect: { name: AdminRoutes.settingsAdditionalDocumentsIndex },
          children: [
            {
              path: '',
              name: AdminRoutes.settingsAdditionalDocumentsIndex,
              component: SettingsTabsAdditionalDocumentsList,
              meta: getRouteMeta(AdminRoutes.settingsAdditionalDocumentsIndex),
            },
          ],
        },
        {
          path: 'user-data',
          name: AdminRoutes.settingsUserData,
          component: SettingsTabsUserData,
          meta: getRouteMeta(AdminRoutes.settingsUserData),
        },
        {
          path: 'notifications',
          name: AdminRoutes.settingsNotifications,
          component: SettingsTabsNotifications,
          meta: getRouteMeta(AdminRoutes.settingsNotifications),
        },
        {
          path: 'roles',
          name: AdminRoutes.settingsRoles,
          component: SettingsTabsRolesIndex,
          redirect: { name: AdminRoutes.settingsRolesIndex },
          children: [
            {
              path: '',
              name: AdminRoutes.settingsRolesIndex,
              component: SettingsTabsRolesList,
              meta: getRouteMeta(AdminRoutes.settingsRolesIndex),
            },
            {
              path: 'create',
              name: AdminRoutes.settingsRolesCreate,
              component: SettingsTabsRolesCreate,
              meta: getRouteMeta(AdminRoutes.settingsRolesCreate),
            },
            {
              path: 'edit/:uuid',
              name: AdminRoutes.settingsRolesEdit,
              component: SettingsTabsRolesEdit,
              meta: getRouteMeta(AdminRoutes.settingsRolesEdit),
            },
          ],
        },
        {
          path: 'recommendations',
          name: AdminRoutes.settingsRecommendations,
          component: SettingsTabsRecommendations,
          meta: getRouteMeta(AdminRoutes.settingsRecommendations),
        },
      ],
    },
    {
      path: 'activity-log',
      name: AdminRoutes.activityLog,
      component: ActivityLogIndex,
      meta: getRouteMeta(AdminRoutes.activityLog),
      redirect: { name: AdminRoutes.activityLogIndex },
      children: [
        {
          path: '',
          name: AdminRoutes.activityLogIndex,
          component: ActivityLogList,
          meta: getRouteMeta(AdminRoutes.activityLogIndex),
        },
      ],
    },
    {
      path: 'login',
      name: AdminRoutes.login,
      component: Login,
      meta: {
        shouldNotLoggedIn: true,
        redirect: AdminRoutes.dashboard,
      },
    },
    {
      path: 'remind-password',
      name: AdminRoutes.remindPassword,
      component: RemindPassword,
      meta: {
        shouldNotLoggedIn: true,
        redirect: AdminRoutes.dashboard,
      },
    },
    {
      path: 'reset-password/:token/:email',
      name: AdminRoutes.resetPassword,
      component: ResetPassword,
      meta: {
        shouldNotLoggedIn: true,
        redirect: AdminRoutes.dashboard,
      },
    },
    { path: '*', redirect: { name: AdminRoutes.index } }, // Catch All
  ],
}
