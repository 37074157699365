const authKey = 'authToken'

const getToken = (): string | null => localStorage.getItem(authKey)

const setToken = (token: string): void => localStorage.setItem(authKey, token)

const clearToken = (): void => localStorage.removeItem(authKey)

export default {
  getToken,
  setToken,
  clearToken,
}
