





import { Component, Prop, Vue } from 'vue-property-decorator'
import can from '@/helpers/can'

@Component
export default class Can extends Vue {
  @Prop() permissions!: string[]
  // At least one permission needed to pass through if atLeastOne = true
  @Prop() atLeastOne!: boolean

  get canAccess(): boolean {
    if (!this.permissions) return false

    return can(this.permissions, this.atLeastOne)
  }
}
