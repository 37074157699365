import { IState } from '@/store/auth/interfaces'
import UserModel from '@/modules/user/user.model'

export enum Getters {
  isLogged = 'AUTH/IS_LOGGED',
  getUser = 'AUTH/GET_USER',
}

export default {
  [Getters.isLogged]: (state: IState): boolean => !!state.user,
  [Getters.getUser]: (state: IState): UserModel | null => state.user,
}
