











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import { HttpMethod } from '@/helpers/requests'
import http from '@/http'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { FundingRequestFinancierInfoAttribute } from '@/modules/funding-request-financier-info/funding-request-financier-info.model'

@Component({
  components: {
    Form,
  },
})
export default class AdditionalInfoProvideAdminDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setMethod(HttpMethod.POST)
    .setInjectValues({
      uuid: this.meta.uuid,
    })
    .setOnSuccess(this.onSuccess)

  created(): void {
    const fields: Field[] = []

    if (this.meta.type !== 'document') {
      fields.push(
        new Field().setType(FieldTypes.text).setKey(FundingRequestFinancierInfoAttribute.answer).setTitle('Atsakymas'),
      )
    } else {
      fields.push(
        new Field()
          .setType(FieldTypes.file)
          .setKey(FundingRequestFinancierInfoAttribute.attachments)
          .setTitle('Dokumentai')
          .setMeta({ multiple: true }),
      )
    }

    this.form.setFields(fields)
  }

  get headline(): string {
    return this.meta.type === 'document' ? 'dokumentus' : 'atsakymą'
  }

  onSuccess(data: any): void {
    const info = new FormData()
    let headers

    info.append(`info[${this.meta.uuid}][uuid]`, this.meta.uuid)

    if (this.meta.type === 'document') {
      headers = {
        'Content-Type': 'multipart/form-data',
      }

      info.append(`info[${this.meta.uuid}][additional_document_uuid]`, this.meta.additional_document_uuid)

      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((file: File) => {
          info.append(`info[${this.meta.uuid}][attachments][]`, file)
        })
      }
    } else {
      info.append(`info[${this.meta.uuid}][answer]`, data.answer)
      info.append(`info[${this.meta.uuid}][question]`, this.meta.question)
    }

    http
      .post(`additional-information/funding-request/${this.meta.funding_request.uuid}/provide`, info, {
        headers,
      })
      .then(() => {
        this.closeDialog()
        this.meta.onSuccess(data.data)
      })
  }
}
