import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableFilterObject from '@/classes/DataTable/DataTableFilterObject'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import DataTableFilter from '@/classes/DataTable/DataTableFilter'
import _ from 'lodash'
import can from '@/helpers/can'
import Model from '@/classes/Model'

export default class DataTableBase {
  model!: any
  data: any = []
  endpoint!: string
  headers: DataTableHeader[] = []
  filter: DataTableFilterObject = new DataTableFilterObject()
  actions: DataTableAction[] = []
  hasFilter: boolean = true
  loading: boolean = false
  isSelectable: boolean = false
  selected: any[] = []
  selectBy!: any
  paginationNotVisible: boolean = false
  isSortable: boolean = false
  itemKey: string = 'uuid'
  isExpandable: boolean = false
  expansionComponent: any = null
  expandedData: any = []
  expansionDataCallback!: (item: any) => Promise<any>

  setModel(model: any): DataTableBase {
    this.model = model

    return this
  }

  setSelected(items: any[]): DataTableBase {
    this.selected = items

    return this
  }

  setSelectBy(selectBy: any): DataTableBase {
    this.selectBy = selectBy

    return this
  }

  setIsSelectable(): DataTableBase {
    this.isSelectable = true

    return this
  }

  setEndpoint(endpoint: string): DataTableBase {
    this.endpoint = endpoint

    return this
  }

  setHeaders(headers: DataTableHeader[]): DataTableBase {
    this.headers = headers

    return this
  }

  addHeader(header: DataTableHeader): DataTableBase {
    this.headers = [...this.headers, header]

    return this
  }

  unshiftHeader(header: DataTableHeader): DataTableBase {
    this.headers = [header, ...this.headers]

    return this
  }

  setActions(actions: DataTableAction[]): DataTableBase {
    this.actions = actions.filter((action: DataTableAction) => {
      if (!action.permissions) return true

      return can(action.permissions)
    })

    return this
  }

  setFilters(filters: DataTableFilter[]): DataTableBase {
    this.filter.setFilters(filters)
    this.filter.filters = filters

    return this
  }

  setData(data: any[]): DataTableBase {
    this.data = data

    return this
  }

  setItem(entry: any): DataTableBase {
    const data = _.cloneDeep(this.data)

    const index = data.findIndex((item: Model) => item.uuid === entry.uuid)
    data[index] = entry
    this.setData(data)

    return this
  }

  unshiftItem(item: any): DataTableBase {
    this.data = [item, ...this.data]

    return this
  }

  addItem(item: any): DataTableBase {
    this.data = [...this.data, item]

    return this
  }

  removeItem(item: any): DataTableBase {
    this.setData([...this.data.filter((itemI: any) => itemI.uuid !== item.uuid)])

    return this
  }

  setPaginationNotVisible(paginationNotVisible: boolean): DataTableBase {
    this.paginationNotVisible = paginationNotVisible

    return this
  }

  setIsSortable(isSortable: boolean): DataTableBase {
    this.isSortable = isSortable

    return this
  }

  setHasFilter(hasFilter: boolean): DataTableBase {
    this.hasFilter = hasFilter

    return this
  }

  setIsExpandable(isExpandable: boolean): DataTableBase {
    this.isExpandable = isExpandable

    return this
  }

  setExpansionComponent(component: any): DataTableBase {
    this.expansionComponent = component

    return this
  }

  setExpansionDataCallback(callback: (item: any) => Promise<any>): DataTableBase {
    this.expansionDataCallback = callback

    return this
  }

  setExpandedData(expandedData: any): DataTableBase {
    this.expandedData = expandedData

    return this
  }
}
