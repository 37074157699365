






import { Component, Prop, Vue } from 'vue-property-decorator'
import { FieldTypes, fieldTypesMap } from '@/components/Form/FieldTypes'
import Field from '@/classes/Form/Field'
import FormBase from '@/classes/Form/FormBase'

@Component
export default class FormField extends Vue {
  @Prop() field!: Field
  @Prop() fullKey!: string
  @Prop() form!: FormBase

  // eslint-disable-next-line consistent-return
  getComponent(type: FieldTypes): any {
    // eslint-disable-next-line no-prototype-builtins
    if (fieldTypesMap.hasOwnProperty(type)) {
      return fieldTypesMap[type]
    }

    console.log(`${type} is not defined`)
  }
}
