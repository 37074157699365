















import { Component } from 'vue-property-decorator'
import DialogBase from '@/classes/Dialog'
import FormBase from '@/classes/Form/FormBase'
import Form from '@/components/Form/Form.vue'
import { HttpMethod } from '@/helpers/requests'
import { Actions } from '@/store/global/actions'
import DepartmentModel from '@/modules/department/department.model'
import OfferModel, { OfferAttribute } from '@/modules/offer/offer.model'
import Field from '@/classes/Form/Field'
import { FieldTypes } from '@/components/Form/FieldTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import { documentsTypes } from '@/helpers/mimeTypes'

@Component({
  components: {
    Form,
  },
})
export default class EditDialog extends DialogBase {
  form: FormBase = new FormBase()
    .setFiles(true)
    .setUuid(this.meta.entry.uuid)
    .setEndpoint('offers')
    .setModel(OfferModel)
    .setMethod(HttpMethod.POST)
    .setEntry(this.meta.entry)
    .setInjectValues({
      _method: 'PUT',
      funding_request_uuid: this.meta.fundingRequestUuid,
    })
    .setFields([
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.period)
        .setTitle('Periodas')
        .setAppend(this.meta.entry.period_measure),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.annualInterestRate)
        .setEntryKey(OfferAttribute.annualInterestRate)
        .setTitle('Palūkanos')
        .setAppend('%'),
      new Field().setType(FieldTypes.number).setKey(OfferAttribute.amount).setTitle('Suma').setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.monthlyAmount)
        .setEntryKey(OfferAttribute.monthlyAmount)
        .setTitle('Mėn. suma')
        .setAppend('€/mėn'),
      new Field()
        .setType(FieldTypes.number)
        .setKey(OfferAttribute.finalAmount)
        .setTitle('Bendra gražintina suma')
        .setAppend('€'),
      new Field()
        .setType(FieldTypes.number)
        .setVisibleIf(() => this.meta.fundingRequest && this.meta.fundingRequest.type !== 'business')
        .setKey(OfferAttribute.bvkkmn)
        .setTitle('*BVKKMN')
        .setAppend('%'),
      new Field().setType(FieldTypes.textArea).setKey(OfferAttribute.description).setTitle('Aprašymas'),
      new SelectField()
        .setKey(OfferAttribute.type)
        .setTitle('Tipas')
        .setItems([
          new SelectItem().setTitle('Įpareigojantis').setValue('binding'),
          new SelectItem().setTitle('Preliminarus').setValue('preliminary'),
        ]),
      new Field()
        .setType(FieldTypes.file)
        .setKey(OfferAttribute.attachments)
        .setDisablePreview(true)
        .setTitle('Dokumentai')
        .setMeta({
          type: 'file',
          accept: documentsTypes.join(','),
          multiple: true,
        }),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: any, entry: DepartmentModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Pasiūlymas buvo sėkmingai paredaguotas',
    })
    this.meta.onSuccess(entry)
    this.closeDialog()
  }
}
