import Model from '@/classes/Model'
import IDebtInterface from '@/modules/debt/debt.interface'

export enum DebtAttribute {
  amount = 'amount',
  amountOriginal = 'amount_original',
  creditorName = 'creditor_name',
  currency = 'currency',
  date = 'date',
  debtStatusDate = 'debt_status_date',
  disputed = 'disputed',
  status = 'status',
  type = 'type',
}

export default class DebtModel extends Model {
  [DebtAttribute.amount]!: number;
  [DebtAttribute.amountOriginal]!: number;
  [DebtAttribute.creditorName]!: string;
  [DebtAttribute.currency]!: string;
  [DebtAttribute.date]!: Date;
  [DebtAttribute.debtStatusDate]!: Date;
  [DebtAttribute.disputed]!: string;
  [DebtAttribute.status]!: string;
  [DebtAttribute.type]!: number

  transform(data: IDebtInterface): DebtModel {
    this.amount = data.Amount
    this.amount_original = data.AmountOriginal
    this.creditor_name = data.CreditorName
    this.currency = data.Currency
    this.date = data.Date
    this.debt_status_date = data.DebtStatusDate
    this.disputed = data.Disputed
    this.status = data.Status
    this.type = data.Type

    return this
  }
}
