











import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import FundingRequestFieldModel, {
  fundingRequestFieldTypes,
} from '@/modules/funding-request-field/funding-request-field.model'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import IOption from '@/interfaces/option.interface'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { Actions } from '@/store/global/actions'
import { IDialog } from '@/store/global/helpers'
import CreateDialog from '@/components/UserData/CreateDialog.vue'
import EditDialog from '@/components/UserData/EditDialog.vue'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class SettingsTabsUserData extends Vue {
  permissions = permissions
  table = new DataTableBase()
    .setModel(FundingRequestFieldModel)
    .setEndpoint('funding-request-fields')
    .setIsSortable(true)
    .setHeaders([
      new DataTableHeader()
        .setValue('type')
        .setText('Tipas')
        .setValueParser(SettingsTabsUserData.typeParser)
        .setSortable(false),
      new DataTableHeader().setValue('label').setText('Pavadinimas').setSortable(false),
      new DataTableHeader()
        .setValue('options')
        .setText('Galimos reikšmės')
        .setValueParser(SettingsTabsUserData.optionsParser)
        .setSortable(false),
      new DataTableHeader()
        .setType(DataTableHeaderTypes.boolean)
        .setValue('required')
        .setText('Privalomas')
        .setSortable(false),
      new DataTableHeader()
        .setType(DataTableHeaderTypes.boolean)
        .setValue('enabled')
        .setText('Matomas')
        .setSortable(false),
    ])
    .setPaginationNotVisible(true)
    .setActions([
      new DataTableAction()
        .setIcon('edit')
        .setTitle('Redaguoti')
        .setAction(this.editItem)
        .setPermissions([permissions.fundingRequests.edit, permissions.fundingRequests.show]),
      new DataTableAction()
        .setIcon('delete')
        .setTitle('Ištrinti')
        .setAction(this.deleteItem)
        .setPermissions([permissions.fundingRequests.delete]),
    ])

  goToCreate(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: CreateDialog,
      maxWidth: 650,
      meta: {
        onSuccess: (entry: FundingRequestFieldModel) => this.table.unshiftItem(entry),
      },
    } as IDialog)
  }

  editItem(item: FundingRequestFieldModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: EditDialog,
      maxWidth: 650,
      meta: {
        entry: item,
        onSuccess: (entry: FundingRequestFieldModel) => this.table.setItem(entry),
      },
    })
  }

  deleteItem(item: FundingRequestFieldModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite vartotojo duomenų lauką (${item.label}) ir šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: FundingRequestFieldModel): Promise<DataTableBase> {
    return http.delete(`${this.table.endpoint}/${item.uuid}`).then(() => this.table.removeItem(item))
  }

  private static typeParser(value: any): string {
    const found = fundingRequestFieldTypes.find((item: IOption) => item.key === value)

    return found ? found.title : ''
  }

  private static optionsParser(value: any): string {
    return value.map((item: any) => item.label).join(', ')
  }
}
