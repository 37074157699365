







































































































import { Component, Vue } from 'vue-property-decorator'
import { IRequestCount } from '@/interfaces/dashboard-response.interface'
import http from '@/http'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import { getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import { AxiosResponse } from 'axios'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'

@Component({
  methods: {
    getFundingRequestStatusTitle,
    can,
  },
})
export default class FundingRequestsStatistics extends Vue {
  permissions = permissions
  requestsCount: IRequestCount | null = null
  offersCount: { percents: number; remaining: number } | null = null
  declinedFundingRequestCount: { percents: number; remaining: number } | null = null
  receivedFundingRequestCount: { percents: number; remaining: number } | null = null

  created(): void {
    http.get('dashboard/requests-count').then((response: AxiosResponse<any>) => {
      this.requestsCount = response.data.request_status_count
      this.declinedFundingRequestCount = response.data.declined_requests_count
      this.offersCount = response.data.offers_count
      this.receivedFundingRequestCount = response.data.received_funding_requests
    })
  }

  getColor(type: string): string {
    const green = [FundingRequestModel.COMPLETE]

    const yellow = [FundingRequestModel.CONFIRMED, FundingRequestModel.FINANCIER_CHOSEN]

    const red = [FundingRequestModel.DRAFT, FundingRequestModel.MISSING_DATA, FundingRequestModel.WAITING_APPROVAL]

    if (green.includes(type)) return 'green'

    if (yellow.includes(type)) return 'yellow'

    if (red.includes(type)) return 'red'

    return 'green'
  }

  goToOffersList(): void {
    this.$router.push({ name: AdminRoutes.offersIndex })
  }
}
