























import { Component, Prop, Vue } from 'vue-property-decorator'
import ITabs from '@/interfaces/tabs'
import can from '@/helpers/can'

@Component({
  methods: {
    can,
  },
})
export default class Tabs extends Vue {
  @Prop() tabs!: ITabs[]
  activeTab: string = ''
}
