





















































import { Component, Inject, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import UserModel from '@/modules/user/user.model'
import Tabs from '@/components/Tabs.vue'
import FinancierModel from '@/modules/financier/financier.model'
import http from '@/http'
import { AxiosResponse } from 'axios'
import IFinancierResponse from '@/modules/financier/financier-response.interface'
import { Actions } from '@/store/global/actions'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessFundingRequestsTabsAssignedFinanciers extends Vue {
  @Inject('fundingRequest') getFundingRequest!: () => FundingRequestModel
  fundingRequestModel = FundingRequestModel
  user: UserModel = this.$store.getters[Getters.getUser]

  selectedItems: any[] = []
  adminRoutes = AdminRoutes

  financiers: FinancierModel[] = []

  permissions = permissions

  created(): void {
    this.prepareTable()
  }

  prepareTable(): void {
    http.get(`/financiers`).then(async (response: AxiosResponse) => {
      this.financiers = response.data.data.map((data: IFinancierResponse) => new FinancierModel().transform(data))
      this.getAssignedFinanciers()
    })
  }

  getAssignedFinanciers(): void {
    http.get(`funding-requests/${this.$router.currentRoute.params.uuid}/assignedFinanciers`).then((response: any) => {
      this.selectedItems = response.data.data
    })
  }

  assignFinanciers(): void {
    http
      .post(`funding-requests/${this.$router.currentRoute.params.uuid}/assignFinanciers`, {
        financiers: this.selectedItems.map((object: any) => object.uuid),
      })
      .then(() => {
        this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Finansuotojai sėkmingai priskirti!',
        })
      })
  }
}
