







import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'

@Component({
  components: {
    AdminLayout,
  },
})
export default class CustomersIndex extends Vue {}
