import Model from '@/classes/Model'
import IFundingRequestFieldResponse from '@/modules/funding-request-field/funding-request-field-response.interface'
import IOption from '@/interfaces/option.interface'
import { FieldTypes } from '@/components/Form/FieldTypes'

export const fundingRequestFieldTypes: IOption[] = [
  {
    key: FieldTypes.text,
    title: 'Tekstinis laukas',
  },
  {
    key: FieldTypes.number,
    title: 'Skaičiaus laukas',
  },
  {
    key: FieldTypes.email,
    title: 'El. pašto laukas',
  },
  {
    key: FieldTypes.select,
    title: 'Pasirinkimo laukas',
  },
  {
    key: FieldTypes.checkbox,
    title: 'Žymimajo langelio laukas',
  },
]

export const fundingRequestFieldGroups: IOption[] = [
  {
    key: 'group-main',
    title: 'Pagrindinė grupė',
  },
  {
    key: 'group-spouse',
    title: 'Sutuoktinių grupė',
  },
  {
    key: 'group-auto-leasing',
    title: 'Auto lizingo grupė',
  },
  {
    key: 'group-business',
    title: 'Verslas',
  },
]

export enum FundingRequestFieldAttribute {
  uuid = 'uuid',
  key = 'key',
  type = 'type',
  group = 'group',
  label = 'label',
  options = 'options',
  default = 'default',
  required = 'required',
  enabled = 'enabled',
  show = 'show',
  visibleByRoleSlug = 'visibleByRoleSlug',
}

export default class FundingRequestFieldModel extends Model {
  [FundingRequestFieldAttribute.key]!: string;
  [FundingRequestFieldAttribute.type]!: string;
  [FundingRequestFieldAttribute.group]!: string;
  [FundingRequestFieldAttribute.label]!: string;
  [FundingRequestFieldAttribute.options]!: any;
  [FundingRequestFieldAttribute.default]!: string;
  [FundingRequestFieldAttribute.visibleByRoleSlug]!: Array<string> | null;
  [FundingRequestFieldAttribute.required]!: boolean;
  [FundingRequestFieldAttribute.enabled]!: boolean;
  [FundingRequestFieldAttribute.show]: boolean = true

  transform(data: IFundingRequestFieldResponse): FundingRequestFieldModel {
    super.transform(data)

    this.key = data.key
    this.type = data.type
    this.group = data.group
    this.label = data.label
    this.options = data.options
    this.default = data.default
    this.required = data.required
    this.enabled = data.enabled
    this.visibleByRoleSlug = data.visible_by_role_slug

    return this
  }
}
