









import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import FundingRequestsStatistics from '@/components/Dashboard/FundingRequestsStatistics.vue'
import LatestFundingRequests from '@/components/Dashboard/LatestFundingRequests.vue'
import FundingRequestsChart from '@/components/Dashboard/FundingRequestsChart.vue'

@Component({
  components: {
    FundingRequestsChart,
    FundingRequestsStatistics,
    LatestFundingRequests,
    AdminLayout,
  },
  methods: {
    can,
  },
})
export default class Dashboard extends Vue {
  permissions = permissions

  created(): void {}
}
