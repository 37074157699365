import ITemplateDocument from '@/modules/attachment/template-document.interface'

export interface IFormError {
  [x: string]: IFieldError
}

export interface IFieldError {
  has: boolean
  count: number
  messages: string[]
}

export enum Positions {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface ISubmit {
  color?: string
  class?: string
  text?: string
  position?: Positions
  disabled?: boolean
}

export interface ILoadItemsProps {
  endpoint: string
  value: string
  title: string
  template_document?: ITemplateDocument | string
}

export interface IFundingRequestType {
  id: number
  key: string
  index: number
  name: string
  description: string
  type: string
  icon: string
  meta: IMetaItem[]
}

export interface IGuaranteesType {
  name: string
  value: string
}

export interface IDistricts {
  title: string
  uuid: string
}

interface IMetaItem {
  key: string
  max: number
  min: number
  value: number
  step: number
  title: string
  parameter: string
  plusDependency?: number
  minusDependency?: number
}
