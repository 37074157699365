import IModelResponse from '@/modules/model-response.interface'

export default class Model {
  uuid!: string
  createdAt!: string
  dynamicPermissions!: any

  hasDynamicPermissions(permissions: string[]): boolean {
    if (!this.dynamicPermissions || !permissions) return true

    return permissions
      .map((permission: string) => permission.split('.')[permission.split('.').length - 1])
      .every((permission: string) => this.dynamicPermissions[permission])
  }

  transform(data: IModelResponse): Model {
    this.uuid = data.uuid
    this.createdAt = data.created_at
    this.dynamicPermissions = data.dynamic_permissions

    return this
  }
}
