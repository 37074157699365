
























import { Component, Prop, Vue } from 'vue-property-decorator'
import AdvanceFilters from '@/components/DataTable/AdvanceFilters.vue'
import FormField from '@/components/Form/FormField.vue'
import Field from '@/classes/Form/Field'
import DataTableFilterObject from '@/classes/DataTable/DataTableFilterObject'

@Component({
  components: {
    FormField,
    AdvanceFilters,
  },
})
export default class Filters extends Vue {
  @Prop() filterProps!: DataTableFilterObject
  @Prop() loading!: boolean
  searchField: Field = new Field()
    .setKey('q')
    .setTitle('Įveskite raktinius žodžius..')
    .setDense(true)
    .setHideDetails(true)
    .setOnEnter(this.search)

  created(): void {
    this.setSearchValue()
    this.$watch('$route', this.setSearchValue)
  }

  search(): void {
    // eslint-disable-next-line prefer-const
    let { query, name } = this.$router.currentRoute
    query = { ...query }

    this.searchField.value === '' ? delete query.q : (query.q = this.searchField.value)
    query.page = String(1)

    this.$router.push({ name: name as string, query }).catch(() => {})
  }

  clear(): void {
    const filtersBlock = this.$refs.filtersBlock as AdvanceFilters

    if (filtersBlock) filtersBlock.clearSelectionForm()

    // eslint-disable-next-line prefer-const
    let { query, name } = this.$router.currentRoute
    query = { ...query }

    Object.keys(query).forEach((key: string) => {
      if (key.startsWith('filter-') || key === 'q') delete query[key]
    })
    this.searchField.value = ''
    this.$router.push({ name: name as string, query }).catch(() => {})
  }

  private setSearchValue(): void {
    const { query } = this.$router.currentRoute
    this.searchField.value = query.q ? String(query.q) : ''
  }
}
