






import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import UserModel from '@/modules/user/user.model'
import Tabs from '@/components/Tabs.vue'
import VerificationModel, { VerificationAttribute } from '@/modules/verification/verification.model'
import { getVerificationTypes } from '@/helpers/fundingRequest'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessFundingRequestsTabsVerifications extends Vue {
  user: UserModel = this.$store.getters[Getters.getUser]
  permissions = permissions
  table: DataTableBase = new DataTableBase()
    .setEndpoint(`verification/${this.$router.currentRoute.params.uuid}`)
    .setModel(VerificationModel)
    .setHasFilter(false)
    .setPaginationNotVisible(true)
    .setHeaders([
      new DataTableHeader()
        .setValue(VerificationAttribute.is_verified)
        .setType(DataTableHeaderTypes.boolean)
        .setText('Patvirtinimas')
        .setSortable(false),
      new DataTableHeader().setValue(VerificationAttribute.name).setText('Vardas').setSortable(false),
      new DataTableHeader().setValue(VerificationAttribute.surname).setText('Pavardė').setSortable(false),
      new DataTableHeader()
        .setValue(VerificationAttribute.type)
        .setText('Patvirtinimo būdas')
        .setValueParser(getVerificationTypes)
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${VerificationAttribute.unique_code}`)
        .setText('Unikalus patvirtinimo kodas')
        .setSortable(false),
      new DataTableHeader().setValue(`${VerificationAttribute.updated_at}`).setText('Data').setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setPermissions([permissions.offers.edit])
        .setIcon('notification_important')
        .setTitle('Pakartoti pranešimus')
        .setAction(this.onNotify),
      new DataTableAction()
        .setPermissions([permissions.verifications.download])
        .setIcon('file_download')
        .setTitle('Atsisiųsti dokumentą')
        .setAction(this.downloadVerificationDocument),
    ])

  downloadVerificationDocument(item: VerificationModel): void {
    if (item[VerificationAttribute.documents]) {
      window.location.replace(item[VerificationAttribute.documents].url)
    }
  }

  onNotify(item: VerificationModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite pakartotinai išsiųsti pranešimus?',
        onYes: () => this.notify(item),
      },
    })
  }

  notify(item: VerificationModel): void {
    http
      .post(
        `business-funding-requests/${this.$router.currentRoute.params.uuid}/repeat-identity-notification/${item.uuid}`,
      )
      .then(() => {
        this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Klientas sėkmingai informuotas!',
        })
      })
  }
}
