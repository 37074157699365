import Model from '@/classes/Model'
import IOfferResponse from '@/modules/offer/offer-response.interface'
import UserModel from '@/modules/user/user.model'
import FinancierModel from '@/modules/financier/financier.model'
import AdditionalDocumentModel from '@/modules/additional-documents/additional-document.model'

export enum OfferAttribute {
  attachments = 'attachments',
  template = 'template',
  period = 'period',
  periodMeasure = 'period_measure',
  periodData = 'periodData',
  amount = 'amount',
  monthlyAmount = 'monthly_amount',
  annualInterestRate = 'annual_interest_rate',
  bvkkmn = 'bvkkmn',
  finalAmount = 'final_amount',
  description = 'description',
  isVoided = 'isVoided',
  type = 'type',
  binding_offer_requested = 'binding_offer_requested',
  binding_offer_request_declined = 'binding_offer_request_declined',

  userUuid = 'userUuid',
  fundingRequestUuid = 'fundingRequestUuid',
  financier_uuid = 'financier_uuid',
  fundingRequest = 'fundingRequest',
  user = 'user',
  financier = 'financier',
  additionalDocuments = 'additional_documents',
  additionalQuestion = 'additional_question',
  status = 'status',
  user_signed_documents = 'user_signed_documents',
}

export default class OfferModel extends Model {
  [OfferAttribute.period]!: number;
  [OfferAttribute.periodMeasure]!: string;
  [OfferAttribute.periodData]!: string;
  [OfferAttribute.amount]!: number;
  [OfferAttribute.monthlyAmount]!: number;
  [OfferAttribute.annualInterestRate]!: number;
  [OfferAttribute.bvkkmn]!: number;
  [OfferAttribute.finalAmount]!: number;
  [OfferAttribute.description]!: string;
  [OfferAttribute.isVoided]!: number;
  [OfferAttribute.type]!: string;
  [OfferAttribute.binding_offer_requested]!: boolean;
  [OfferAttribute.binding_offer_request_declined]!: boolean;

  [OfferAttribute.userUuid]!: string;
  [OfferAttribute.fundingRequestUuid]!: string;
  [OfferAttribute.financier_uuid]!: string;
  [OfferAttribute.fundingRequest]!: any;
  [OfferAttribute.status]!: any;
  [OfferAttribute.user]!: UserModel;
  [OfferAttribute.financier]!: FinancierModel;
  [OfferAttribute.additionalDocuments]!: AdditionalDocumentModel[];
  [OfferAttribute.attachments]!: any[];
  [OfferAttribute.user_signed_documents]!: any[]

  static OVERWRITTEN = 'overwritten'
  static EXPIRED = 'expired'
  static PLAIN = 'plain'

  transform(data: IOfferResponse): OfferModel {
    super.transform(data)

    this.period = data.period
    this.period_measure = data.period_measure
    this.periodData = this.period ? `${this.period} ${this.period_measure}` : '-'
    this.amount = data.amount
    this.monthly_amount = data.monthly_amount
    this.annual_interest_rate = data.annual_interest_rate
    this.bvkkmn = data.bvkkmn
    this.final_amount = data.final_amount
    this.description = data.description
    this.isVoided = data.is_voided
    this.type = data.type
    this.binding_offer_requested = data.binding_offer_requested
    this.binding_offer_request_declined = data.binding_offer_request_declined

    this.userUuid = data.user_uuid
    this.fundingRequestUuid = data.funding_request_uuid
    this.financier_uuid = data.financier_uuid
    this.fundingRequest = data.funding_request
    this.user = data.user && new UserModel().transform(data.user)
    this.financier = data.financier && new FinancierModel().transform(data.financier)
    this.status = data.status
    this.additional_documents = data.additional_documents
    this.attachments = data.attachments
    this.user_signed_documents = data.user_signed_documents

    return this
  }
}
