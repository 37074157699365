








import { Component, Provide, Vue } from 'vue-property-decorator'
import Tabs from '@/components/Tabs.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import ITabs from '@/interfaces/tabs'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import PageHeader from '@/components/PageHeader.vue'
import UserModel from '@/modules/user/user.model'
import http from '@/http'
import IResponse from '@/modules/response.interface'
import IUserResponse from '@/modules/user/user-response.interface'

@Component({
  components: {
    Tabs,
    AdminLayout,
    PageHeader,
  },
})
export default class EmployeesEditTabsIndex extends Vue {
  @Provide('user') getUser(): UserModel | null {
    return this.user
  }

  loading: boolean = true
  user: UserModel | null = null
  tabs!: ITabs[]

  created(): void {
    const { uuid } = this.$router.currentRoute.params
    http
      .get(`/users/${uuid}`)
      .then((response: IResponse<IUserResponse>) => {
        this.user = new UserModel().transform(response.data.data)
        this.tabs = [
          {
            to: AdminRoutes.employeesEditBasic,
            title: 'Pagrindiniai',
            permissions: [permissions.users.edit],
          },
        ]

        if (!this.user.isAdmin) {
          this.tabs = [
            ...this.tabs,
            {
              to: AdminRoutes.employeesEditPermissions,
              title: 'Leidimai',
              permissions: [permissions.users.edit],
            },
          ]
        }
      })
      .finally(() => (this.loading = false))
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.employeesIndex })
  }
}
