











import { Component } from 'vue-property-decorator'
import Form from '@/components/Form/Form.vue'
import Field from '@/classes/Form/Field'
import { Actions } from '@/store/global/actions'
import FormBase from '@/classes/Form/FormBase'
import DialogBase from '@/classes/Dialog'
import { FieldTypes } from '@/components/Form/FieldTypes'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import { HttpMethod } from '@/helpers/requests'
import AdditionalDocumentModel, {
  AdditionalDocumentAttribute,
} from '@/modules/additional-documents/additional-document.model'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { Getters } from '@/store/auth/getters'
import { AxiosResponse } from 'axios'
import { OfferAttribute } from '@/modules/offer/offer.model'
import { documentsTypes } from '@/helpers/mimeTypes'

@Component({
  components: {
    Form,
  },
})
export default class Create extends DialogBase {
  user: UserModel = this.$store.getters[Getters.getUser]
  form: FormBase = new FormBase()
    .setFiles(true)
    .setEndpoint('additional-documents')
    .setMethod(HttpMethod.POST)
    .setModel(AdditionalDocumentModel)
    .setEntry({
      financier_uuid: this.user.financier_uuid,
      is_active: true,
    })
    .setFields([
      new SelectField()
        .setKey(AdditionalDocumentAttribute.financier_uuid)
        .setValue(this.user[UserAttribute.financier_uuid])
        .setTitle('Finansuotojas')
        .loadItems({
          endpoint: `financiers/getForSelect`,
          value: 'uuid',
          title: 'name',
        }),
      new Field().setKey(AdditionalDocumentAttribute.title).setTitle('Dokumento pavadinimas'),
      new Field()
        .setType(FieldTypes.file)
        .setKey(OfferAttribute.template)
        .setDisablePreview(true)
        .setTitle('Pridėti dokumento šabloną')
        .setMeta({
          type: 'file',
          accept: documentsTypes.join(','),
        }),
      new Field().setType(FieldTypes.checkbox).setKey(AdditionalDocumentAttribute.isActive).setTitle('Aktyvus'),
    ])
    .setOnSuccess(this.onSuccess)

  onSuccess(data: AxiosResponse, entry: AdditionalDocumentModel): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Dokumentas sėkmingai sukurtas!',
    })
    this.closeDialog()
    this.meta.onSuccess(entry)
  }
}
