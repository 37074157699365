import TextHeader from '@/components/DataTable/HeaderTypes/TextHeader.vue'
import ActionHeader from '@/components/DataTable/HeaderTypes/ActionHeader.vue'
import ImageHeader from '@/components/DataTable/HeaderTypes/ImageHeader.vue'
import BooleanHeader from '@/components/DataTable/HeaderTypes/BooleanHeader.vue'
import IconHeader from '@/components/DataTable/HeaderTypes/IconHeader.vue'
import LinkHeader from '@/components/DataTable/HeaderTypes/LinkHeader.vue'

enum DataTableHeaderTypes {
  text = 'text',
  action = 'action',
  image = 'image',
  boolean = 'boolean',
  icon = 'icon',
  link = 'link',
}

export default DataTableHeaderTypes

export const dataTableHeaderMap = {
  [DataTableHeaderTypes.text]: TextHeader,
  [DataTableHeaderTypes.action]: ActionHeader,
  [DataTableHeaderTypes.image]: ImageHeader,
  [DataTableHeaderTypes.boolean]: BooleanHeader,
  [DataTableHeaderTypes.icon]: IconHeader,
  [DataTableHeaderTypes.link]: LinkHeader,
}
