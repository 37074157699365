










import { Component, Vue } from 'vue-property-decorator'
import DataTableBase from '@/classes/DataTable/DataTable'
import FundingRequestModel, { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import DataTable from '@/components/DataTable/DataTable.vue'
import PageHeader from '@/components/PageHeader.vue'
import can from '@/helpers/can'
import permissions from '@/helpers/permissions'
import { Getters } from '@/store/auth/getters'

@Component({
  components: {
    DataTable,
    PageHeader,
  },
  methods: {
    can,
  },
})
export default class LatestFundingRequests extends Vue {
  permissions = permissions
  user: UserModel | null = this.$store.getters[Getters.getUser]
  table = new DataTableBase()
    .setEndpoint('dashboard/latest-requests')
    .setModel(FundingRequestModel)
    .setPaginationNotVisible(true)
    .setHasFilter(false)
    .setHeaders([
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.user}.${UserAttribute.fullName}`)
        .setText('Vardas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.amount)
        .setText('Paskolos suma')
        .setValueParser((value) => `${value} €`)
        .setSortable(false),
      new DataTableHeader().setValue(FundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.status)
        .setText('Statusas')
        .setValueParser(getFundingRequestStatusTitle)
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(FundingRequestAttribute.funding_type)
        .setText('Tipas')
        .setValueParser((value) => value.name)
        .setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setPermissions([permissions.fundingRequests.show])
        .setIcon('info')
        .setTitle('Informacija')
        .setAction(this.goToInformation),
    ])

  created(): void {
    if (this.user && this.user[UserAttribute.isAdmin]) {
      this.table.setHeaders([
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
          .setValueParser((value) => `#${value}`)
          .setText('Nr.')
          .setSortable(false),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.confirmed_at}`)
          .setText('Pateikimo data')
          .setSortable(false),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.user}.${UserAttribute.fullName}`)
          .setText('Vardas')
          .setSortable(false),
        new DataTableHeader().setValue(`${FundingRequestAttribute.userEmail}`).setText('El. paštas').setSortable(false),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.userPhone}`)
          .setText('Tel. nr.')
          .setValueParser((value) => `+370${value}`)
          .setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.amount)
          .setText('Paskolos suma')
          .setValueParser((value) => `${value} €`)
          .setSortable(false),
        new DataTableHeader().setValue(FundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.status)
          .setText('Statusas')
          .setValueParser(getFundingRequestStatusTitle)
          .setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.funding_type_name)
          .setKey(FundingRequestAttribute.funding_type)
          .setText('Tipas')
          .setValueParser((value) => value.name)
          .setSortable(false),
      ])
    }
  }

  goToInformation(item: FundingRequestModel): void {
    this.$router.push({
      name: AdminRoutes.fundingRequestsShow,
      params: {
        uuid: item.uuid,
      },
    })
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.fundingRequestsIndex })
  }
}
