import { Component, Prop, Vue } from 'vue-property-decorator'
import Field from '@/classes/Form/Field'
import FormBase from '@/classes/Form/FormBase'
import { IFieldError } from '@/interfaces/form'
import _ from 'lodash'

@Component
export default class AbstractField extends Vue {
  @Prop() field!: Field | any
  @Prop() fullKey!: string
  @Prop() form!: FormBase

  get value(): any {
    if (this.form && !this.form.withoutData) {
      if (!this.field.value) this.field.value = _.get(this.form.data, this.fullKey)

      return this.field.value
    }

    return this.field.value
  }

  set value(value) {
    if (this.form && !this.form.withoutData) {
      this.field.value = value
      _.set(this.form.data, this.fullKey, value)

      if (this.field.callback) {
        this.field.callback(this.field.value)
      }

      return
    }

    this.field.value = value
  }
  getError(): IFieldError | null {
    if (!this.form) return null

    return _.get(this.form.errors, this.fullKey, {
      has: false,
      count: 0,
      messages: [],
    })
  }

  removeError(): void {
    if (!this.form) return
    delete this.form.errors[this.fullKey]
  }
}
