









import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import PageHeader from '@/components/PageHeader.vue'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import { Actions, Actions as GlobalActions } from '@/store/global/actions'
import http from '@/http'
import ArchivedFundingRequestModel, {
  ArchivedFundingRequestAttribute,
} from '@/modules/archived-funding-requests/archived-funding-request.model'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'

export enum EFundingRequestType {
  private = 'Fizinė',
  business = 'Verslo',
}

@Component({
  components: {
    AdminLayout,
    PageHeader,
    DataTable,
  },
})
export default class RegularArchivedFundingRequestList extends Vue {
  table = new DataTableBase()
    .setModel(ArchivedFundingRequestModel)
    .setEndpoint('funding-requests/archive')
    .setHeaders([
      new DataTableHeader()
        .setValue(ArchivedFundingRequestAttribute.funding_request_number)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(false),
      new DataTableHeader()
        .setValue(ArchivedFundingRequestAttribute.name)
        .setText('Vardas / Įmonės pavadinimas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(ArchivedFundingRequestAttribute.amount)
        .setText('Paskolos suma')
        .setValueParser((value) => `${value} €`)
        .setSortable(false),
      new DataTableHeader().setValue(ArchivedFundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
      new DataTableHeader()
        .setValue(`${ArchivedFundingRequestAttribute.user}.email`)
        .setText('El. paštas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${ArchivedFundingRequestAttribute.user}.phone`)
        .setText('Tel. nr.')
        .setValueParser((value) => `+370${value}`)
        .setSortable(false),
      new DataTableHeader().setValue(ArchivedFundingRequestAttribute.date).setText('Pateikimo data').setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setTitle('Informacija')
        .setPermissions([permissions.fundingRequests.show])
        .setAction(this.goToInformation),
      new DataTableAction()
        .setPermissions([permissions.verifications.download])
        .setIcon('file_download')
        .setTitle('Atsisiųsti dokumentą')
        .setAction(this.downloadVerificationDocument),
      new DataTableAction()
        .setIcon('info')
        .setTitle('Atstatyti paraišką')
        .setPermissions([permissions.fundingRequests.unarchive])
        .setAction(this.onUnarchive),
    ])

  downloadVerificationDocument(item: ArchivedFundingRequestModel): void {
    if (item[ArchivedFundingRequestAttribute.signed_document]) {
      window.location.replace(item[ArchivedFundingRequestAttribute.signed_document].url)
    }
  }

  onUnarchive(item: ArchivedFundingRequestModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite atstatyti paraišką?',
        text: `Paspausdami taip mygtuką jūs atstatysite finansavimo paraišką.`,
        onYes: () => this.unarchiveFundingRequest(item),
      },
    })
  }

  goToInformation(item: ArchivedFundingRequestModel): void {
    this.$router.push({
      name:
        item.type === EFundingRequestType.private
          ? AdminRoutes.fundingRequestsShow
          : AdminRoutes.businessFundingRequestsShow,
      params: {
        uuid: item[ArchivedFundingRequestAttribute.uuid],
      },
    })
  }
  unarchiveFundingRequest(item: ArchivedFundingRequestModel): void {
    http.put(`funding-requests/${item[ArchivedFundingRequestAttribute.uuid]}/revoke-from-archive`).then(() => {
      // @ts-ignore
      this.$refs.archivedFundingRequestsTable.filter()
      this.$store.dispatch(GlobalActions.showSnackbar, {
        type: 'success',
        message: 'Paraiška sėkmingai atkurta.',
      })
    })
  }
}
