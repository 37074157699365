


















import { Component, Vue } from 'vue-property-decorator'
import IDashboardResponse, { IOfferAcceptedCount } from '@/interfaces/dashboard-response.interface'
import http from '@/http'
import { getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import { AxiosResponse } from 'axios'
import DatePickerField from '@/components/Form/FieldTypes/DatePickerField.vue'
import Field from '@/classes/Form/Field'
import moment from 'moment'
import FundingRequestChart from '@/components/Dashboard/FundingRequestChart.vue'

@Component({
  components: {
    DatePickerField,
    FundingRequestChart,
  },
  methods: {
    getFundingRequestStatusTitle,
  },
})
export default class FundingRequestsChart extends Vue {
  offerAcceptedCount: IOfferAcceptedCount[] | null = null

  field: Field = new Field()
    .setKey('dates')
    .setTitle('Laikotarpis')
    .setHideDetails(true)
    .setDense(true)
    .setValue([moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    .setOnChange(this.callEndpoint)

  created(): void {
    this.callEndpoint()
  }

  callEndpoint(): Promise<void | AxiosResponse<AxiosResponse<IDashboardResponse>>> {
    return http
      .post(`/dashboard?${this.field.key}=${this.field.value}`)
      .then((response: AxiosResponse<IDashboardResponse>) => {
        this.offerAcceptedCount = response.data.offer_accepted_count
      })
  }
}
