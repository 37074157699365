import { Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import _ from 'lodash'

export default class AbstractHeader extends Vue {
  @Prop() table!: DataTableBase
  @Prop() header!: DataTableHeader
  @Prop() props!: any

  get value(): any {
    return _.get(this.props.item, this.header.key ? this.header.key : this.header.value)
  }
}
