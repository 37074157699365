

























import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/layouts/AdminLayout.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import PageHeader from '@/components/PageHeader.vue'
import FundingRequestModel, { FundingRequestAttribute } from '@/modules/funding-request/funding-request.model'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import {
  fundingRequestBusinessTypes,
  fundingRequestStatuses,
  getFundingRequestStatusTitle,
} from '@/helpers/fundingRequest'
import DataTableFilter, { FilterOperators } from '@/classes/DataTable/DataTableFilter'
import SelectField from '@/classes/Form/Fields/Select/SelectField'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { Actions } from '@/store/global/actions'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import http from '@/http'
import permissions from '@/helpers/permissions'
import { Getters } from '@/store/auth/getters'
import IUserResponse from '@/modules/user/user-response.interface'
import FundingRequestData from '@/components/DataTable/FundingRequestData.vue'
import { AxiosPromise } from 'axios'

@Component({
  components: {
    AdminLayout,
    PageHeader,
    DataTable,
  },
})
export default class BusinessFundingRequestsList extends Vue {
  selectedItems: any[] = []
  user: UserModel | null = this.$store.getters[Getters.getUser]
  selectedItemsCount: number = 0
  table = new DataTableBase()
    .setModel(FundingRequestModel)
    .setEndpoint('business-funding-requests')
    .setHeaders([
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.confirmed_at}`)
        .setText('Pateikimo data')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestAttribute.data}.company_name`)
        .setText('Įmonės pavadinimas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.amount)
        .setText('Paskolos suma')
        .setValueParser((value) => `${value} €`)
        .setSortable(false),
      new DataTableHeader().setValue(FundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.status)
        .setText('Statusas')
        .setValueParser(getFundingRequestStatusTitle),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.funding_type_name)
        .setKey(FundingRequestAttribute.funding_type)
        .setText('Tipas')
        .setValueParser((value) => value.name),
      new DataTableHeader()
        .setValue(FundingRequestAttribute.assigned_users)
        .setText('Priskirti darbuotojai')
        .setValueParser((value) => this.parseUsers(value))
        .setSortable(false),
    ])
    .setFilters([
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setField(
          new SelectField()
            .isMultiple()
            .setKey(FundingRequestAttribute.status)
            .setTitle('Statusas')
            .setItems(fundingRequestStatuses),
        ),
      new DataTableFilter()
        .setOperator(FilterOperators.in)
        .setField(
          new SelectField()
            .isMultiple()
            .setKey(FundingRequestAttribute.filterTypeKey)
            .setTitle('Tipas')
            .setItems(fundingRequestBusinessTypes),
        ),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('info')
        .setTitle('Informacija')
        .setPermissions([permissions.fundingRequests.show])
        .setAction(this.goToInformation),
      new DataTableAction()
        .setIcon('cancel')
        .setTitle('Ištrinti')
        .setPermissions([permissions.fundingRequests.delete])
        .setAction(this.onDelete),
      new DataTableAction()
        .setIcon('mdi-file-move')
        .setTitle('Archyvuoti')
        .setPermissions([permissions.businessFundingRequests.archive])
        .setAction(this.onArchive),
    ])
    .setIsExpandable(true)
    .setExpansionComponent(FundingRequestData)
    .setExpansionDataCallback((item) => this.fetchExpandedData(item))

  created(): void {
    if (this.user && !this.user[UserAttribute.isAdmin]) {
      this.table.setIsSelectable().setSelectBy('uuid')
    }

    if (this.user && this.user[UserAttribute.isAdmin]) {
      this.table.setHeaders([
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.fundingRequestNumber}`)
          .setValueParser((value) => `#${value}`)
          .setText('Nr.')
          .setSortable(true),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.confirmed_at}`)
          .setText('Pateikimo data')
          .setSortable(false),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.data}.company_name`)
          .setText('Įmonės pavadinimas')
          .setSortable(false),
        new DataTableHeader().setValue(`${FundingRequestAttribute.userEmail}`).setText('El. paštas').setSortable(false),
        new DataTableHeader()
          .setValue(`${FundingRequestAttribute.userPhone}`)
          .setValueParser((value) => `+370${value}`)
          .setText('Tel. nr.')
          .setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.amount)
          .setText('Paskolos suma')
          .setValueParser((value) => `${value} €`)
          .setSortable(false),
        new DataTableHeader().setValue(FundingRequestAttribute.periodData).setText('Periodas').setSortable(false),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.status)
          .setText('Statusas')
          .setValueParser(getFundingRequestStatusTitle),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.funding_type_name)
          .setKey(FundingRequestAttribute.funding_type)
          .setText('Tipas')
          .setValueParser((value) => value.name),
        // (new DataTableHeader()).setValue(`${FundingRequestAttribute.user}.${UserAttribute.hasVerifiedEmail}`).setText('El-paštas patvirtintas').setType(DataTableHeaderTypes.boolean),
        new DataTableHeader()
          .setValue(FundingRequestAttribute.assigned_users)
          .setText('Priskirti darbuotojai')
          .setValueParser((value) => this.parseUsers(value))
          .setSortable(false),
      ])
    }
  }

  goToInformation(item: FundingRequestModel): void {
    this.$router.push({
      name: AdminRoutes.businessFundingRequestsShow,
      params: {
        uuid: item.uuid,
      },
    })
  }

  onArchive(item: FundingRequestModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite archyvuoti užklausą?',
        text: `Paspaudus taip mygtuką pasirinkta paraiška pateks į archyvuotų paraiškų sąrašą.`,
        onYes: () => this.archiveFundingRequest(item),
      },
    })
  }

  archiveFundingRequest(item: FundingRequestModel): void {
    http.post(`funding-requests/${item.uuid}/archive`).then(async () => {
      // @ts-ignore
      this.$refs.FundingRequestDataTable.filter()
      await this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: `Paraiška sėkmingai suarchyvuota`,
      })
    })
  }

  onDelete(item: FundingRequestModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite ištrinti užklausą?',
        text: `Paspausdami taip mygtuką jūs ištrinsite finansavimo užklausą ir šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: FundingRequestModel): Promise<void> {
    return http
      .delete(`${this.table.endpoint}/${item.uuid}`)
      .then(() => this.table.removeItem(item))
      .catch(() => this.$store.dispatch(Actions.closeDialog))
  }

  handleSelectedItems(items: any[]): void {
    this.selectedItemsCount = items.length
    this.selectedItems = items.map((item) => item.uuid)
  }

  onAssignItems(): void {
    if (this.user) {
      this.$store.dispatch(Actions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: 'Ar tikrai norite prisiskirti šias paraiškas?',
          text: `Paraiškos jau yra priskirtos darbuotojui ${this.user.fullName}`,
          onYes: () => this.assignItems(),
        },
      })
    }
  }

  assignItems(): void {
    if (this.user) {
      this.table.loading = true
      http.post('funding-requests/assign', this.selectedItems).then(async () => {
        // @ts-ignore
        await this.$refs.FundingRequestDataTable.filter() // refresh table data
        await this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: `Paraiškos sėkmingai priskirtos! Priskirtų paraiškų kiekis: ${this.selectedItemsCount}`,
        })
        await this.unselectAll()
      })
    }
  }

  unselectAll(): void {
    this.selectedItems = []
    this.selectedItemsCount = 0
    // @ts-ignore
    this.$refs.colleaguesDataTable.clearSelectedItems()
  }

  parseUsers(items: IUserResponse[]): string {
    let parsedItems = ''
    items.forEach((item: IUserResponse, index: number) => {
      if (items.length === 1) {
        parsedItems += item.full_name

        return
      }

      if (index > 0) {
        parsedItems += item.full_name
      } else {
        parsedItems = `${parsedItems + item.full_name}, `
      }
    })

    return parsedItems
  }

  fetchExpandedData(item: FundingRequestModel): AxiosPromise {
    return http.get(`/funding-requests/${item.uuid}/get-financiers-data`)
  }
}
