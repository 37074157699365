































































import { Component, Inject, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import can from '@/helpers/can'
import Tabs from '@/components/Tabs.vue'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import ISodraFinancesInterface from '@/modules/sodra/sodra-finances.interface'
import SodraFinancesModel from '@/modules/sodra/sodra-finances.model'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class Finances extends Vue {
  @Inject('fundingRequest') getFundingRequest!: () => FundingRequestModel
  sodraFinances: SodraFinancesModel[] = []
  spouseSodraFinances: SodraFinancesModel[] = []

  created(): void {
    const fundingRequest = this.getFundingRequest()

    const formattedSodraFinances: any = []

    const sodraFinances = fundingRequest.sodra_data?.data?.Pajamos

    if (sodraFinances) {
      Object.keys(sodraFinances).forEach((key) => {
        sodraFinances[key].forEach((financesKey: ISodraFinancesInterface) => {
          formattedSodraFinances.push(new SodraFinancesModel().transform(financesKey))
        })
      })
      this.sodraFinances = formattedSodraFinances.sort(this.sortByDate)
    }

    const spouseSodraFinances = fundingRequest.spouse_sodra_data?.data?.Pajamos

    if (!spouseSodraFinances) return

    const formattedSpouseSodraFinances: any = []
    Object.keys(spouseSodraFinances).forEach((key) => {
      spouseSodraFinances[key].forEach((financesKey: ISodraFinancesInterface) => {
        formattedSpouseSodraFinances.push(new SodraFinancesModel().transform(financesKey))
      })
    })
    this.spouseSodraFinances = formattedSodraFinances.sort(this.sortByDate)
  }
  sortByDate(a, b): number {
    const aTimestamp = new Date(a.from.date).getTime() / 1000

    const bTimestamp = new Date(b.from.date).getTime() / 1000

    if (aTimestamp < bTimestamp) {
      return 1
    }

    if (aTimestamp > bTimestamp) {
      return -1
    }

    return 0
  }
}
