import Model from '@/classes/Model'
import IFundingRequestFinancierInfoResponse from '@/modules/funding-request-financier-info/funding-request-financier-info-response.interface'
import AdditionalDocumentModel from '@/modules/additional-documents/additional-document.model'
import FinancierModel from '@/modules/financier/financier.model'

export enum FundingRequestFinancierInfoAttribute {
  additionalDocumentUuid = 'additional_document_uuid',

  attachments = 'attachments',
  question = 'question',
  answer = 'answer',
  comment = 'comment',
  remarks = 'remarks',
  isProvided = 'is_provided',

  additionalDocument = 'additional_document',
  financier = 'financier',
}

export default class FundingRequestFinancierInfoModel extends Model {
  [FundingRequestFinancierInfoAttribute.additionalDocumentUuid]!: string;
  [FundingRequestFinancierInfoAttribute.attachments]!: any;
  [FundingRequestFinancierInfoAttribute.question]!: string;
  [FundingRequestFinancierInfoAttribute.answer]!: string;
  [FundingRequestFinancierInfoAttribute.comment]!: string;
  [FundingRequestFinancierInfoAttribute.remarks]!: string;
  [FundingRequestFinancierInfoAttribute.isProvided]!: boolean;

  [FundingRequestFinancierInfoAttribute.additionalDocument]!: AdditionalDocumentModel | null;
  [FundingRequestFinancierInfoAttribute.financier]!: FinancierModel | null

  transform(data: IFundingRequestFinancierInfoResponse): FundingRequestFinancierInfoModel {
    super.transform(data)

    this.question = data.question
    this.answer = data.answer
    this.remarks = data.remarks
    this.comment = data.comment

    this.additional_document_uuid = data.additional_document_uuid
    this.attachments = data.attachments
    this.is_provided = data.is_provided

    this.additional_document = data.additional_document
      ? new AdditionalDocumentModel().transform(data.additional_document)
      : null
    this.financier = data.financier ? new FinancierModel().transform(data.financier) : null

    return this
  }
}
