






import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import _get from '@/helpers/_get'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import Tabs from '@/components/Tabs.vue'
import ActivityLogModel, { ActivityLogAttribute } from '@/modules/activity-log/activity-log.model'
import { FinancierAttribute } from '@/modules/financier/financier.model'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class ActivityLog extends Vue {
  user: UserModel = this.$store.getters[Getters.getUser]
  permissions = permissions
  table: DataTableBase = new DataTableBase()
    .setModel(ActivityLogModel)
    .setEndpoint(`activity-logs/${this.$router.currentRoute.params.uuid}?sort_by=created_at&order=desc`)
    .setHeaders([
      new DataTableHeader().setValue(ActivityLogAttribute.type).setText('Tipas').setSortable(false),
      new DataTableHeader()
        .setValue(`${ActivityLogAttribute.loggable}.funding_request_number`)
        .setValueParser((value) => `#${value}`)
        .setText('Nr.')
        .setSortable(false),
      new DataTableHeader().setValue(ActivityLogAttribute.activity_text).setText('Veiksmas').setSortable(false),
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setMeta({ width: '40px', height: '40px' })
        .setValue(`${ActivityLogAttribute.user}.${UserAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Finansuotojas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${ActivityLogAttribute.user}.${UserAttribute.fullName}`)
        .setText('Darbuotojas')
        .setSortable(false),
      new DataTableHeader().setValue(`${ActivityLogAttribute.created_at}`).setText('Data').setSortable(false),
    ])
}
