import SelectItem from '@/classes/Form/Fields/Select/SelectItem'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'

enum FinancierActivenessStatuses {
  active = 'active',
  disabled = 'disabled',
}

export const fundingRequestIndividualTypes: SelectItem[] = [
  new SelectItem().setValue('individual_consumer_credit').setTitle('Vartojimo paskola'),
  new SelectItem().setValue('individual_transport_loan').setTitle('Automobiliui įsigyti'),
  new SelectItem().setValue('individual_home_loan').setTitle('Būsto paskola'),
  new SelectItem()
    .setValue('individual_loan_with_real_estate_exchange')
    .setTitle('Paskola su nekilnojamojo turto įkeitimu'),
  new SelectItem().setValue('individual_self_employment').setTitle('Individualiai veiklai'),
  new SelectItem().setValue('individual_working_abroad').setTitle('Užsienyje dirbantiems lietuviams'),
  new SelectItem().setValue('individual_refinancing').setTitle('Refinansavimas'),
]

export const fundingRequestBusinessTypes: SelectItem[] = [
  new SelectItem().setValue('business_working_capital').setTitle('Apyvartinėms lėšoms'),
  new SelectItem().setValue('business_leasing').setTitle('Investicinė paskola'),
  new SelectItem().setValue('business_transport_loan').setTitle('Transporto priemonės veiklos nuoma ir lizingas'),
  new SelectItem().setValue('business_refinancing').setTitle('Refinansavimas'),
  new SelectItem().setValue('business_real_estate').setTitle('Nekilnojamojo turto pirkimas'),
  new SelectItem().setValue('business_real_estate_financing').setTitle('Nekilnojamojo turto projektų finansavimas'),
  new SelectItem().setValue('business_factoring').setTitle('Faktoringas'),
]

export const generalStatuses: SelectItem[] = [
  new SelectItem().setValue('draft').setTitle(FundingRequestModel.STATUS_DRAFT),
  new SelectItem().setValue('waiting-approval').setTitle(FundingRequestModel.STATUS_WAITING_APPROVAL),
  new SelectItem().setValue('waiting-spouse-approval').setTitle(FundingRequestModel.STATUS_WAITING_SPOUSE_APPROVAL),
  new SelectItem().setValue('missing-data').setTitle(FundingRequestModel.STATUS_MISSING_DATA),
  new SelectItem().setValue('confirmed').setTitle(FundingRequestModel.STATUS_CONFIRMED),
  new SelectItem().setValue('financier-chosen').setTitle(FundingRequestModel.STATUS_FINANCIER_CHOSEN),
  new SelectItem().setValue('complete').setTitle(FundingRequestModel.STATUS_COMPLETE),
  new SelectItem().setValue('declined').setTitle(FundingRequestModel.STATUS_DECLINED),
]

export const workExperienceValues: SelectItem[] = [
  new SelectItem().setValue('zero-to-three-month').setTitle('0 - 3 mėn'),
  new SelectItem().setValue('three-to-six-month').setTitle('3 - 6 mėn'),
  new SelectItem().setValue('six-to-twelve-month').setTitle('6 - 12 mėn'),
  new SelectItem().setValue('one-to-two-year').setTitle('1 - 2 metų'),
  new SelectItem().setValue('two-to-three-year').setTitle('2 - 3 metai'),
  new SelectItem().setValue('three-to-four-year').setTitle('3 - 4 metai'),
  new SelectItem().setValue('four-to-five-year').setTitle('4 - 5 metai'),
  new SelectItem().setValue('over-five-year').setTitle('virš 5 metų'),
]

export const fundingRequestStatuses: SelectItem[] = [
  new SelectItem().setValue('draft').setTitle(FundingRequestModel.STATUS_DRAFT),
  new SelectItem().setValue('waiting-approval').setTitle(FundingRequestModel.STATUS_WAITING_APPROVAL),
  new SelectItem().setValue('waiting-spouse-approval').setTitle(FundingRequestModel.STATUS_WAITING_SPOUSE_APPROVAL),
  new SelectItem().setValue('missing-data').setTitle(FundingRequestModel.STATUS_MISSING_DATA),
  new SelectItem().setValue('confirmed').setTitle(FundingRequestModel.STATUS_CONFIRMED),
]

export const offerStatuses: SelectItem[] = [
  new SelectItem().setValue('financier-chosen').setTitle(FundingRequestModel.STATUS_FINANCIER_CHOSEN),
  new SelectItem().setValue('complete').setTitle(FundingRequestModel.STATUS_COMPLETE),
]

export const verificationTypes: { key: any; value: any }[] = [
  { key: 'smart_id', value: 'Smart ID' },
  { key: 'mobile_id', value: 'Mobilus parašas' },
  { key: 'remote', value: 'Nuotolinė identificakija' },
  { key: 'paysera', value: '1ct. pavedimas' },
]

export const getVerificationTypes = (type: string | null): string => {
  const found = verificationTypes.find((item: { key: any; value: any }) => item.key === type)

  if (found) return found.value

  return 'Nėra'
}

export const getFinanciersStatus = (status: string): string => {
  switch (status) {
    case FinancierActivenessStatuses.active:
      return 'Aktyvus'
    case FinancierActivenessStatuses.disabled:
      return 'Neaktyvus'
    default:
      return 'Statusas nežinomas'
  }
}

export const getFundingRequestStatusTitle = (status: string): string => {
  const found = generalStatuses.find((item: SelectItem) => item.value === status)

  if (found) return found.title

  return '-'
}

export const getCorrectPhoneFormat = (phone: string): string => {
  return phone.startsWith('+370') ? phone.slice(4) : phone
}

export const businessRequiredFiles = {
  company_person_identity_card: {
    label: 'Asmens tapatybės kortelė arba Pasas įmonės vadovo/akcininko :',
    key: 'company_person_identity_card',
  },
  company_balance_3m: {
    label: 'Balansas už paskutinius 3 mėn.:',
    key: 'company_balance_3m',
  },
  company_revenue_report_3m: {
    label: 'Įmonės pelno/nuostolio ataskaita už paskutinius 3 mėn.:',
    key: 'company_revenue_report_3m',
  },
  company_balance_2019: {
    label: 'Balansas už 2020 metus (išplėstinis):',
    key: 'company_balance_3m',
  },
  company_revenue_report_2019: {
    label: 'Įmonės pelno/nuostolio ataskaita už 2020 metus (išplėstinis):',
    key: 'bank_excerpt_2019',
  },
  yearly_balance: {
    label: 'Įmonės pelno/nuostolio ir balanso ataskaitos už 2021 metus:',
    key: 'yearly_balance',
  },
  three_month_balance: {
    label: 'Įmonės pelno/nuostolio ir balanso ataskaitos už paskutinius 3 mėn:',
    key: 'three_month_balance',
  },
}
