import { IDialog, ISnackbar, IState, NavigationState } from '@/store/global/helpers'
import { Mutations } from '@/store/global/mutations'
import { ActionContext } from 'vuex'

export enum Actions {
  showSnackbar = 'GLOBAL/SHOW_SNACKBAR',
  toggleNavigation = 'GLOBAL/TOGGLE_NAVIGATION',
  setNavigation = 'GLOBAL/SET_NAVIGATION',
  showDialog = 'GLOBAL/SHOW_DIALOG',
  closeDialog = 'GLOBAL/CLOSE_DIALOG',
  showLoading = 'GLOBAL/SHOW_LOADING',
}

export default {
  [Actions.showSnackbar]: ({ commit }: ActionContext<IState, any>, snackbar: ISnackbar | null): void => {
    commit(Mutations.setSnackbar, snackbar)
  },
  [Actions.toggleNavigation]: ({ commit, state }: ActionContext<IState, any>): void => {
    let next = NavigationState.minimized

    if (state.navigation === NavigationState.minimized) {
      next = NavigationState.default
    }

    commit(Mutations.setNavigation, next)
  },
  [Actions.setNavigation]: ({ commit }: any, payload: NavigationState): void =>
    commit(Mutations.setNavigation, payload),
  [Actions.showDialog]: ({ commit }: any, payload: IDialog): void => commit(Mutations.setDialog, payload),
  [Actions.closeDialog]: ({ commit }: any): void => commit(Mutations.resetDialog),
  [Actions.showLoading]: ({ commit }: any, payload: boolean): void => commit(Mutations.showLoading, payload),
}
