











































































































































































































































import { Component, Provide, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import http from '@/http'
import _get from '@/helpers/_get'
import FundingRequestModel, {
  FundingRequestAttribute,
  FundingRequestBusinessModel,
} from '@/modules/funding-request/funding-request.model'
import DataTable from '@/components/DataTable/DataTable.vue'
import FundingRequestFieldModel, {
  FundingRequestFieldAttribute,
} from '@/modules/funding-request-field/funding-request-field.model'
import IFundingRequestResponse from '@/modules/funding-request/funding-request-response.interface'
import IFundingRequestFieldResponse from '@/modules/funding-request-field/funding-request-field-response.interface'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { AxiosError, AxiosResponse } from 'axios'
import { Actions } from '@/store/global/actions'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import _ from 'lodash'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { getFundingRequestStatusTitle, businessRequiredFiles } from '@/helpers/fundingRequest'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import { IDialog } from '@/store/global/helpers'
import OverwriteOfferDialog from '@/components/Offers/OverwriteOfferDialog.vue'
import { getOfferStatuses } from '@/helpers/offers'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { Getters } from '@/store/auth/getters'
import Tabs from '@/components/Tabs.vue'
import ITabs from '@/interfaces/tabs'
import { IAttachment } from '@/interfaces/attachments'

@Component({
  components: {
    PageHeader,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class BusinessSelectedOffersShow extends Vue {
  permissions = permissions
  fundingRequest: FundingRequestBusinessModel | null = null
  fundingRequestFields: FundingRequestFieldModel[] | null = null
  fundingRequestState: boolean | false = false
  user: UserModel = this.$store.getters[Getters.getUser]
  businessRequiredFiles = businessRequiredFiles

  tabs: ITabs[] = [
    {
      to: AdminRoutes.selectedBusinessOffersShowTabOffers,
      title: 'Pasiūlymai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedBusinessOffersShowTabDocuments,
      title: 'Papildomi dokumentai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedBusinessOffersShowTabVerifications,
      title: 'Tapatybės patvirtinimai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedBusinessOffersShowTabActivityLog,
      title: 'Paraiškos veiksmų istorija',
      permissions: [permissions.fundingRequests.show],
    },
  ]
  @Provide('fundingRequest') getFundingRequest(): FundingRequestBusinessModel | null {
    return this.fundingRequest
  }

  created(): void {
    this.loadFundingRequest()
  }

  get businessRequiredAttachments(): any[] {
    return _.filter(this.fundingRequest?.attachments, (item: any) =>
      Object.keys(businessRequiredFiles).includes(item.group),
    )
  }

  downloadLoanDocument(): void {
    if (this.fundingRequest && this.fundingRequest.uuid) {
      http(`funding-requests/${this.fundingRequest.uuid}/get-signed-document`, {
        responseType: 'blob',
        method: 'GET',
      })
        .then(async (response) => {
          const fileName = response.headers['content-disposition'].split('filename=')[1]

          const blobUrl = URL.createObjectURL(response.data)

          const link = document.createElement('a')

          link.setAttribute('href', blobUrl)
          link.setAttribute('download', fileName)
          link.click()
        })
        .catch(async (error: AxiosError) => {
          if (error.response) {
            await this.$store.dispatch(Actions.showSnackbar, {
              type: 'error',
              message: error.response.data.message,
            })
          }
        })
    }
  }

  get recommendedDocuments(): any[] {
    return _.filter(this.fundingRequest?.attachments, (item: any) =>
      ['funding_request', 'guarantee', 'user_funding_request_documents'].includes(item.group),
    )
  }

  getAttachmentsByIndex(key: string): IAttachment[] | undefined {
    if (this.fundingRequest && this.fundingRequest[FundingRequestAttribute.attachments]) {
      const attachments: IAttachment[] = this.fundingRequest[FundingRequestAttribute.attachments]

      return attachments.filter((attachment: IAttachment) => {
        return attachment.group === key
      })
    }

    return undefined
  }

  isSignedDocument(itemGroup): boolean {
    return ['user-signed-documents', 'old-user-signed-documents'].includes(itemGroup)
  }

  hasSignedDocuments(): any {
    if (this.fundingRequest?.offers) {
      const signedDocuments: any = []

      this.fundingRequest?.offers.forEach((item: any) => {
        if (
          item.user_signed_documents.find((documentItem) =>
            ['user-signed-documents', 'old-user-signed-documents'].includes(documentItem.group),
          )
        ) {
          signedDocuments.push(item)
        }
      })

      return signedDocuments.length > 0
    }

    return false
  }

  loadFundingRequest(): void {
    http.get(`/business-funding-requests/${this.$router.currentRoute.params.uuid}`).then(
      (
        response: AxiosResponse<{
          funding_request: IFundingRequestResponse
          funding_request_fields: IFundingRequestFieldResponse[]
        }>,
      ) => {
        this.fundingRequestFields = response.data.funding_request_fields.map((item: IFundingRequestFieldResponse) =>
          new FundingRequestFieldModel().transform(item),
        )
        this.fundingRequest = new FundingRequestModel().transform(response.data.funding_request)
        this.fundingRequestState =
          getFundingRequestStatusTitle(response.data.funding_request.status) === FundingRequestModel.STATUS_COMPLETE
      },
    )
  }

  triggerDeclineDialog(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite atsisakyti sudaryti sutartį?',
        text: `Veiksmas negalės būti atšauktas.`,
        onYes: () => this.declineContract(),
      },
    })
  }

  overwriteOffer(): void {
    if (this.fundingRequest && this.fundingRequest.selectedOffer) {
      this.$store.dispatch(Actions.showDialog, {
        show: true,
        component: OverwriteOfferDialog,
        maxWidth: 650,
        meta: {
          entry: this.fundingRequest.selectedOffer,
          fundingRequestUuid: this.fundingRequest && this.fundingRequest.uuid,
          onSuccess: () => this.loadFundingRequest(),
        },
      } as IDialog)
    }
  }

  parseOfferStatus(status: string): string {
    return getOfferStatuses(status)
  }

  triggerDialog(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai sudarėte sutartį?',
        text: `Paspausdami taip mygtuką jūs pažymėsite, jog sutartis sudaryta, veiksmas negalės būti atšauktas.`,
        onYes: () => this.complete(),
      },
    })
  }

  complete(): void {
    const uuid = this.fundingRequest && this.fundingRequest.uuid
    http.post(`/funding-requests/${uuid}/complete`).then(() => {
      this.fundingRequestState = true
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Sutartis sėkmingai sudaryta!',
      })
    })
  }

  declineContract(): void {
    const uuid = this.fundingRequest && this.fundingRequest.uuid
    http.post(`/funding-requests/${uuid}/decline`).then(() => {
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Sutartis atšaukta!',
      })
    })
  }

  getValue(data: object, field: FundingRequestFieldModel): string | false {
    if (field.type === FieldTypes.select) {
      const found = field.options.find((option: any) => option.value === _.get(data, field.key))

      return found ? found.label : _.get(data, field.key, (field[FundingRequestFieldAttribute.show] = false))
    }

    if (field.type === 'json') {
      return (field[FundingRequestFieldAttribute.show] = false)
    }

    if (field.type === FieldTypes.checkbox) {
      return _.get(data, field.key) ? 'Taip' : 'Ne'
    }

    if (
      ['company_person_email', 'company_person_phone', 'company_phone', 'company_email'].includes(field.key) &&
      this.fundingRequest &&
      !this.fundingRequest.hasDynamicPermissions([permissions.fundingRequests.complete]) &&
      !this.user[UserAttribute.isAdmin]
    ) {
      return '*******'
    }

    if (field.type === FieldTypes.money) {
      return `${_.get(data, field.key)}€`
    }

    if (field.type === FieldTypes.months) {
      return `${_.get(data, field.key)} ${this.fundingRequest ? this.fundingRequest.period_measure : ''}`
    }

    return _.get(data, field.key) || (field[FundingRequestFieldAttribute.show] = false)
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.selectedBusinessOffersIndex })
  }

  get isSelectedFinancierOrAdmin(): boolean {
    if (this.user.isAdmin) return true

    return this.fundingRequest
      ? this.fundingRequest.selectedOffer.financier_uuid === this.user[UserAttribute.financier].uuid
      : true
  }
}
