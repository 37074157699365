import Model from '@/classes/Model'
import IUserResponse from '@/modules/user/user-response.interface'
import FinancierModel from '@/modules/financier/financier.model'
import RoleModel from '@/modules/role/role.model'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'

export enum UserAttribute {
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  phone = 'phone',
  email = 'email',
  roleUuid = 'roleUuid',
  role = 'role',
  financier = 'financier',
  financier_uuid = 'financier_uuid',
  permissions = 'permissions',
  avatar = 'avatar',
  isAdmin = 'isAdmin',
  funding_requests = 'funding_requests',
  hasVerifiedEmail = 'hasVerifiedEmail',
}

export default class UserModel extends Model {
  [UserAttribute.firstName]!: string;
  [UserAttribute.lastName]!: string;
  [UserAttribute.fullName]!: string;
  [UserAttribute.phone]!: string;
  [UserAttribute.email]!: string;
  [UserAttribute.permissions]!: string[];
  [UserAttribute.avatar]!: string;
  [UserAttribute.isAdmin]!: boolean;

  [UserAttribute.roleUuid]!: string;
  [UserAttribute.financier_uuid]!: string;
  [UserAttribute.role]!: RoleModel;
  [UserAttribute.financier]!: FinancierModel;
  [UserAttribute.hasVerifiedEmail]!: boolean;
  [UserAttribute.funding_requests]!: FundingRequestModel[]

  transform(data: IUserResponse): UserModel {
    super.transform(data)

    this.firstName = data.first_name
    this.lastName = data.last_name
    this.fullName = data.full_name
    this.phone = data.phone
    this.email = data.email
    this.permissions = data.permissions
    this.avatar = data.avatar
    this.isAdmin = data.is_admin
    this.funding_requests = data.funding_requests

    this.roleUuid = data.role_uuid
    this.financier_uuid = data.financier_uuid
    this.hasVerifiedEmail = data.has_verified_email
    this.role = data.role && new RoleModel().transform(data.role)
    this.financier = data.financier && new FinancierModel().transform(data.financier)

    return this
  }
}
