








import AbstractField from '@/classes/Form/Fields/AbstractField'
import ITemplateDocument from '@/modules/attachment/template-document.interface'

export default class FileViewField extends AbstractField {
  downloadDocument(file: ITemplateDocument): void {
    const link = document.createElement('a')

    link.setAttribute('href', file.url)
    link.setAttribute('download', file.filename)
    link.click()
  }
}
