
import { Bar } from 'vue-chartjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOfferAcceptedCount } from '@/interfaces/dashboard-response.interface'

@Component({
  extends: Bar,
})
export default class FundingRequestChart extends Vue {
  @Prop() data!: any

  mounted(): void {
    // @ts-ignore
    this.renderChart(this.getData(), this.getOptions())
    this.$watch('data', () => {
      // @ts-ignore
      this.renderChart(this.getData(), this.getOptions())
    })
  }

  getData(): object {
    return {
      labels: this.data && this.data.map((item: IOfferAcceptedCount) => item.title),
      datasets: [
        {
          height: 250,
          label: true,
          backgroundColor: '#1976d2',
          data: this.data && this.data.map((item: IOfferAcceptedCount) => item.count),
        },
      ],
    }
  }

  getOptions(): object {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: any) => tooltipItem.yLabel,
        },
      },
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
        // xAxes: [
        //   {
        //     gridLines: {
        //       display: false
        //     }
        //   }
        // ]
      },
    }
  }
}
