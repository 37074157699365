var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('PageHeader',{attrs:{"title":"Pajamos"}}),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":[
      { text: 'Rūšis', value: 'kind', sortable: false },
      { text: 'Pavadinimas', value: 'kind_description', sortable: false },
      { text: 'Tipas', value: 'type' },
      { text: 'Aprašymas', value: 'type_description', sortable: false },
      { text: 'Suma', value: 'amount' },
      { text: 'Nuo', value: 'from', sortable: false },
      { text: 'Iki', value: 'to', sortable: false } ],"items":_vm.sodraFinances,"item-key":"uuid","show-select":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount + ' ' + item.currency))])]}},{key:"item.from",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.from.date).toLocaleDateString())+" ")]}},{key:"item.to",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.to.date).toLocaleDateString())+" ")]}}])}),(_vm.spouseSodraFinances.length)?_c('div',{staticClass:"mt-15"},[_c('PageHeader',{attrs:{"title":"Sutuoktinio pajamos"}}),_c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":[
        { text: 'Rūšis', value: 'kind', sortable: false },
        { text: 'Pavadinimas', value: 'kind_description', sortable: false },
        { text: 'Tipas', value: 'type' },
        { text: 'Aprašymas', value: 'type_description', sortable: false },
        { text: 'Suma', value: 'amount' },
        { text: 'Nuo', value: 'from', sortable: false },
        { text: 'Iki', value: 'to', sortable: false } ],"items":_vm.spouseSodraFinances,"item-key":"uuid","show-select":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.amount + ' ' + item.currency))])]}},{key:"item.from",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.from.date).toLocaleDateString())+" ")]}},{key:"item.to",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.to.date).toLocaleDateString())+" ")]}}],null,false,1310302955)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }