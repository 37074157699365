


























import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'
import _ from 'lodash'

@Component
export default class DatePickerField extends AbstractField {
  @Prop() classes!: string[]
  menuItem: boolean = false
  computedClasses: string = ''

  created(): void {
    this.computedClasses = this.classes && _.cloneDeep(this.classes).join(' ')
  }

  get menu(): boolean {
    return this.menuItem
  }

  set menu(value: boolean) {
    this.menuItem = value
  }

  onChange(): void {
    if (this.value.length > 1) {
      this.menu = false

      if (this.field.onChange) this.field.onChange()
    }
  }

  get dateRangeText(): string {
    return this.value.join(' - ')
  }
}
