





































import { Component, Prop } from 'vue-property-decorator'
import AbstractHeader from '@/components/DataTable/HeaderTypes/AbstractHeader'
import DataTableBase from '@/classes/DataTable/DataTable'
import can from '@/helpers/can'
import Model from '@/classes/Model'
import DataTableAction from '@/classes/DataTable/DataTableAction'

@Component({
  methods: {
    can,
  },
})
export default class ActionHeader extends AbstractHeader {
  @Prop() table!: DataTableBase

  hasAtleastOneAction(): boolean {
    return this.table.actions.some((action: DataTableAction) => this.hasPermission(action.permissions, this.props.item))
  }

  hasPermission(permissions: string[], item: Model): boolean {
    return item.hasDynamicPermissions(permissions) && can(permissions)
  }
}
