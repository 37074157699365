









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminRoutes } from '@/helpers/getRouteMeta'

enum FinancierStatuses {
  declined = 'declined',
  offer_submitted = 'offer_submitted',
  waiting_for_client_answer = 'waiting_for_client_answer',
  waiting_for_client_documents = 'waiting_for_client_documents',
  waiting_for_client_answer_and_documents = 'waiting_for_client_answer_and_documents',
  not_submitted = 'not_submitted',
  financier_selected = 'financier_selected',
}

@Component
export default class FundingRequestData extends Vue {
  @Prop() expandedData!: any
  @Prop() fundingRequestUuid!: string
  @Prop() fundingRequestType!: string
  financierStatuses = FinancierStatuses

  goToOffers(fundingRequestUuid): void {
    this.$router
      .push({
        name:
          this.fundingRequestType === 'business'
            ? AdminRoutes.businessFundingRequestsShowTabOffers
            : AdminRoutes.fundingRequestsShowTabOffers,
        params: {
          uuid: fundingRequestUuid,
        },
      })
      .catch(() => {})
  }

  formStatus(status: string): string {
    switch (status) {
      case FinancierStatuses.declined:
        return 'Atsisakyta'
      case FinancierStatuses.offer_submitted:
        return 'Pateiktas pasiūlymas'
      case FinancierStatuses.waiting_for_client_answer_and_documents:
        return 'Laukiama kliento atsakymo ir dokumentu'
      case FinancierStatuses.waiting_for_client_answer:
        return 'Laukiama kliento atsakymo'
      case FinancierStatuses.waiting_for_client_documents:
        return 'Laukiama kliento dokumentu'
      case FinancierStatuses.not_submitted:
        return 'Finansuotojas nagrinėja'
      default:
        return 'Pasirinktas finansuotojas'
    }
  }

  getStatusColor(status: string): string {
    switch (status) {
      case FinancierStatuses.declined:
        return 'red'
      case FinancierStatuses.offer_submitted:
        return 'green'
      case FinancierStatuses.waiting_for_client_answer_and_documents:
      case FinancierStatuses.waiting_for_client_answer:
      case FinancierStatuses.waiting_for_client_documents:
      case FinancierStatuses.not_submitted:
        return 'orange'
      default:
        return 'blue'
    }
  }

  goToActivityLog(fundingRequestUuid): void {
    this.$router
      .push({
        name:
          this.fundingRequestType === 'business'
            ? AdminRoutes.businessFundingRequestsShowTabActivityLog
            : AdminRoutes.fundingRequestsShowTabActivityLog,
        params: {
          uuid: fundingRequestUuid,
        },
      })
      .catch(() => {})
  }
}
