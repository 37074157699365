




































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { NavigationState } from '@/store/global/helpers'
import { Getters } from '@/store/global/getters'
import { Getters as AuthGetters } from '@/store/auth/getters'
import NavigationItem from '@/classes/NavigationItem'
import Can from '@/components/Can.vue'
import can from '@/helpers/can'
import { Actions } from '@/store/global/actions'
import isMobile from '@/helpers/isMobile'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import FinancierModel from '@/modules/financier/financier.model'

@Component({
  components: {
    Can,
  },
  methods: {
    can,
  },
})
export default class Navigation extends Vue {
  activeItem: NavigationItem | null = null
  activeChildItem: NavigationItem | null = null
  isHover: boolean = false
  financier: FinancierModel | null = null

  items: NavigationItem[] = [
    new NavigationItem()
      .setPermissions([permissions.dashboard.list])
      .setTitle('Pagrindinis')
      .setIcon('dashboard')
      .setRoute(AdminRoutes.dashboard),
    new NavigationItem()
      .setPermissions([permissions.fundingRequests.list])
      .setTitle('Fizinės')
      .setIcon('description')
      .setSubItems([
        new NavigationItem()
          .setPermissions([permissions.fundingRequests.list])
          .setTitle('Užklausos finansavimui')
          .setIcon('description')
          .setRoute(AdminRoutes.fundingRequests),

        new NavigationItem()
          .setPermissions([permissions.offers.list])
          .setTitle('Pateikti pasiūlymai')
          .setIcon('local_offer')
          .setRoute(AdminRoutes.offers),

        new NavigationItem()
          .setPermissions([permissions.fundingRequests.offerAccepted])
          .setTitle('Pasirinkti finansuotojai')
          .setIcon('gavel')
          .setRoute(AdminRoutes.selectedOffers),

        new NavigationItem()
          .setPermissions([permissions.fundingRequests.list])
          .setTitle('Atsisakytos užklausos')
          .setIcon('no_sim')
          .setRoute(AdminRoutes.declinedFundingRequests),
      ]),
    new NavigationItem()
      .setPermissions([permissions.businessFundingRequests.list])
      .setTitle('Verslas')
      .setIcon('mdi-office-building')
      .setSubItems([
        new NavigationItem()
          .setPermissions([permissions.businessFundingRequests.list])
          .setTitle('Užklausos finansavimui')
          .setIcon('description')
          .setRoute(AdminRoutes.businessFundingRequests),

        new NavigationItem()
          .setPermissions([permissions.businessFundingRequests.list])
          .setTitle('Pateikti pasiūlymai')
          .setIcon('local_offer')
          .setRoute(AdminRoutes.businessOffers),

        new NavigationItem()
          .setPermissions([permissions.businessFundingRequests.offerAccepted])
          .setTitle('Pasirinkti finansuotojai')
          .setIcon('gavel')
          .setRoute(AdminRoutes.selectedBusinessOffers),

        new NavigationItem()
          .setPermissions([permissions.businessFundingRequests.list])
          .setTitle('Atsisakytos užklausos')
          .setIcon('no_sim')
          .setRoute(AdminRoutes.declinedBusinessFundingRequests),
      ]),
    new NavigationItem()
      .setPermissions([permissions.archivedFundingRequests.list])
      .setTitle('Suarchyvuotos paraiškos')
      .setIcon('inventory')
      .setRoute(AdminRoutes.archivedFundingRequests),
    new NavigationItem()
      .setPermissions([permissions.users.list])
      .setTitle('Darbuotojai')
      .setIcon('contacts')
      .setRoute(AdminRoutes.employees),
    new NavigationItem()
      .setPermissions([permissions.users.list])
      .setTitle('Vartotojai')
      .setIcon('person')
      .setRoute(AdminRoutes.customers),
    new NavigationItem()
      .setPermissions([
        permissions.fundingRequestFields.list,
        permissions.financiers.list,
        permissions.settings.list,
        permissions.notificationTemplates.list,
        permissions.additionalDocuments.list,
      ])
      .setPermissionsAtLeastOne(true)
      .setTitle('Nustatymai')
      .setIcon('settings')
      .setRoute(AdminRoutes.settings),
  ]
  isMobile = isMobile

  @Watch('$store.state.global.navigation') closeNavigationItems(): void {
    if (this.$vuetify.breakpoint.smAndDown) return

    if (this.$store.getters[Getters.getNavigation] === NavigationState.minimized) {
      this.items.forEach((item: NavigationItem) => (item.open = false))
    }
  }

  created(): void {
    const user: UserModel = this.$store.getters[AuthGetters.getUser]

    if (!user.isAdmin) {
      this.items.push(new NavigationItem().setTitle('Kolegos').setIcon('person').setRoute(AdminRoutes.colleagues))
      this.financier = user[UserAttribute.financier]
    }

    let found: NavigationItem | null = null
    let childFound: NavigationItem | null = null

    const subItemsCount: NavigationItem[] = this.items.filter((item: NavigationItem) => {
      return can(item.permissions) && item.subItems.length > 0
    })

    this.items.forEach((item: NavigationItem) => {
      if (found) return

      // eslint-disable-next-line consistent-return
      if ((this.$router.currentRoute.name as string).startsWith(item.route)) return (found = item)
      item.subItems.forEach((subItem: NavigationItem) => {
        if (found) return

        if ((this.$router.currentRoute.name as string).startsWith(subItem.route)) {
          found = item
          childFound = subItem
        }
      })
    })

    if (found) this.activeItem = found

    if (this.activeItem && childFound) {
      this.activeItem.setOpen(true)
      this.activeChildItem = childFound
    }

    if (subItemsCount.length === 1 && !this.activeChildItem) {
      subItemsCount[0].setOpen(true)
    }
  }

  hover(value: boolean): void {
    this.isHover = value

    if (!this.isMinimized && !value) this.closeNavigationItems()
  }

  openNavigation(): void {
    this.$store.dispatch(Actions.setNavigation, NavigationState.default)
  }

  navigate(navigationItem: NavigationItem, childNavigationItem?: NavigationItem): void {
    if (this.isMobile()) {
      // Close navigation on mobile after click
      this.$store.dispatch(Actions.setNavigation, NavigationState.default)
    }
    this.activeItem = navigationItem
    this.activeChildItem = childNavigationItem || null
    this.$router.push({ name: childNavigationItem ? childNavigationItem.route : navigationItem.route }).catch(() => {})
  }

  get isMinimized(): boolean {
    return this.$store.getters[Getters.getNavigation] === NavigationState.default
  }
}
