


















import { Component } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'

@Component
export default class PasswordField extends AbstractField {
  private showPassword: boolean = false
}
